import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import styled from "@emotion/styled";
import {css} from "@emotion/core";
import Link from "../components/link";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagramSquare } from '@fortawesome/free-brands-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'

const WhoWeAreSection2Background = styled.div`
  min-height: 409px;
  background: linear-gradient(to left, ${props => props.backgroundColorRight} 50%, ${props => props.backgroundColorLeft} 50%);
  color: ${props => props.color};
  position:relative;
  width:100%;
  overflow:hidden;
`
const SectionItem = styled(Col)`
  margin-top:130px;
  margin-bottom:130px;
  text-align:center;
  display: inline-block;
  @media(max-width:767px){
    margin-top:50px;
    margin-bottom:50px;
  }
`
const SectionP = styled.p`
  margin-bottom:35px;
`

const ColumnSection = styled(Col)`
  background-color: ${props => props.backgroundColor};
  font: 20px/28px Roboto;
`

const ButtonFill6 = styled(Button)`
  height: 60px;
  color: ${props => props.color};
  background-color: ${props => props.backgroundColor};
  border-color: ${props => props.borderColor};
  font: 22px/22px Roboto;
  transition: 0.25s ease-out;
  &:hover {
    color: ${props => props.colorHover} !important;
    background-color: ${props => props.backgroundColorHover} !important;
    border-color: ${props => props.borderColorHover} !important;
    box-shadow: 0px 3px 20px ${props => props.theme.colors.gray_shadow} !important;
    @media(min-width:1199px){
      margin-top:-3px;
    }
  }
  &:focus, &.focus, &:active, &.active {
    color: ${props => props.colorPressed} !important;
    background-color: ${props => props.backgroundColorPressed} !important;
    border-color: ${props => props.theme.borderColorPressed} !important;
    box-shadow: none !important;
    @media(min-width:1199px){
      margin-top:-3px;
    }
  }
  @media(max-width:767px){
    width:100%;
    margin-bottom:20px;
  }
`
const FASocmedButton = styled(FontAwesomeIcon)`
  font-size:3rem;
  color:${props => props.theme.colors.accent_yellow};
  transition: 0.25s ease-out;
  &:hover, &:focus, &.focus, &:active, &.active {
    color:${props => props.theme.colors.accent_yellow_hover};
    margin-top:-3px;
    cursor:pointer;
  }
`

const SocmedRow = styled(Row)`
  margin-top:10px;
  margin-bottom:40px;
`

export default function NewThemedMentorshipSection3(props) {
  return (
  <WhoWeAreSection2Background color={props.TextColor} backgroundColorLeft={props.BackgroundLeft} backgroundColorRight={props.BackgroundRight}>
    <Container>
      <Row>
          <ColumnSection xs="12" md="6" xl="5" backgroundColor={props.BackgroundLeft}>
            <SectionItem>
              <SectionP>
                  {props.ContentLeft}
              </SectionP>
              <Link to={props.ButtonLinkLeft}>
                <ButtonFill6 variant="outline-light" 
                color={props.ButtonColorLeft} 
                backgroundColor={props.ButtonBackgroundLeft} 
                borderColor={props.ButtonBorderLeft} 
                colorHover={props.ButtonColorHoverLeft}
                backgroundColorHover={props.ButtonBackgroundHoverLeft}
                borderColorHover={props.ButtonBorderHoverLeft}
                colorPressed={props.ButtonColorPressedLeft}
                backgroundColorPressed={props.ButtonBackgroundPressedLeft}
                borderColorPressed={props.ButtonBorderPressedLeft}
                >
                    {props.ButtonTextLeft}
                </ButtonFill6>
              </Link>
            </SectionItem>
          </ColumnSection>
          <Col xl="2" css={css`@media(max-width:1199px){display:none;}`}></Col>
          <ColumnSection xs="12" md="6" xl="5" backgroundColor={props.BackgroundRight}>
            <SectionItem>
              <SectionP>{props.ContentRight}</SectionP>
              <SocmedRow>
                <Col xs={4}></Col>
                <Col xs={2}>
                  <OutboundLink href="https://www.instagram.com/womenworksid/" target="_blank" rel="noreferrer">
                    <FASocmedButton icon={faInstagramSquare} />
                  </OutboundLink>
                </Col>
                <Col xs={2}>
                  <OutboundLink href="https://www.linkedin.com/company/womenworksid/" target="_blank" rel="noreferrer">
                    <FASocmedButton icon={faLinkedin} />
                  </OutboundLink>
                </Col>
                <Col xs={4}></Col>
              </SocmedRow>
            </SectionItem>
          </ColumnSection>
      </Row>
    </Container>
  </WhoWeAreSection2Background>
  )
}

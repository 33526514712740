import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import styled from "@emotion/styled";
import {css} from "@emotion/core";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'

const SixthSectionBackground = styled.div`
  min-height: 439px;
  background-color: ${props => props.backgroundColor};
  color: ${props => props.color};
`
const FAHeart = styled(FontAwesomeIcon)`
  font-size:1rem;
  color: ${props => props.color};
`
const ButtonFill6 = styled(Button)`
  height: 60px;
  margin-bottom:100px;
  color: ${props => props.color};
  background-color: ${props => props.backgroundColor};
  border-color: ${props => props.borderColor};
  font: 22px/22px Roboto;
  transition: 0.25s ease-out;
  &:hover {
    color: ${props => props.colorHover} !important;
    background-color: ${props => props.backgroundColorHover} !important;
    border-color: ${props => props.borderColorHover} !important;
    box-shadow: 0px 3px 20px ${props => props.theme.colors.gray_shadow} !important;
    @media(min-width:1199px){
      margin-top:-3px;
    }
  }
  &:focus, &.focus, &:active, &.active {
    color: ${props => props.colorPressed} !important;
    background-color: ${props => props.backgroundColorPressed} !important;
    border-color: ${props => props.theme.borderColorPressed} !important;
    box-shadow: none !important;
    @media(min-width:1199px){
      margin-top:-3px;
    }
  }
  @media(min-width:1199px){
    width:272px;
  }
  @media(max-width:767px){
    width:100%;
    margin-bottom:50px;
  }
`

export default function IndexSection2(props) {
  return (
  <SixthSectionBackground backgroundColor={props.Background} color={props.TextColor}>
    <Container>
      <Row>
          <Col lg="1"></Col>
          <Col md="6" css={css`font: 28px/36px Roboto;margin-top:100px;@media(max-width:767px){margin-top:50px;font: 20px/26px Roboto;}`}>
            Still not sure whether WomenWorks is right for you?<b> Sign up for a FREE 15-minute consultation </b>with our founding team and select mentors.
          </Col>
          <Col md="5" css={css`font: 20px/26px Roboto;margin-top:100px;@media(max-width:767px){margin-top:20px;}`}>
            <p css={css`margin-bottom:11px;`}>Find out more about how WomenWorks can help you solve your problem</p>
            <Row >
              <Col xs="auto" css={css`padding-right:0;`}>
                <FAHeart icon={faHeart} color={props.ColorHeart} />
              </Col>
              <Col css={css`padding-left:7px;margin-bottom:13px;`}>Get help to formulate your questions</Col>
            </Row>
            <Row >
              <Col xs="auto" css={css`padding-right:0;`}>
                <FAHeart icon={faHeart} color={props.ColorHeart} />
              </Col>
              <Col css={css`padding-left:7px;margin-bottom:25px;`}>Ask any questions you have before you commit to our program</Col>
            </Row>
            <OutboundLink target="_blank" rel="noreferrer" href="https://bit.ly/prementorshipWW">
              <ButtonFill6 variant="outline-light" 
                color={props.ButtonColor} 
                backgroundColor={props.ButtonBackground} 
                borderColor={props.ButtonBorder} 
                colorHover={props.ButtonColorHover}
                backgroundColorHover={props.ButtonBackgroundHover}
                borderColorHover={props.ButtonBorderHover}
                colorPressed={props.ButtonColorPressed}
                backgroundColorPressed={props.ButtonBackgroundPressed}
                borderColorPressed={props.ButtonBorderPressed}
              >
                Get a free consultation
              </ButtonFill6>
            </OutboundLink>
          </Col>
      </Row>
    </Container>
  </SixthSectionBackground>
  )
}
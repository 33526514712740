import React from "react";
import { Link } from "gatsby-plugin-modal-routing";
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import styled from "@emotion/styled";
import { css} from "@emotion/core";
import algoliasearch from "algoliasearch/lite"
import { InstantSearch, SearchBox, Configure, connectHits, Highlight, Menu, Pagination } from 'react-instantsearch-dom';
import 'instantsearch.css/themes/algolia.css';
import Layout from "../components/layout";
import Section1 from "../sections/the-ladybosses-section1";
import Section3 from "../sections/index-section7-multiple-use";
import Section4 from "../sections/new-themed-mentorship-section3-multiple-use";
import Section5 from "../sections/index-section4-multiple-use";
import Section6 from "../sections/the-ladybosses-section6";
import Section7 from "../sections/index-section5-multiple-use";
import SEO from "../components/seo"

const LadybossMenuTitle = styled.span`
  display:block;
  float:left;
  font: bold 16px/28px Roboto;
  @media(max-width:768px) {
    margin-bottom:10px;
  }
  @media(max-width:576px) {
    float: none;
    text-align:center;
  }
`
const LadybossMenu = styled(Menu)`
  .ais-Menu-count {
    display:none;
  }
  ul li{
    display: inline;
  }
  .ais-Menu-item {
    margin-left:5%;
  }
  .ais-Menu-link {
    display: inline-block;
    padding-bottom:10px;
  }
  .ais-Menu-label {
    color: ${props => props.theme.colors.primary_violet}; 
    &:hover, &:focus, &.focus, &:active, &.active  {
      color: ${props => props.theme.colors.gray_dark};
    }
  }
  .ais-Menu-item--selected .ais-Menu-label {
    color: ${props => props.theme.colors.gray_dark};
    border-bottom: 3px solid;
    padding-bottom: 3px;
  }
`

const LadybossPagination = styled(Pagination)`
  .ais-Pagination-link {
    width:33.98px;
    height:38px;
    padding-top:8px;
    text-align:center;
    font: 1rem Roboto;
    border-radius:0;
    border: 1px solid ${props => props.theme.colors.gray_border};
    border-left:0;
    color:${props => props.theme.colors.primary_violet};
    &:hover, &:focus, &.focus, &:active, &.active  {
      color:${props => props.theme.colors.white};
      background-color:${props => props.theme.colors.primary_violet}; 
      border-color:${props => props.theme.colors.primary_violet}; 
    }
  }
  .ais-Pagination-item--previousPage .ais-Pagination-link {
    border-left: 1px solid ${props => props.theme.colors.gray_border};
    border-top-left-radius:.25rem;
    border-bottom-left-radius:.25rem;
  }
  .ais-Pagination-item--nextPage .ais-Pagination-link {
    border-top-right-radius:.25rem;
    border-bottom-right-radius:.25rem;
  }
  .ais-Pagination-link--selected {
    color:${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors.gray_dark} !important;
    border-color: ${props => props.theme.colors.gray_dark} !important;
  }
  .ais-Pagination-item + .ais-Pagination-item {
    margin-left:0;
  }
  @media(max-width:767px) {
    justify-content: center !important;
  }
  @media(min-width:768px) {
    float:right;
  }
`

const SecondSectionContainer = styled(Container)`
  margin-top:34px;
  margin-bottom:100px;
  @media(max-width:767px) {
    margin-top:15px;
    margin-bottom:20px;
  }
`
const SecondSectionCard = styled(Button)`
  padding:0;
  color: ${props => props.theme.colors.gray_dark};
  background-color: ${props => props.theme.colors.white};
  border-color: ${props => props.theme.colors.gray_border};
  font: 16px/22px Roboto;
  transition: 0.25s ease-out;
  &:hover {
    color: ${props => props.theme.colors.gray_dark};
    background-color: ${props => props.theme.colors.white};
    border-color: ${props => props.theme.colors.gray_border};
    box-shadow: 0px 3px 5px ${props => props.theme.buttons.secondary_small_button_violet.hovered.shadow} !important;
    cursor: pointer;
  }
  &:focus, &.focus, &:active, &.active {
    color: ${props => props.theme.colors.gray_dark};
    background-color: ${props => props.theme.colors.white};
    border-color: ${props => props.theme.colors.gray_border};
    box-shadow: none;
    cursor: pointer;
  }
`
const SecondSectionCardBody = styled(Card.Body)`
  text-align:center;
  padding-top:15px;
  padding-left:25px;
  padding-right:25px;
  @media(min-width:768px) {
    height:220px;
    word-break: break-word;
  }
  @media(min-width:992px) {
    height:140px;
  }
`
const SecondSectionCardTitle = styled(Card.Title)`
  font: Bold 16px/22px Roboto;
  margin-bottom:10px;
`
const SecondSectionCardSubtitle = styled(Card.Subtitle)`
  font: 16px/22px Roboto;
`
const LadyBossHighlight = styled(Highlight)`
  .ais-Highlight-highlighted, .ais-Snippet-highlighted {
    background-color: ${props => props.theme.colors.accent_yellow};
  }
`

const Hits = ({ hits }) => (
  <Row>
    {hits.map(hit => (
      <Col md="3" css={css`margin-top:15px;`}>
        <Link
          //to={"/modal-example"}
          to={"/the-ladybosses/" + hit.name.trim().replace(/ /gi, '-').toLowerCase()} 
          asModal
        >
          
          <SecondSectionCard variant="light">
            <Card.Img alt={"WomenWorks Ladyboss - " + hit.name} variant="top" src={"/ladybosses/"+hit.name.trim().replace(/ /gi, '-').toLowerCase()+".jpg"} />
            <SecondSectionCardBody>
              <SecondSectionCardTitle>
                <LadyBossHighlight attribute="name" hit={hit} />
              </SecondSectionCardTitle>
              <SecondSectionCardSubtitle>
                <LadyBossHighlight attribute="title" hit={hit} />
              </SecondSectionCardSubtitle>
            </SecondSectionCardBody>
          </SecondSectionCard>

        </Link>
      </Col>
    ))}
  </Row>
);

const LadybossHits = styled(connectHits(Hits))`
  .ais-Hits-item {
    border: none;
    box-shadow: none;
  }
`

export default class TheLadybosses extends React.Component {
  render() {
    const searchClient = algoliasearch(
      process.env.GATSBY_ALGOLIA_APP_ID,
      process.env.GATSBY_ALGOLIA_SEARCH_KEY
    )

    return (
      <Layout 
        LogoColor={props => props.theme.colors.secondary_green} 
        HeaderMenuColor={props => props.theme.colors.gray_dark} 
        firstSectionBGColor={props => props.theme.colors.white}

        //button
        HeaderButtonColor={props => props.theme.buttons.primary_small_button_yellow_gray.normal.color} 
        HeaderButtonBorder={props => props.theme.buttons.primary_small_button_yellow_gray.normal.border_color} 
        HeaderButtonBackground={props => props.theme.buttons.primary_small_button_yellow_gray.normal.background_color}
        
        HeaderButtonColorHover={props => props.theme.buttons.primary_small_button_yellow_gray.hovered.color}
        HeaderButtonBorderHover={props => props.theme.buttons.primary_small_button_yellow_gray.hovered.border_color} 
        HeaderButtonBackgroundHover={props => props.theme.buttons.primary_small_button_yellow_gray.hovered.background_color}

        HeaderButtonColorPressed={props => props.theme.buttons.primary_small_button_yellow_gray.pressed.color}
        HeaderButtonBorderPressed={props => props.theme.buttons.primary_small_button_yellow_gray.pressed.border_color} 
        HeaderButtonBackgroundPressed={props => props.theme.buttons.primary_small_button_yellow_gray.pressed.background_color}
      >
        <SEO title={"The Ladybosses"} description={"Get mentored and connected to Indonesia’s inspiring professionals through WomenWorks"} />
        <Section1></Section1>

        <InstantSearch searchClient={searchClient} indexName="Mentors">
          <Container>
            <Row className="justify-content-center">
              <Col xs="12" xl="10" css={css`margin-top:25px;text-align:center;`}>
                <LadybossMenuTitle>
                  Filter mentors by their specialties:
                </LadybossMenuTitle>
                <LadybossMenu attribute="categories" />
              </Col>
            </Row>
            <Row id="mentors">
              <Col xs="12" md="6" lg="5" css={css`padding-top:35px;@media(max-width:576px){padding-top:10px;}`}>
                  <SearchBox css={css`.ais-SearchBox-submit{z-index:0;}.ais-SearchBox-input{height:38px;}`}
                    translations={{
                    placeholder: 'Search mentor’s name, title, or other keywords',
                  }}/>
              </Col>
              <Col xs="12" md="6" lg="7" css={css`padding-top:35px;@media(max-width:576px){padding-top:20px;}`}>
                  <LadybossPagination
                    showFirst={false} 
                  />
                
              </Col>
            </Row>
          </Container>
          
          <SecondSectionContainer>
            <Configure hitsPerPage={8} />
            <LadybossHits />
          </SecondSectionContainer>
          
        </InstantSearch>

        <Section3
          Title = "Themed mentorship and other WomenWorks new offer"
          TitleTextAlign = "left"
          Names = {[
            {
              photo:"dayu-dara-permata.png",
              name:"Dayu Dara Permata",
              title:"CEO and Founder of Pinhome",
              description:"Known as one of the creators of Indonesia’s largest on-demand lifestyle app, Dara is now revolutionizing the property industry through tech.",
              
              section2Subtitle:"Transforming Your Idea Into a Validated Prototype",
              section2Check1:"Startup 101 : Knowing the life cycle",
              section2Check2:"Testing your idea and conducting market validation",
              section2Check3:"Create your product prototype",
              section2Check4:"Piloting your MVP",
              section2GroupSize:"5-10 women",
              section2Duration:"4 sessions within 2 months",
              section2Price:"IDR 1.000.000 for 4 sessions",
              secion2FriendText:"Bring your friend to join and you each will pay only IDR 800K",
              section2VideoTitle:"Dara's Introduction",
              section2ButtonLink: "http://bit.ly/growthcirclewomenworks-dayu",

              section3Communities1:"Sequoia Spark",
              section3Communities2:" More Girls in Tech Events",
              section3Communities3:" Startup and VCs communities",
              section3Communities4:"ITB Startup communities",
              section31o1Text:"Dara is available for mentorship for women entrepreneurs looking to advance/build/scale their business or those looking to shift careers.",
              section3Book1:"The Lean Startup",
              section3Book1Author:"Eric Ries",
              section3Book2:"The Art of The Start",
              section3Book2Author:"Guy Kawasaki"
            }
          ]}
        ></Section3>
        <Section4
          BackgroundRight = {props => props.theme.colors.white_dark}
          BackgroundLeft = {props => props.theme.colors.accent_yellow70}
          TextColor = {props => props.theme.colors.gray_dark}

          TitleLeft = "It’s time to pay it forward"
          ContentLeft = "Mentorship and access to WomenWorks LadyBosses network could benefit women in different stages of professional career."

          TitleRight= "Still have questions?"
          ContentRight = "On the fence about investing your time for a mentorship? Sign up for a free 15-minute pre-mentorship consultation session!"

          ButtonTextLeft = "Become a mentor"
          ButtonLinkLeft = "/be-a-mentor/"
          ButtonTextRight = "Connect with the WomenWorks team"
          ButtonLinkRight = "https://bit.ly/prementorshipWW"

          ButtonColorRight={props => props.theme.buttons.primary_button_violet.normal.color} 
          ButtonBorderRight={props => props.theme.buttons.primary_button_violet.normal.border_color} 
          ButtonBackgroundRight={props => props.theme.buttons.primary_button_violet.normal.background_color}
          
          ButtonColorHoverRight={props => props.theme.buttons.primary_button_violet.hovered.color}
          ButtonBorderHoverRight={props => props.theme.buttons.primary_button_violet.hovered.border_color} 
          ButtonBackgroundHoverRight={props => props.theme.buttons.primary_button_violet.hovered.background_color}

          ButtonColorPressedRight={props => props.theme.buttons.primary_button_violet.pressed.color}
          ButtonBorderPressedRight={props => props.theme.buttons.primary_button_violet.pressed.border_color} 
          ButtonBackgroundPressedRight={props => props.theme.buttons.primary_button_violet.pressed.background_color}

          ButtonColorLeft={props => props.theme.buttons.primary_button_violet.normal.color} 
          ButtonBorderLeft={props => props.theme.buttons.primary_button_violet.normal.border_color} 
          ButtonBackgroundLeft={props => props.theme.buttons.primary_button_violet.normal.background_color}
          
          ButtonColorHoverLeft={props => props.theme.buttons.primary_button_violet.hovered.color}
          ButtonBorderHoverLeft={props => props.theme.buttons.primary_button_violet.hovered.border_color} 
          ButtonBackgroundHoverLeft={props => props.theme.buttons.primary_button_violet.hovered.background_color}

          ButtonColorPressedLeft={props => props.theme.buttons.primary_button_violet.pressed.color}
          ButtonBorderPressedLeft={props => props.theme.buttons.primary_button_violet.pressed.border_color} 
          ButtonBackgroundPressedLeft={props => props.theme.buttons.primary_button_violet.pressed.background_color}
        ></Section4>
        <Section5
          Title = "Find a mentor and get the network"
          Color = {props => props.theme.colors.accent_yellow_dark} 
          Background = {props => props.theme.colors.white} 
          MarginTop = "150px"
          SignUpPageDisplay = "none"
        ></Section5>
        <Section6
          BackgroundLeft = {props => props.theme.colors.secondary_green10}
          BackgroundRight = {props => props.theme.colors.secondary_green_shade20}
          TextColor = {props => props.theme.colors.white}

          ContentLeft = "Still have questions? Find out more through a FREE 30-minute pre-mentorship consultation!"

          ContentRight = "Don’t miss our bad-ass updates and free webinars! Follow us in social media:"

          ButtonTextLeft = "Get a free consultation"
          ButtonLinkLeft = "https://bit.ly/prementorshipWW"

          ButtonColorLeft={props => props.theme.buttons.primary_button_yellow_green.normal.color} 
          ButtonBorderLeft={props => props.theme.buttons.primary_button_yellow_green.normal.border_color} 
          ButtonBackgroundLeft={props => props.theme.buttons.primary_button_yellow_green.normal.background_color}
          
          ButtonColorHoverLeft={props => props.theme.buttons.primary_button_yellow_green.hovered.color}
          ButtonBorderHoverLeft={props => props.theme.buttons.primary_button_yellow_green.hovered.border_color} 
          ButtonBackgroundHoverLeft={props => props.theme.buttons.primary_button_yellow_green.hovered.background_color}

          ButtonColorPressedLeft={props => props.theme.buttons.primary_button_yellow_green.pressed.color}
          ButtonBorderPressedLeft={props => props.theme.buttons.primary_button_yellow_green.pressed.border_color} 
          ButtonBackgroundPressedLeft={props => props.theme.buttons.primary_button_yellow_green.pressed.background_color}

          ButtonColorRight={props => props.theme.buttons.primary_button_yellow_green.normal.color} 
          ButtonBorderRight={props => props.theme.buttons.primary_button_yellow_green.normal.border_color} 
          ButtonBackgroundRight={props => props.theme.colors.accent_yellow20}
          
          ButtonColorHoverRight={props => props.theme.buttons.primary_button_yellow_green.hovered.color}
          ButtonBorderHoverRight={props => props.theme.buttons.primary_button_yellow_green.hovered.border_color} 
          ButtonBackgroundHoverRight={props => props.theme.buttons.primary_button_yellow_green.hovered.background_color}

          ButtonColorPressedRight={props => props.theme.buttons.primary_button_yellow_green.pressed.color}
          ButtonBorderPressedRight={props => props.theme.buttons.primary_button_yellow_green.pressed.border_color} 
          ButtonBackgroundPressedRight={props => props.theme.buttons.primary_button_yellow_green.pressed.background_color}
        ></Section6>
        <Section7 
          DisplaySection='none'
        >
        </Section7>
      </Layout>   
    )
  }
}

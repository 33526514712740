import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import Card from 'react-bootstrap/Card';
import styled from "@emotion/styled";
import {Global, css} from "@emotion/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons'
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons'

const ThirdSectionBackground = styled.div`
  background-color : ${props => props.theme.colors.secondary_green10};
  color : ${props => props.theme.colors.white};
  min-height: 1034px;
  
`
const ThirdSectionTitle = styled.div`
  margin-top:150px;
  font: 58px/62px Abril Fatface;
  text-align:center;
  margin-bottom:50px;
  @media(max-width:767px){
    font: 2.5rem/3rem Abril Fatface;
    margin-top:50px;
    margin-bottom:25px;
  }
  @media(max-width:320px){
    font: 2rem/2.5rem Abril Fatface;
  }
`
const ThirdSectionCarousel = styled(Carousel)`
  .carousel-row {
    margin-left:0;
    margin-right:0;
    @media(min-width:992px) {
      min-height:602px;
    }
    @media(min-width:768px) {
      min-height:634px;
    }
    .carousel-col {
      padding-left:0;
      padding-right:16px;
      @media(max-width:1199px){
        padding-right:8px;
      }
      @media(max-width:767px){
        margin-bottom:15px;
        padding-right:0;
      }
      &.second {
        @media(min-width:768px){
          margin-top:30px;
        }
      }
      &.last {
        padding-right:0;
      }
      .card {
        background-color:transparent;
        text-align: center;
        border:none;
        width:171px;
        @media(max-width:1199px){
          width:147px;
        }
        @media(max-width:991px){
          width:107px;
        }
        @media(max-width:767px){
          width:120px;
        }
        @media(max-width:320px){
          width:107px;
        }
        .card-body {
          padding-left:0;
          padding-right:0;
          padding-top:10px;
          padding-bottom:0;
          .card-title {
            font: 16px/20px Roboto;
            margin-bottom:5px;
          }
          .card-text {
            font: Bold 16px/20px Roboto;
          }
          @media(min-width:768px) {
            min-height:195px;
          }
          @media(min-width:992px) {
            min-height:115px;
          }
        }
      }
    }
  }
  
`
const FAArrowButton = styled(FontAwesomeIcon)`
  font-size:3rem;
  color:${props => props.theme.colors.accent_yellow};
  transition: 0.25s ease-out;
  &:hover, &:focus, &.focus, &:active, &.active {
    color:${props => props.theme.colors.accent_yellow_hover};
    @media(min-width:1200px){
      margin-top:-3px;
    }
  }
`
const MentorsPage = [];

MentorsPage[0] = {pageId:0,mentors:[
  {id:1,name:"Alamanda Shantika", title:"Founder, President Director at Binar Academy", picture:"alamanda-shantika.png"},
  {id:2,name:"Alanda Kariza", title:"Founder of Advislab", picture:"alanda-kariza.png"},
  {id:3,name:"Amanda Djojonegoro", title:"Associate Director at Tusk Advisory", picture:"amanda-djojonegoro.png"},
  {id:4,name:"Asrini Suhita", title:"Hair Care Brand Director at Procter & Gamble Indonesia", picture:"asrini-suhita.png"},
  {id:5,name:"Cindy Riswantyo", title:"Counsel at at Ginting & Reksodiputro in association with Allen & Overy", picture:"cindy-riswantyo.png"},
  {id:6,name:"Cissylia Stefani", title:"Brand Director at BASE", picture:"cissylia-stefani.png"},
  {id:7,name:"Clairine Runtung", title:"Incoming Consultant at Bain and Company, MBA at Yale University", picture:"clairine-runtung.png"},
  {id:8,name:"Dayu Dara Permata", title:"CEO & Founder, Pinhome", picture:"dayu-dara-permata.png"},
  {id:9,name:"Dessy Kadriyani", title:"Sr. Product Manager at Amazon.com", picture:"dessy-kadriyani.png"},
  {id:10,name:"Deviani Ary Wulandari", title:"Senior Manager, Consumer PR at GoPay", picture:"deviani-ary-wulandari.png"},
]};

MentorsPage[1] = {pageId:1,mentors:[
  {id:1,name:"Fransiska P.W. Hadiwidjana", title:"Head of Engineering at Mamikos", picture:"fransiska-pw-hadiwidjana.png"},
  {id:2,name:"Gitta Amelia", title:"General Partner at EverHaüs", picture:"gitta-amelia.png"},
  {id:3,name:"Indriani Karlina", title:"Economist, Macroeconomics Analyst at Central Bank of Indonesia", picture:"indriani-karlina.png"},
  {id:4,name:"Ivana Setiawan", title:"Founder & CEO of Venopi", picture:"ivana-setiawan.png"},
  {id:5,name:"Maria Yeti Khim", title:"Product Advisor at Ministry of Education and Culture, Republic of Indonesia", picture:"maria-yeti-kim.png"},
  {id:6,name:"Melina Subastian", title:"Principal, Investment at Alpha JWC Ventures", picture:"melina-subastian.png"},
  {id:7,name:"Melvany Kasih", title:"AVP Business Development at PT Japfa Comfeed Indonesia, Tbk", picture:"melvany-kasih.png"},
  {id:8,name:"Metha Trisnawati", title:"Co-Founder & COO at Sayurbox", picture:"metha-trisnawati.png"},
  {id:9,name:"Nacitta Kanyandara", title:"Chief Product and Growth Officer at Sayurbox", picture:"nacitta-kanyandara.png"},
  {id:10,name:"Natalia Rialucky Marsudi", title:"Consultant and Head of Social Impact at Boston Consulting Group", picture:"natalia-rialucky-marsudi.png"},
]};

MentorsPage[2] = {pageId:2,mentors:[
  {id:1,name:"Nazrya Octora", title:"External Communications Manager at PT HM Sampoerna Tbk", picture:"nazrya-octora.png"},
  {id:2,name:"Pradita Astarina", title:"SVP of Investment and Business Development at Cakra Ventures", picture:"pradita-astarina.png"},
  {id:3,name:"Priska Sufhana", title:"Assistant Advisor, Kantor Staf Presiden Republik Indonesia", picture:"priska-sufhana.png"},
  {id:4,name:"Puti Ara Zena", title:"Head of Growth at GOJEK", picture:"puti-ara-zena.png"},
  {id:5,name:"Shuhaela Zen", title:"Director - Strategy & Operations at Deloitte Consulting", picture:"shuhaela-zen .png"},
  {id:6,name:"Stella Tambunan", title:"Treasurer at YCAB Foundation", picture:"stella-tambunan.png"},
  {id:7,name:"Tanisha Banaszczyk", title:"Principal at AC Ventures", picture:"tanisha-banaszczyk.png"},
  {id:8,name:"Tantia Dian Permata Indah ", title:"Chief Operating Officer at Zenius", picture:"tantia-dian-permata-indah.png"},
  {id:9,name:"Triana Krisandini", title:"Assistant Director Sustainability, APRIL", picture:"triana-krisandini.png"},
  {id:10,name:"Wina Wilanisa", title:"Communications Specialist at McKinsey & Company Indonesia", picture:"wina-wilanisa.png"},
]};

MentorsPage[3] = {pageId:3,mentors:[
  {id:1,name:"Windy Natriavi", title:"Co-Founder & CPO at AwanTunai", picture:"windy-natriavi.png"},
  {id:2,name:"Wulan Mantik", title:"Ex-Head of Product (Growth, Payment) at Bukalapak", picture:"wulan-mantik.png"},
  {id:3,name:"Yuriko Feliciani", title:"National Cold Drink Equipment Services at Coca-Cola Amatil Indonesia", picture:"yuriko-feliciani.png"},
  {id:3,name:"Zhafira Loebis", title:"Co-Founder, CEO at Babyloania.com", picture:"zhafira-loebis.png"},
  {id:5,name:"", title:"", picture:"many-more.svg"}
]};

export default function Home() {
  return (      
  <ThirdSectionBackground id="womenworks-lady-bosses">
    <Container>
      <Row className="justify-content-center">
        <Col xs="10">
          <ThirdSectionTitle>
            +50 LadyBosses onboarded and ready to grow with you... and counting!
          </ThirdSectionTitle>
          <Global
            //To style Bootstrap Carousel
            styles={css`
            .carousel-control-prev {
              opacity:1;
              margin-left:-141px;
              @media(max-width:1199px){
                margin-left:-125px;
              }
              @media(max-width:991px){
                margin-left:-95px;
              }
              @media(max-width:767px){
                margin-left:-30px;
              }
            }
            .carousel-control-next {
              opacity:1; 
              margin-right:-141px;
              @media(max-width:1199px){
                margin-right:-125px;
              }
              @media(max-width:991px){
                margin-right:-95px;
              }
              @media(max-width:767px){
                margin-right:-30px;
              }
            }
            .carousel-inner {
              @media(max-width:767px){
                margin-bottom:50px;
              }
            }
            `}
          />
          <ThirdSectionCarousel className="third-section-carousell" indicators={false} 
            prevIcon = {<FAArrowButton icon={faArrowAltCircleLeft} />}
            nextIcon = {<FAArrowButton icon={faArrowAltCircleRight} />}
          >
            {MentorsPage.map(page => 
              <Carousel.Item>
                <Row className="carousel-row">
                  {MentorsPage[page.pageId].mentors.map(mentor => 
                    <Col className={"carousel-col d-flex justify-content-center" + 
                      (mentor.id % 5 === 0 ? " last" : "") + 
                      (Math.floor(mentor.id/6) === 1 ? " second" : "")} 
                    xs="6" md="auto">
                      <Card>
                        <Card.Img alt={"WomenWorks Mentor - " + mentor.name} variant="top" src={"/mentor/"+ mentor.picture} />
                        <Card.Body>
                          <Card.Title>{mentor.name}</Card.Title>
                          <Card.Text>
                            {mentor.title}
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  )}
                </Row>
              </Carousel.Item>
            )}
            
          </ThirdSectionCarousel>
        </Col>
      </Row>
    </Container>
  </ThirdSectionBackground>
  )
}
import React, { useContext, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import AccordionContext from 'react-bootstrap/AccordionContext';
import Modal from 'react-bootstrap/Modal';
import styled from "@emotion/styled";
import {css} from "@emotion/core";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import { faArrowAltCircleDown } from '@fortawesome/free-solid-svg-icons';

const SectionBackground = styled.div`
  background-color : ${props => props.theme.colors.white};
  color : ${props => props.theme.colors.gray_dark};
  padding-bottom:50px;
`
const TitleText = styled.div`
  color : ${props => props.theme.colors.accent_yellow_dark};
  font: 58px/62px Abril Fatface;
  padding-top:150px;
  margin-bottom:30px;
  @media(max-width:767px){
    font: 2.5rem/3rem Abril Fatface;
    padding-top:30px;
  }
`
const CardAccordion = styled(Card)`
  border: 2px solid ${props => props.theme.colors.gray_border};
  border-left:none;
  border-right:none;
  .card-header {
    padding:0;
    background-color: ${props => props.theme.colors.white};
    border-bottom:none;
  }
  .card-body {
    text-align: justify;
    padding-left:5px;
    padding-top:0;
    padding-bottom:15px;
    span {
      margin-left:15px;
    }
    div {
      margin-top:10px;
    }
  }
  .row-video {
    img {
      width:122px;
      height:70px;
      object-fit:contain;
    }
  }
  .col-video{
    @media(min-width:1200px) {
      padding-right:0;
    }
  }
`
const FAArrow = styled(FontAwesomeIcon)`
  font-size:1.5rem;
  color: ${props => props.theme.colors.primary_violet};
  &:hover, &:focus, &.focus, &:active, &.active {
    color: ${props => props.theme.colors.primary_violet_dark};
  }
`
const Circle = styled(Button)`
    border:none;
    width: 43px;
    height: 43px;
    -webkit-border-radius: 21.5px;
    -moz-border-radius: 21.5px;
    border-radius: 21.5px;
    background: ${props => props.theme.colors.primary_violet};
    position:absolute;
    left: 60px;
    top: 15px;
    &:hover, &:focus, &.focus, &:active, &.active {
        background: ${props => props.theme.colors.white} !important;
        border:0 !important;
        box-shadow:none !important;
    }
    @media(max-width:1199px){
        left: 62px;
    }
`
const FAPlay = styled(FontAwesomeIcon)`
  color: ${props => props.theme.colors.primary_violet_tint40};
  position:absolute;
  left:16px;
  top:12px;
  font-size:1.2rem;
  &:hover, &:focus, &.focus, &:active, &.active {
    color: ${props => props.theme.colors.primary_violet};
}
`
const SectionSubtitle = styled.div`
  font: Bold 16px/22px Roboto;
  color: ${props => props.theme.colors.gray_dark};
`
const ButtonRegistration = styled(Button)`
  height: 60px;
  color: ${props => props.theme.buttons.primary_button_violet.normal.color};
  background-color: ${props => props.theme.buttons.primary_button_violet.normal.background_color};
  border-color: ${props => props.theme.buttons.primary_button_violet.normal.border_color};
  font: 22px/22px Roboto; 
  margin-top:10px;
  transition: 0.25s ease-out;
  &:hover {
    color: ${props => props.theme.buttons.primary_button_violet.hovered.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_violet.hovered.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_violet.hovered.border_color} !important;
    box-shadow: 0px 3px 20px ${props => props.theme.buttons.primary_button_violet.hovered.shadow} !important;
    @media(min-width:1200px){
      margin-top:7px;
      margin-bottom:3px;
    }
  }
  &:focus, &.focus, &:active, &.active {
    color: ${props => props.theme.buttons.primary_button_violet.pressed.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_violet.pressed.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_violet.pressed.border_color} !important;
    box-shadow: none !important;
    @media(min-width:1200px){
      margin-top:7px;
      margin-bottom:3px;
    }
  }
  @media(max-width:767px){
    width:100%;
    font-size:20px;
  }
  @media(max-width:320px){
    font-size:16px;
  }
`
const ButtonRegistrationOutline = styled(Button)`
  height: 47px;
  color: ${props => props.theme.buttons.secondary_small_button_violet.normal.color};
  background-color: ${props => props.theme.buttons.secondary_small_button_violet.normal.background_color};
  border-color: ${props => props.theme.buttons.secondary_small_button_violet.normal.border_color};
  font: 16px/22px Roboto; 
  margin-top:10px;
  transition: 0.25s ease-out;
  &:hover {
    color: ${props => props.theme.buttons.secondary_small_button_violet.hovered.color} !important;
    background-color: ${props => props.theme.buttons.secondary_small_button_violet.hovered.background_color} !important;
    border-color: ${props => props.theme.buttons.secondary_small_button_violet.hovered.border_color} !important;
    box-shadow: 0px 3px 20px ${props => props.theme.buttons.secondary_small_button_violet.hovered.shadow} !important;
    @media(min-width:1200px){
      margin-top:7px;
      margin-bottom:3px;
    }
  }
  &:focus, &.focus, &:active, &.active {
    color: ${props => props.theme.buttons.secondary_small_button_violet.pressed.color} !important;
    background-color: ${props => props.theme.buttons.secondary_small_button_violet.pressed.background_color} !important;
    border-color: ${props => props.theme.buttons.secondary_small_button_violet.pressed.border_color} !important;
    box-shadow: none !important;
    @media(min-width:1200px){
      margin-top:7px;
      margin-bottom:3px;
    }
  }
  @media(max-width:320px){
    width:100%;
    font-size:14px;
  }
`

function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(eventKey, () =>
    callback && callback(eventKey),
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <div
      css={css`
        font: Bold 20px/28px Roboto;
        outline:none;
        padding-top:30px;
        padding-bottom:30px};
        @media(max-width:767px){
          padding-top:15px;
          padding-bottom:15px};
        }
      `}
      onClick={decoratedOnClick}
      onKeyDown={decoratedOnClick}
      role="button"
      tabIndex={0}
    >
      <Row>
        <Col xs="10" md="11">
          {children}
        </Col>
        <Col xs="2" md="1" className="d-flex justify-content-end">
          <FAArrow icon={isCurrentEventKey ? faArrowAltCircleDown : faArrowAltCircleRight} />
        </Col>
      </Row>
    </div>
  );
}

function ModalVideo(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Circle onClick={handleShow}>
        <FAPlay icon={faPlay} className="fa-play" />
      </Circle>

      <Modal 
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show} 
        onHide={handleClose}
      >
        <div className="embed-responsive embed-responsive-16by9">
          <iframe title="Mentee Video Example" className="embed-responsive-item" src={props.videoLink+"?autoplay=1&color=white"} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
      </Modal>
    </>
  );
}

export default function SignUpforMentorshipSection3() {
  return (      
    <SectionBackground>
      <Container>
        <Row>
          <Col lg="2">
          </Col>
          <Col lg="9">
            <TitleText>
              Here’s how it works:
              <br></br>Find your mentor in 4 steps.
          </TitleText>
          <Accordion defaultActiveKey="0" css={css`margin-bottom:25px;`}>
            <CardAccordion>
              <Card.Header>
                <ContextAwareToggle eventKey="0">1. Fill out WomenWorks registration form with your details and story.</ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  Fill out this registration form with your details and story. 
                  <br></br>After the form submission, you will receive an email that will guide you on the next steps. 
                  <br></br>
                  <OutboundLink href="https://bit.ly/womenworksmenteesignupnew" target="_blank" rel="noreferrer">
                    <ButtonRegistrationOutline type="button">Fill out the mentee registration form</ButtonRegistrationOutline>
                  </OutboundLink>
                </Card.Body>
              </Accordion.Collapse>
            </CardAccordion>
            <CardAccordion>
              <Card.Header>
                <ContextAwareToggle eventKey="1">2. Send us a 60-second self-recorded video about yourself.</ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  Reply our email with a <b>link to your 60-second self-recorded video*</b> where you describe the following details so we can match you with the perfect mentor:
                  <br></br><span>a. Brief background about yourself</span>
                  <br></br><span>b. The challenges you’re currently facing that you wish your mentor can help you</span>
                  <br></br><span>c. Your expectations of the mentorship program</span>
                  <br></br><div>*PS: please feel free to do it in Bahasa Indonesia. You can keep it as simple as possible – just make sure we can hear you clearly. Here are the video examples:</div>
                  <Row className="row-video">
                    <Col xs="6" md="3" xl="2" className="col-video">
                      <ModalVideo videoLink="https://www.youtube.com/embed/Dz9YPbQ6Plg"></ModalVideo>
                      <img alt="WomenWorks Mentee Video Example (English)" src="/page-sign-up-for-mentorship-img1.png"></img>
                      <SectionSubtitle className="video-title">English Example</SectionSubtitle>
                    </Col>
                    <Col xs="6" className="col-video">
                      <ModalVideo videoLink="https://www.youtube.com/embed/pIkHCY48-3U"></ModalVideo>
                      <img alt="WomenWorks Mentee Video Example (Bahasa)" src="/page-sign-up-for-mentorship-img2.png"></img>
                      <SectionSubtitle className="video-title">Bahasa Example</SectionSubtitle>
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Collapse>
            </CardAccordion>
            <CardAccordion>
              <Card.Header>
                <ContextAwareToggle eventKey="2">3. Complete the registration fee.</ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="2">
                <Card.Body>Complete the commitment fee through the payment link button provided in the email.</Card.Body>
              </Accordion.Collapse>
            </CardAccordion>
            <CardAccordion>
              <Card.Header>
                <ContextAwareToggle eventKey="3">4. Get contacted by WomenWorks Team for you mentorship appointments.</ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="3">
                <Card.Body>We will reply to your emails in more detail regarding the next steps latest <b>within 2 - 3 days</b> after we’ve received your confirmation email.</Card.Body>
              </Accordion.Collapse>
            </CardAccordion>
          </Accordion>
          <OutboundLink href="https://bit.ly/womenworksmenteesignupnew" target="_blank" rel="noreferrer">
            <ButtonRegistration type="button">Fill out the mentee registration form</ButtonRegistration>
          </OutboundLink>
          </Col>
        </Row>
      </Container>
    </SectionBackground>
  )
}
import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Layout2 from "../components/layout2";
import Section1 from "../sections/waitlist-section1";
import Section2 from "../sections/waitlist-section2";
import SEO from "../components/seo"

export default function ExcitingNewOffer() {
  return (
    <Layout2
      LogoColor={props => props.theme.colors.white} 
      HeaderMenuColor={props => props.theme.colors.white} 
      firstSectionBGColor={props => props.theme.colors.secondary_green}

      //button
      HeaderButtonColor={props => props.theme.buttons.secondary_small_button_white_green.normal.color} 
      HeaderButtonBorder={props => props.theme.buttons.secondary_small_button_white_green.normal.border_color} 
      HeaderButtonBackground={props => props.theme.buttons.secondary_small_button_white_green.normal.background_color}
      
      HeaderButtonColorHover={props => props.theme.buttons.secondary_small_button_white_green.hovered.color}
      HeaderButtonBorderHover={props => props.theme.buttons.secondary_small_button_white_green.hovered.border_color} 
      HeaderButtonBackgroundHover={props => props.theme.buttons.secondary_small_button_white_green.hovered.background_color}

      HeaderButtonColorPressed={props => props.theme.buttons.secondary_small_button_white_green.pressed.color}
      HeaderButtonBorderPressed={props => props.theme.buttons.secondary_small_button_white_green.pressed.border_color} 
      HeaderButtonBackgroundPressed={props => props.theme.buttons.secondary_small_button_white_green.pressed.background_color}
    >
        <SEO title={"The Clique Waitlist"} description={"Waitlist for WomenWorks’ The Clique - a private and membership-based social platform aimed to empower women in their journey towards professional and personal growth."} /> 
        <Section1></Section1>
        <Section2></Section2>
    </Layout2>   
  )
}

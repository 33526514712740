import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import styled from "@emotion/styled";
import {css} from "@emotion/core";
import Container from "react-bootstrap/Container";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'

const WhoWeAreSection1Background = styled.div`
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.gray_dark};
  font: 20px/28px Roboto;
  min-height: 669px;
`

const WhoWeAreSection1Title = styled.div`
  font: 58px/62px Abril Fatface;
  margin-bottom:35px;
  min-height: 132px;
  @media(max-width:1199px){
    font: 56px/60px Abril Fatface;
  }
  @media(max-width:767px){
    font: 2.5rem/3rem Abril Fatface;
    margin-bottom:17.5px;
  }
`
const SectionItem = styled.div`
  margin-top:61px;
  margin-bottom:100px;
  display: inline-block;
  @media(max-width:767px){
    margin-top:0;
    margin-bottom:50px;
  }
`

const FAHeart = styled(FontAwesomeIcon)`
  font-size:1rem;
  color: ${props => props.theme.colors.accent_yellow_hover};
`

const ButtonFill6 = styled(Button)`
  height: 60px;
  color: ${props => props.theme.buttons.primary_button_violet.normal.color};
  background-color: ${props => props.theme.buttons.primary_button_violet.normal.background_color};
  border-color: ${props => props.theme.buttons.primary_button_violet.normal.border_color};
  font: 22px/22px Roboto;
  transition: 0.25s ease-out;
  &:hover {
    color: ${props => props.theme.buttons.primary_button_violet.hovered.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_violet.hovered.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_violet.hovered.border_color} !important;
    box-shadow: 0px 3px 20px ${props => props.theme.colors.gray_shadow} !important;
    @media(min-width:1199px){
      margin-top:-3px;
      margin-bottom:3px;
    }
  }
  &:focus, &.focus, &:active, &.active {
    color: ${props => props.theme.buttons.primary_button_violet.pressed.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_violet.pressed.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_violet.pressed.border_color} !important;
    box-shadow: none !important;
    @media(min-width:1199px){
      margin-top:-3px;
      margin-bottom:3px;
    }
  }
  @media(max-width:767px){
    width:100%;
    margin-bottom:20px;
  }
`

export default function NewThemedMentorshipSection1(props) {
  return (
  <WhoWeAreSection1Background backgroundColor={props.Background} color={props.TextColor}>
      <img alt="WomenWorks - two young woman looking at laptop" src="/page-themed-mentorship-img1.png" 
        css={css`
          @media(max-width:991px) {
            display:none;
          }
          @media(min-width:992px) {
            position: absolute;
            right: 0;
            top:145px;
            height: 500px;
            max-width: 36vw;
            object-fit: cover;
          }
          @media(min-width:1600px) {
            display:none;
          }
      `}></img>
      <Container>
          <SectionItem>
            <Row>
              <Col xs="12" lg="8" >
                <WhoWeAreSection1Title>
                  Growth Circle: Our new themed mentorship
                </WhoWeAreSection1Title>
              </Col>
              <Col xs="12" lg="4">
                <img alt="WomenWorks - two young woman looking at laptop" src="/page-themed-mentorship-img1.png" 
                  css={css`
                    @media(max-width:767px) {
                      width:100%;
                      margin-bottom:15px;
                    }
                    @media(min-width:768px) {
                      object-fit: cover;
                      height: 250px;
                      width:100%;
                      margin-bottom:30px;
                    }
                    @media(min-width:992px) {
                      display:none;
                    }
                    @media(min-width:1600px) {
                      display:block;
                      position:absolute;
                      width: 516px;
                      height: 500px;
                    }
                `}></img>
              </Col>
              <Col xs="12" lg="8">
                <p css={css`margin-bottom:20px;min-heihgt:54px;max-height:216px;`}>
                  Empower each other and reach your fullest potential with like-minded mentees and mentors as your support system.
                </p>
                <Row >
                  <Col xs="auto" css={css`padding-right:0;`}>
                    <FAHeart icon={faHeart} />
                  </Col>
                  <Col css={css`padding-left:7px;margin-bottom:20px;`}>
                    <b>Your own peer circles:</b> Team up with other mentees (and a mentor of your choice) in a group setting based on your interests and goals ;)
                  </Col>
                </Row>
                <Row >
                  <Col xs="auto" css={css`padding-right:0;`}>
                    <FAHeart icon={faHeart} />
                  </Col>
                  <Col css={css`padding-left:7px;margin-bottom:20px;`}>
                    <b>Quality over quantity:</b> Our sessions typically comprise of 5 - 10 mentees per cohort to ensure deeper connections.
                  </Col>
                </Row>
                <Row>
                  <Col xs="auto" css={css`padding-right:0;`}>
                    <FAHeart icon={faHeart} />
                  </Col>
                  <Col css={css`padding-left:7px;margin-bottom:25px;`}>
                    <b>Continuous and in-depth knowledge:</b> Be inspired beyond one session and leave with actionable items to do.
                  </Col>
                </Row>
                <OutboundLink target="_blank" rel="noreferrer" href="https://bit.ly/growthcirclewomenworks">
                  <ButtonFill6 variant="outline-light" css={css`@media(max-width:991px) {width:100%;}`}>
                    Sign up for Growth Circle
                  </ButtonFill6>
                </OutboundLink>
              </Col>
            </Row>
          </SectionItem>
      </Container>
  </WhoWeAreSection1Background>
  )
}


import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Layout from "../components/layout";
import Section1 from "../sections/index-section1";
import Section2 from "../sections/index-section2";
import Section3 from "../sections/index-section3";
import Section4 from "../sections/index-section4-multiple-use";
import Section5 from "../sections/index-section5-multiple-use";
import Section6 from "../sections/index-section6-multiple-use";
import Section8 from "../sections/index-section7-multiple-use";
import SEO from "../components/seo"

export default function Home() {
  return (
    <Layout 
      LogoColor={props => props.theme.colors.white} 
      HeaderMenuColor={props => props.theme.colors.white} 
      firstSectionBGColor={props => props.theme.colors.primary_violet}

      //button
      HeaderButtonColor={props => props.theme.buttons.secondary_small_button_white_violet.normal.color} 
      HeaderButtonBorder={props => props.theme.buttons.secondary_small_button_white_violet.normal.border_color} 
      HeaderButtonBackground={props => props.theme.buttons.secondary_small_button_white_violet.normal.background_color}
      
      HeaderButtonColorHover={props => props.theme.buttons.secondary_small_button_white_violet.hovered.color}
      HeaderButtonBorderHover={props => props.theme.buttons.secondary_small_button_white_violet.hovered.border_color} 
      HeaderButtonBackgroundHover={props => props.theme.buttons.secondary_small_button_white_violet.hovered.background_color}

      HeaderButtonColorPressed={props => props.theme.buttons.secondary_small_button_white_violet.pressed.color}
      HeaderButtonBorderPressed={props => props.theme.buttons.secondary_small_button_white_violet.pressed.border_color} 
      HeaderButtonBackgroundPressed={props => props.theme.buttons.secondary_small_button_white_violet.pressed.background_color}
    >
      <SEO title={"Bad-ass. Connected. Financially-independent."} description={"Build meaningful connections for women to achieve their professional and personal goals"} />
      
      <Section1></Section1>
      <Section2></Section2>
      <Section3></Section3>
      <Section4
        Title = "Find a mentor and supercharge your career growth"
        Color = {props => props.theme.colors.gray_dark} 
        Background = {props => props.theme.colors.white}
        MarginTop = "150px" 
        CardTextDescriptionDisplay = "block"
        SignUpPageDisplay = "none"
      ></Section4>
      <Section8
        Title = "Our New Peer Groups and Themed Mentorship"
        TitleTextAlign = "left"
        Names = {[
          {
            photo:"alamanda-shantika.png",
            name:"Alamanda Shantika",
            title:"Founder and President Director of Binar Academy",
            description:"An entrepreneur with 11 years experience in tech and startup, Alamanda is now the Founder and President Director of Binar Academy. She’s passionate in bringing technology to life and delivering happiness.",
            
            section2Subtitle:"Building a Technology Driven Start-up",
            section2Check1:"Building your business’ vision and mission.",
            section2Check2:"Getting your business to product market fit",
            section2Check3:"Calculating your financial projections",
            section2Check4:"Building a bad-ass team",
            section2GroupSize:"5-10 women",
            section2Duration:"1 hour per session(s) within 2 months (available 1 up to 4 sessions)",
            section2Price:"IDR 200,000 - 1,000,000 per person*",
            secion2FriendText:"*Depending on the chosen package, discount available",
            section2VideoTitle:"Alamanda’s Introduction",
            section2ButtonLink: "http://bit.ly/growthcirclewomenworks-alamanda",

            section3Communities1:"Binar Academy",
            section3Communities2:"Selected gov.officials & corporates",
            section3Communities3:"Impact investors",
            section3Communities4:"Startup communities",
            section31o1Text:"Alamanda is available for mentorship for women entrepreneurs looking to advance/build/scale their business or those looking to shift careers.",
            section3Book1:"Hooked: How to Build Habit-Forming Products",
            section3Book1Author:"Nir Eyal",
            section3Book2:"",
            section3Book2Author:""
          }
        ]}
      ></Section8>
      <Section5
        DisplaySection='block'
      >
      </Section5>
      <Section6
        Background = {props => props.theme.colors.accent_yellow70}
        TextColor = {props => props.theme.colors.gray_dark2}
        ColorHeart = {props => props.theme.colors.accent_yellow_dark}

        ButtonColor={props => props.theme.buttons.primary_button_violet.normal.color} 
        ButtonBorder={props => props.theme.buttons.primary_button_violet.normal.border_color} 
        ButtonBackground={props => props.theme.buttons.primary_button_violet.normal.background_color}
        
        ButtonColorHover={props => props.theme.buttons.primary_button_violet.hovered.color}
        ButtonBorderHover={props => props.theme.buttons.primary_button_violet.hovered.border_color} 
        ButtonBackgroundHover={props => props.theme.buttons.primary_button_violet.hovered.background_color}

        ButtonColorPressed={props => props.theme.buttons.primary_button_violet.pressed.color}
        ButtonBorderPressed={props => props.theme.buttons.primary_button_violet.pressed.border_color} 
        ButtonBackgroundPressed={props => props.theme.buttons.primary_button_violet.pressed.background_color}
      ></Section6>
    </Layout>   
  )
}
import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Layout from "../components/layout";
import Section1 from "../sections/new-themed-mentorship-section1";
import Section2 from "../sections/index-section7-multiple-use";
import Section3 from "../sections/new-themed-mentorship-section3-multiple-use";
import Section4 from "../sections/index-section5-multiple-use";
import Section5 from "../sections/new-themed-mentorship-section2";
import SEO from "../components/seo"

export default function ExcitingNewOffer() {
  return (
    <Layout 
      LogoColor={props => props.theme.colors.secondary_green} 
      HeaderMenuColor={props => props.theme.colors.gray_dark} 
      firstSectionBGColor={props => props.theme.colors.white}

      //button
      HeaderButtonColor={props => props.theme.buttons.primary_small_button_yellow_gray.normal.color} 
      HeaderButtonBorder={props => props.theme.buttons.primary_small_button_yellow_gray.normal.border_color} 
      HeaderButtonBackground={props => props.theme.buttons.primary_small_button_yellow_gray.normal.background_color}
      
      HeaderButtonColorHover={props => props.theme.buttons.primary_small_button_yellow_gray.hovered.color}
      HeaderButtonBorderHover={props => props.theme.buttons.primary_small_button_yellow_gray.hovered.border_color} 
      HeaderButtonBackgroundHover={props => props.theme.buttons.primary_small_button_yellow_gray.hovered.background_color}

      HeaderButtonColorPressed={props => props.theme.buttons.primary_small_button_yellow_gray.pressed.color}
      HeaderButtonBorderPressed={props => props.theme.buttons.primary_small_button_yellow_gray.pressed.border_color} 
      HeaderButtonBackgroundPressed={props => props.theme.buttons.primary_small_button_yellow_gray.pressed.background_color}
    >
      <SEO title={"Growth Circle"} description={"Empower each other and reach your fullest potential with like-minded mentees and mentors as your support system."} />
      <Section1></Section1>
      <Section5></Section5>
      <Section2
        Title = "WomenWorks Growth Circle"
        TitleTextAlign = "center"
        Names = {[
          {
            photo:"nina-karina-nikicio.png",
            name:"Nina Karina Nikicio",
            title:"VP of Marketing at Wif World",
            description:"Nina was the mastermind behind the iconic Indonesian fashion brand Nikicio that helped shape the local fashion industry since 2008. Nina also teaches Entrepreneurship at Binus International University and is a mother of two!",
            
            section2Subtitle:"Building Your Personal Brand",
            section2Check1:"Discovering yourself and your inner brand",
            section2Check2:"Communicating your values",
            section2Check3:"Using your personal brand to succeed in your career path",
            section2Check4:"Tailored review of your personal goals to achieve by having a personal brand",
            section2GroupSize:"5-10 women",
            section2Duration:"1 hour per session(s) within 2 months (available 1 up to 4 sessions)",
            section2Price:"IDR 200,000 - 1,000,000 per person*",
            secion2FriendText:"*Depending on the chosen package, discount available",
            section2VideoTitle:"Nina’s Introduction",
            section2ButtonLink: "http://bit.ly/growthcirclewomenworks-nina",

            section3Communities1:"Binus International University",
            section3Communities2:"Brightspot Market",
            section3Communities3:"",
            section3Communities4:"",
            section31o1Text:"Nina is available for mentorship for women entrepreneurs looking to advance their brand or mid level professionals looking to enhance their personal brand.",
            section3Book1:"Brain Maker: The Power of Gut Microbes to Heal and Protect Your Brain for Life",
            section3Book1Author:"David Perlmutter and Kristin Loberg",
            section3Book2:"",
            section3Book2Author:""
          },
          {
            photo:"alamanda-shantika.png",
            name:"Alamanda Shantika",
            title:"Founder and President Director of Binar Academy",
            description:"An entrepreneur with 11 years experience in tech and startup, Alamanda is now the Founder and President Director of Binar Academy. She’s passionate in bringing technology to life and delivering happiness.",
            
            section2Subtitle:"Building a Technology Driven Start-up",
            section2Check1:"Building your business’ vision and mission.",
            section2Check2:"Getting your business to product market fit",
            section2Check3:"Calculating your financial projections",
            section2Check4:"Building a bad-ass team",
            section2GroupSize:"5-10 women",
            section2Duration:"1 hour per session(s) within 2 months (available 1 up to 4 sessions)",
            section2Price:"IDR 200,000 - 1,000,000 per person*",
            secion2FriendText:"*Depending on the chosen package, discount available",
            section2VideoTitle:"Alamanda’s Introduction",
            section2ButtonLink: "http://bit.ly/growthcirclewomenworks-alamanda",

            section3Communities1:"Binar Academy",
            section3Communities2:"Selected gov.officials & corporates",
            section3Communities3:"Impact investors",
            section3Communities4:"Startup communities",
            section31o1Text:"Alamanda is available for mentorship for women entrepreneurs looking to advance/build/scale their business or those looking to shift careers.",
            section3Book1:"Hooked: How to Build Habit-Forming Products",
            section3Book1Author:"Nir Eyal",
            section3Book2:"",
            section3Book2Author:""
          },
          {
            photo:"dayu-dara-permata.png",
            name:"Dayu Dara Permata",
            title:"CEO and Founder of Pinhome",
            description:"Known as one of the creators of Indonesia’s largest on-demand lifestyle app, Dara is now revolutionizing the property industry through tech.",
            
            section2Subtitle:"Transforming Your Idea Into a Validated Prototype",
            section2Check1:"Startup 101 : Knowing the life cycle",
            section2Check2:"Testing your idea and conducting market validation",
            section2Check3:"Create your product prototype",
            section2Check4:"Piloting your MVP",
            section2GroupSize:"5-10 women",
            section2Duration:"1 hour per session(s) within 2 months (available 1 up to 4 sessions)",
            section2Price:"IDR 200,000 - 1,000,000 per person*",
            secion2FriendText:"*Depending on the chosen package, discount available",
            section2VideoTitle:"Dara's Introduction",
            section2ButtonLink: "http://bit.ly/growthcirclewomenworks-dayu",

            section3Communities1:"Sequoia Spark",
            section3Communities2:" More Girls in Tech Events",
            section3Communities3:" Startup and VCs communities",
            section3Communities4:"ITB Startup communities",
            section31o1Text:"Dara is available for mentorship for women entrepreneurs looking to advance/build/scale their business or those looking to shift careers.",
            section3Book1:"The Lean Startup",
            section3Book1Author:"Eric Ries",
            section3Book2:"The Art of The Start",
            section3Book2Author:"Guy Kawasaki"
          },
          {
            photo:"melina-subastian.png",
            name:"Melina Subastian",
            title:"Principal, Investment of Alpha JWC Ventures",
            description:"A passionate venture capitalist for tech and SME with top-tier management consulting experience.",
            
            section2Subtitle:"Getting Your Business Funded",
            section2Check1:"Refining your business concept and pitch deck",
            section2Check2:"Defining funding amount and strategy",
            section2Check3:"Connecting with the right investors",
            section2Check4:"Practicing your pitch presentation",
            section2GroupSize:"5-10 women",
            section2Duration:"1 hour per session(s) within 2 months (available 1 up to 4 sessions)",
            section2Price:"IDR 200,000 - 1,000,000 per person*",
            secion2FriendText:"*Depending on the chosen package, discount available",
            section2VideoTitle:"Melina’s Introduction",
            section2ButtonLink: "http://bit.ly/growthcirclewomenworks-melina",

            section3Communities1:"Select VC Companies",
            section3Communities2:"Consulting",
            section3Communities3:"Startup accelerators",
            section3Communities4:"Select potential business partners",
            section31o1Text:"Melina is available for mentorship for women entrepreneurs looking to advance/build/ scale their business or those looking to shift careers.",
            section3Book1:"Make Time: How to Focus on what Matters Every Day",
            section3Book1Author:"Jake Knapp",
            section3Book2:"",
            section3Book2Author:""
          },
          {
            photo:"clairine-runtung.png",
            name:"Clairine Runtung",
            title:"Incoming Consultant at Bain and Company, MBA at Yale University",
            description:"Passionate about women and youth empowerment, Clairine’s professional experience spans in the intersection between technology, social impact and private sectors.",
            
            section2Subtitle:"Landing your Dream Master Program",
            section2Check1:"Creating a gameplan to prepare your application",
            section2Check2:"Preparing a compelling essay",
            section2Check3:"Practice your admission interview",
            section2Check4:"",
            section2GroupSize:"5-10 women",
            section2Duration:"1 hour per session(s) within 2 months (available 1 up to 4 sessions)",
            section2Price:"IDR 200,000 - 1,000,000 per person*",
            secion2FriendText:"*Depending on the chosen package, discount available",
            section2VideoTitle:"Clairine’s Introduction",
            section2ButtonLink: "http://bit.ly/growthcirclewomenworks-clairine",

            section3Communities1:"Yayasan Cinta Anak Bangsa",
            section3Communities2:"Select VCs and management consulting firms",
            section3Communities3:"Yale Univ. Alumni Indonesia",
            section3Communities4:"",
            section31o1Text:"Clairine is available for mentorship for kickstarters, mid-level professionals looking to obtain Master degrees and professionals interested in tech/social enterprise.",
            section3Book1:"Pachinko",
            section3Book1Author:"Min Jin Lee",
            section3Book2:"Communion: The Female Search for Love",
            section3Book2Author:"Bell Hooks"
          },
          {
            photo:"natalia-rialucky-marsudi.png",
            name:"Natalia Rialucky Marsudi",
            title:"Consultant & Head of Social Impact at Boston Consulting Group",
            description:"Seasoned business consultant and investment professional. Passionate in social entrepreneurship and youth leadership.",
            
            section2Subtitle:"Charting your Career Journey",
            section2Check1:"Mapping out your possible career path",
            section2Check2:"Preparing your toolkit : CV, Cover Letter and LinkedIn",
            section2Check3:"Tips to ace your job fit interview",
            section2Check4:"",
            section2GroupSize:"5-10 women",
            section2Duration:"2 sessions within 2 months",
            section2Price:"IDR 800.000 for 2 sessions",
            secion2FriendText:"Bring your friend to join and you each will pay only IDR 600K",
            section2VideoTitle:"Ria’s Introduction",
            section2ButtonLink: "http://bit.ly/growthcirclewomenworks-natalia",

            section3Communities1:"Management Consulting",
            section3Communities2:"Investment firms",
            section3Communities3:"Various companies (Fintechs, E-commerce, Soc.Enterprises)",
            section3Communities4:"",
            section31o1Text:"Ria is available for mentorship for kickstarters looking to start their career or advancers looking to excel in their current roles or change industries.",
            section3Book1:"Grit",
            section3Book1Author:"Angela Duckwort",
            section3Book2:"Zero to One",
            section3Book2Author:"Peter Thiel"
          }

        ]}
      ></Section2>
      <Section3
        BackgroundLeft = {props => props.theme.colors.secondary_green10}
        BackgroundRight = {props => props.theme.colors.secondary_green_shade20}
        TextColor = {props => props.theme.colors.white}

        TitleLeft = "Lead, motivate, and inspire."
        ContentLeft = "Mentorship and access to WomenWorks LadyBosses network could benefit women in different stages of professional career."

        TitleRight= "Still have questions?"
        ContentRight = "On the fence about investing your time for a mentorship? Sign up for a free 15-minute pre-mentorship consultation session!"

        ButtonTextLeft = "Become a mentor"
        ButtonLinkLeft = "/be-a-mentor/"
        ButtonTextRight = "Connect with the WomenWorks team"
        ButtonLinkRight = "https://bit.ly/prementorshipWW"

        ButtonColorLeft={props => props.theme.buttons.primary_button_yellow_green.normal.color} 
        ButtonBorderLeft={props => props.theme.buttons.primary_button_yellow_green.normal.border_color} 
        ButtonBackgroundLeft={props => props.theme.buttons.primary_button_yellow_green.normal.background_color}
        
        ButtonColorHoverLeft={props => props.theme.buttons.primary_button_yellow_green.hovered.color}
        ButtonBorderHoverLeft={props => props.theme.buttons.primary_button_yellow_green.hovered.border_color} 
        ButtonBackgroundHoverLeft={props => props.theme.buttons.primary_button_yellow_green.hovered.background_color}

        ButtonColorPressedLeft={props => props.theme.buttons.primary_button_yellow_green.pressed.color}
        ButtonBorderPressedLeft={props => props.theme.buttons.primary_button_yellow_green.pressed.border_color} 
        ButtonBackgroundPressedLeft={props => props.theme.buttons.primary_button_yellow_green.pressed.background_color}

        ButtonColorRight={props => props.theme.buttons.primary_button_yellow_green.normal.color} 
        ButtonBorderRight={props => props.theme.buttons.primary_button_yellow_green.normal.border_color} 
        ButtonBackgroundRight={props => props.theme.colors.accent_yellow20}
        
        ButtonColorHoverRight={props => props.theme.buttons.primary_button_yellow_green.hovered.color}
        ButtonBorderHoverRight={props => props.theme.buttons.primary_button_yellow_green.hovered.border_color} 
        ButtonBackgroundHoverRight={props => props.theme.buttons.primary_button_yellow_green.hovered.background_color}

        ButtonColorPressedRight={props => props.theme.buttons.primary_button_yellow_green.pressed.color}
        ButtonBorderPressedRight={props => props.theme.buttons.primary_button_yellow_green.pressed.border_color} 
        ButtonBackgroundPressedRight={props => props.theme.buttons.primary_button_yellow_green.pressed.background_color}
      ></Section3>
      <Section4 
        DisplaySection='none'
      >
      </Section4>
    </Layout>   
  )
}

import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { Global, css } from "@emotion/core";
import { ThemeProvider } from 'emotion-theming';
import styled from "@emotion/styled";
import themeWW from "../styles/theme-ww";
import NavbarMenu from "../components/navbar-menu";

// First Section
const FirstSectionBackground = styled.div`
  background-color: ${props => props.backgroundColor};
`

// To Push Footer to the bottom of the page
const Wrap = styled.div`
  margin: 0 auto -81px;
  min-height: 100%;
  height: auto !important;
  height: 100%;
  background-color: ${props => props.backgroundColor};
`

const Push = styled.div`
  height: 81px;
`

const AltFooter = styled.div`
    background-color : ${props => props.theme.colors.primary_violet_shade20};
    color : ${props => props.theme.colors.white_dark};
    justify-content:center;
    display:flex;
    flex-wrap:wrap;
    flex-direction:row;
    min-height:81px;
    width:100%;
`

const FooterRow = styled(Row)`
  @media(max-width:991px){
    flex-direction:column;
    text-align:center;
  }
`
const FooterTitle = styled.div`
  font: 32px/58px Abril Fatface;
	margin-right: 50px;
  margin-top: 9px;
  @media(max-width:991px){
    margin-top: 20px;
    margin-right: 0px;
  }
`

const FooterItem = styled.p`
  margin-right: 25px;
  margin-top: 30px;
  @media(max-width:991px){
    margin-top:5px;
    margin-right: 0px;
  }
  a {
    color: ${props => props.theme.colors.white_dark} !important;
    text-decoration: underline;
  }
`
const FooterItemLast = styled.p`
  margin-right: 25px;
  margin-top: 30px;
  @media(max-width:991px){
    margin-top:5px;
    margin-bottom:30px;
    margin-right: 0px;
  }
  a {
    color: ${props => props.theme.colors.white_dark} !important;
    text-decoration: underline;
  }
`

export default function Layout(props) {
  return (
    <ThemeProvider theme={themeWW}>
      <Global
        //To Push Footer to the bottom of the page
        styles={css`
        html,body, #___gatsby, #gatsby-focus-wrapper {
            height:100%;
            font-family: "Roboto"
        }
        `}
      />
      <Wrap backgroundColor={props.firstSectionBGColor}>
        <FirstSectionBackground className="first-section-background" backgroundColor={props.firstSectionBGColor}>
            <NavbarMenu
                LogoColor={props.LogoColor} 
                HeaderMenuColor={props.HeaderMenuColor} 
            
                //button
                HeaderButtonColor={props.HeaderButtonColor} 
                HeaderButtonBorder={props.HeaderButtonBorder} 
                HeaderButtonBackground={props.HeaderButtonBackground}
                
                HeaderButtonColorHover={props.HeaderButtonColorHover}
                HeaderButtonBorderHover={props.HeaderButtonBorderHover} 
                HeaderButtonBackgroundHover={props.HeaderButtonBackgroundHover}
            
                HeaderButtonColorPressed={props.HeaderButtonColorPressed}
                HeaderButtonBorderPressed={props.HeaderButtonBorderPressed} 
                HeaderButtonBackgroundPressed={props.HeaderButtonBackgroundPressed}
            >
            </NavbarMenu>
            {props.children}
        </FirstSectionBackground>
        <Push></Push>
       </Wrap>
        <AltFooter className="bg-location">
            <FooterRow>
                <FooterTitle>Women Works</FooterTitle> 
                <FooterRow> 
                <FooterItem>©2020 All Rights Reserved</FooterItem>
                <FooterItem  css={css`@media(max-width:767px){display:none}@media(max-width:1199px){display:none}`}>|</FooterItem>
                <FooterItem>
                    Contact us at <OutboundLink target="_blank" rel="noreferrer" href="mailto:yours@womenworks.io"> yours@womenworks.io</OutboundLink>
                </FooterItem>
                <FooterItem css={css`@media(max-width:767px){display:none}@media(max-width:1199px){display:none}`}>|</FooterItem>
                <FooterItemLast>
                <Link to={'/terms-of-use'}>
                    Terms of Use & Privacy Policy
                </Link>
                </FooterItemLast>
                </FooterRow>
            </FooterRow>
        </AltFooter>
    </ThemeProvider>
  )
}

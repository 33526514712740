import React from "react";
import { Link } from "gatsby";
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import styled from "@emotion/styled";
import { Global, css } from "@emotion/core";
import { ThemeProvider } from 'emotion-theming'
import themeWW from "../styles/theme-ww";


// Header
const MenuBrand = styled(Navbar.Brand)`
  color: ${props => props.color} !important;
  font: 32px/58px Abril Fatface;
`
const MenuItem = styled(Navbar.Text)`
  padding-right: 21px;
  font: 16px/22px Roboto;
  color: ${props => props.color} !important;
  &:hover {
      color:${props => props.theme.colors.accent_yellow} !important;
  }
`
const ButtonHeader = styled(Button)`
  height: 41px;
  font: 16px/22px Roboto;
  color: ${props => props.color};
  background-color: ${props => props.backgroundColor};
  border-color: ${props => props.borderColor};
  transition: 0.25s ease-out;
  &:hover, &:focus, &.focus, &:active, &.active  {
    color: ${props => props.colorHover} !important;
    background-color: ${props => props.backgroundColorHover} !important;
    border-color: ${props => props.borderColorHover} !important;
    box-shadow: 0px 3px 20px ${props => props.theme.colors.gray_shadow} !important;
    margin-top:-3px;
    margin-bottom:3px;
  }
  &:focus, &.focus, &:active, &.active {
    color: ${props => props.colorPressed} !important;
    background-color: ${props => props.backgroundColorPressed} !important;
    border-color: ${props => props.theme.borderColorPressed} !important;
    box-shadow: none !important;
    margin-top:-3px;
    margin-bottom:3px;
  }
  @media(max-width:767px){
    width:100%;
  }
`

export default function Layout(props) {
  return (
    <ThemeProvider theme={themeWW}>
    <Global
      //To Push Footer to the bottom of the page
      styles={css`
      html,body, #___gatsby, #gatsby-focus-wrapper {
          height:100%;
          font-family: "Roboto"
      }
      `}
    />
          <Navbar expand="lg">
            <Container>
            <Link to={`/`} title={`Empowering the world with #bad-ass, #connected, and #financially-independent Indonesian women`}>
                <MenuBrand color={props.LogoColor}>WomenWorks</MenuBrand>
              </Link>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                  <Link to={`/growth-circle/`} title={`Empower each other and reach your fullest potential with like-minded mentees and mentors as your support system.`}>
                      <MenuItem color={props.HeaderMenuColor}>New: Growth Circle</MenuItem>
                  </Link>
                  <Link to={`/who-we-are/`} title={`WomenWorks is the place for women to connect, empower, and reach their fullest potential. We are bad-ass. Connected. Financially independent.`}>
                      <MenuItem color={props.HeaderMenuColor}>Who We Are</MenuItem>
                  </Link>
                  <Link to={`/be-a-mentor/`} title={`Join the community and empower others and help close the gender gap that is still prevalent in Indonesia’s workforce today`}>
                      <MenuItem color={props.HeaderMenuColor}>Be a Mentor</MenuItem>
                  </Link>
                  <Link to={`/sign-up-for-mentorship-coaching/`} title={`Sign up for WomenWorks personalised 1-on-1 mentorship / coaching and access to mentors’ professional network to help through every stage of your professional life.`}>
                      <ButtonHeader variant="outline-light" 
                        color={props.HeaderButtonColor} 
                        backgroundColor={props.HeaderButtonBackground} 
                        borderColor={props.HeaderButtonBorder} 
                        colorHover={props.HeaderButtonColorHover}
                        backgroundColorHover={props.HeaderButtonBackgroundHover}
                        borderColorHover={props.HeaderButtonBorderHover}
                        colorPressed={props.HeaderButtonColorPressed}
                        backgroundColorPressed={props.HeaderButtonBackgroundPressed}
                        borderColorPressed={props.HeaderButtonBorderPressed}
                      >
                          Sign up for mentorship
                      </ButtonHeader>
                  </Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
          {props.children}
      </ThemeProvider>
  )
}

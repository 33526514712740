import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Layout from "../components/layout";
import Section1 from "../sections/index-section4-multiple-use";
import Section2 from "../sections/sign-up-for-mentorship-section2";
import Section3 from "../sections/index-section5-multiple-use";
import Section4 from "../sections/index-section6-multiple-use";
import SEO from "../components/seo"

export default function SignUpforMentorship() {
  return (
    <Layout 
      LogoColor={props => props.theme.colors.secondary_green} 
      HeaderMenuColor={props => props.theme.colors.gray_dark} 
      firstSectionBGColor={props => props.theme.colors.gray_light_background}

      //button
      HeaderButtonColor={props => props.theme.buttons.primary_small_button_yellow_gray.normal.color} 
      HeaderButtonBorder={props => props.theme.buttons.primary_small_button_yellow_gray.normal.border_color} 
      HeaderButtonBackground={props => props.theme.buttons.primary_small_button_yellow_gray.normal.background_color}
      
      HeaderButtonColorHover={props => props.theme.buttons.primary_small_button_yellow_gray.hovered.color}
      HeaderButtonBorderHover={props => props.theme.buttons.primary_small_button_yellow_gray.hovered.border_color} 
      HeaderButtonBackgroundHover={props => props.theme.buttons.primary_small_button_yellow_gray.hovered.background_color}

      HeaderButtonColorPressed={props => props.theme.buttons.primary_small_button_yellow_gray.pressed.color}
      HeaderButtonBorderPressed={props => props.theme.buttons.primary_small_button_yellow_gray.pressed.border_color} 
      HeaderButtonBackgroundPressed={props => props.theme.buttons.primary_small_button_yellow_gray.pressed.background_color}
    >

      <SEO title={"Sign Up for Mentorship & Coaching"} description={"Sign up for WomenWorks personalised 1-on-1 mentorship / coaching and access to mentors’ professional network to help through every stage of your professional life."} />
      
      <Section1
        Title = "Sign up, get a mentor and supercharge your career growth"
        Color = {props => props.theme.colors.gray_dark} 
        Background = {props => props.theme.colors.gray_light_background} 
        MarginTop = "61px"
        CardTextDescriptionDisplay = "none"
      ></Section1>
      <Section2></Section2>
      <Section3
        DisplaySection='block'
      >
      </Section3>
      <Section4
        Background = {props => props.theme.colors.secondary_green10}
        TextColor = {props => props.theme.colors.white}
        ColorHeart = {props => props.theme.colors.accent_yellow}

        ButtonColor={props => props.theme.buttons.primary_button_yellow_green.normal.color} 
        ButtonBorder={props => props.theme.buttons.primary_button_yellow_green.normal.border_color} 
        ButtonBackground={props => props.theme.buttons.primary_button_yellow_green.normal.background_color}
        
        ButtonColorHover={props => props.theme.buttons.primary_button_yellow_green.hovered.color}
        ButtonBorderHover={props => props.theme.buttons.primary_button_yellow_green.hovered.border_color} 
        ButtonBackgroundHover={props => props.theme.buttons.primary_button_yellow_green.hovered.background_color}

        ButtonColorPressed={props => props.theme.buttons.primary_button_yellow_green.pressed.color}
        ButtonBorderPressed={props => props.theme.buttons.primary_button_yellow_green.pressed.border_color} 
        ButtonBackgroundPressed={props => props.theme.buttons.primary_button_yellow_green.pressed.background_color}
      ></Section4>
    </Layout>   
  )
}

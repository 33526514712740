import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Link from "../components/link";
import styled from "@emotion/styled";
import {css} from "@emotion/core";

const BeAMentorSection4Background = styled.div`
    background-color:${props => props.theme.colors.secondary_green10};
    color:${props => props.theme.colors.white};
    min-height:715px;
    @media(min-width:992px){
      height:715px;
    }
    @media(max-width:767px){
      min-height:0;
    }
`

const BeAMentorSection4Text = styled(Row)`
    font: 20px/28px Roboto;
    margin-top: 50px;
    @media(min-width:1199px) {
      margin-top: 130px;
      margin-bottom:35px;
    }
    @media(max-width:992px) {
      margin-top: 35px;
      margin-bottom:15px;
    }
    @media(max-width:767px) {
      margin-top: 0px;
      margin-bottom: 20px;
    }
`

const ButtonFill6 = styled(Button)`
  height: 60px;
  color: ${props => props.theme.buttons.primary_button_yellow_green.normal.color} ;
  background-color: ${props => props.theme.buttons.primary_button_yellow_green.normal.background_color};
  border-color: ${props => props.theme.buttons.primary_button_yellow_green.normal.border_color} ;
  font: 22px/22px Roboto;
  transition: 0.25s ease-out;
  &:hover {
    color: ${props => props.theme.buttons.primary_button_yellow_green.hovered.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_yellow_green.hovered.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_yellow_green.hovered.border_color} !important;
    box-shadow: 0px 3px 20px ${props => props.theme.colors.gray_shadow} !important;
    @media(min-width:1199px){
      margin-top:-3px;
      margin-bottom:3px;
    }
  }
  &:focus, &.focus, &:active, &.active {
    color: ${props => props.theme.buttons.primary_button_yellow_green.pressed.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_yellow_green.pressed.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_yellow_green.pressed.border_color}  !important;
    box-shadow: none !important;
    @media(min-width:1199px){
      margin-top:-3px;
      margin-bottom:3px;
    }
  }
  @media(max-width:992px){
    width:100%;
    margin-bottom:50px;
  }
`

export default function BeAMentorSection4() {
  return (
  <BeAMentorSection4Background>
        <img alt="WomenWorks - two young woman looking at laptop" src="/page-wailist-section1-img1@2x.png"
        css={css`
          @media(max-width:1199px) {
            display:none;
          }
          @media(min-width:1200px) {
            position: absolute;
            right: 0;
            height: 715px;
            max-width: 50vw;
            object-fit: cover;
          }
          @media(min-width:1600px) {
            display:none;
          }
      `}></img>
    <Container>
        <Row>
        <Col lg="6" xs="12"></Col>
        <Col xs="12" lg="6">
            <img alt="WomenWorks - two young woman looking at laptop" src="/page-wailist-section1-img1@2x.png"
                    css={css`
                      @media(max-width:767px) {
                        width:100%;
                        margin-bottom:15px;
                        margin-top:50px;
                      }
                      @media(min-width:768px) {
                        object-fit: cover;
                        object-position: center 40%;
                        height: 250px;
                        width:100%;
                        margin-bottom:30px;
                        margin-top:50px;
                      }
                      @media(min-width:992px) {
                        display: block;
                        position: absolute;
                        width: 48.5vw;
                        height: 715px;
                        margin-top: 0;
                      }
                      @media(min-width:1199px) {
                        display:none;
                      }
                      @media(min-width:1600px) {
                        display:block;
                        position:absolute;
                        width: 720px;
                        height: 715px;
                        margin-top:0;
                      }
                  `}></img>
            </Col>
          <Col lg="5" xs="12">
              <BeAMentorSection4Text>
                  <Col>
                    <p>
                      “Having had mentors to help carve out our career paths with their invaluable insights and practical advice that came from a lived real-life experience on the field has been a great encouragement, if not life changing to say the least.<br /><br />
                      What may seem like a no brainer to us now might have been a complete mystery just a few years ago. Even in the age of advanced technology where unlimited sources of information is always one click away, we believe nothing quite beats the impact of a face-to-face intentional conversation with inspiring prominent figures who are committed to see us grow and succeed.“ <b>— WomenWorks Team</b>
                    </p>
                  </Col>
              </BeAMentorSection4Text>
              <Row>
                <Col>
                  <Link to={`/who-we-are/`}>
                      <ButtonFill6>Meet the WomenWorks team</ButtonFill6>
                  </Link>
                </Col>
              </Row>
          </Col>
        </Row>
    </Container>
  </BeAMentorSection4Background>
  )
}

import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import styled from "@emotion/styled";
import {css} from "@emotion/core";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

const FourthSectionBackground = styled.div`
  background-color : ${props => props.backgroundColor};
  color : ${props => props.theme.colors.gray_dark};
  min-height: 1093px;  
`
const FourthSectionTitle = styled.div`
  color: ${props => props.color};
  margin-top:${props => props.marginTop};
  font: 58px/62px Abril Fatface;
  text-align:center;
  @media(max-width:767px){
    font: 2.5rem/3rem Abril Fatface;
    margin-top:50px;
  }
  @media(max-width:320px){
    font: 2rem/2.5rem Abril Fatface;
  }
`
const FourthSectionSubtitle = styled.div`
  margin-top:35px;
  margin-bottom:50px;
  font: 20px/28px Roboto;
  text-align:center;
  @media(max-width:767px){
    margin-top:20px;
    margin-bottom:15px;
  }
`
const FourthSectionCard = styled(Card)` 
  border: 2px solid ${props => props.theme.colors.white_dark};
  box-shadow: 0px 1px 3px ${props => props.theme.colors.gray_shadow_card};
  @media(min-width:1200px){
    width:265px;
  }
  &.fourth-card {
    margin-bottom:127px;
  }
  @media(max-width: 767px){
    margin-bottom:25px;
    &.first-card {
      margin-bottom:0;
    }
    &.fourth-card {
      margin-bottom:50px;
    }
  }
  transition: 0.25s ease-out;
  &:hover {
    @media(min-width:1200px){
      margin-top:-5px;
    }
    box-shadow: 0px 1px 10px ${props => props.theme.colors.gray_shadow_card_hover};
  }
  .card-img-top {
    width:100%;
    @media(min-width:1200px){
      height:150px;
      object-fit:none;
    }
  }
  .card-body {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 20px;
    padding-bottom: 20px;
    .card-title {
      font: 28px/32px Roboto;
      color: ${props => props.theme.colors.primary_violet};
      @media(min-width:1200px){
        min-height:75px;
      }
      @media(min-width:768px){
        min-height:64px;
      }
      margin-bottom:10px;
    }
    .card-text {
      margin-bottom:0;
      min-height:216px;
      .card-text--display {
        display: ${props => props.cardTextDescriptionDisplay} !important;
      }
    }
  }
`
const CardTextDescription = styled.div`
  font: 16px/22px Roboto;
  // on production, somehow this become class for button. these 2 lines below to make button centered
  display:flex;
  justify-content:center !important;
  @media(min-width:992px){
    min-height: 44px;
  }
  @media(min-width:1200px){
    min-height: 66px;
  }
`
const CardTextChecklist = styled.div`
  margin-top:5px;
  font: 16px/22px Roboto;
  @media(min-width:768px){
    min-height: 88px;
  }
  @media(min-width:992px){
    min-height: 71px;
  }
  @media(min-width:1200px){
    min-height: 116px;
  }
`
const CardTextCheckIcon = styled(Col)`
  padding-right:0;
`
const FACheck = styled(FontAwesomeIcon)`
  font-size:1rem;
  color: ${props => props.theme.colors.secondary_green10};
`
const CardTextCheckContent = styled(Col)`
  max-width:208px;
  padding-left:7px;
  padding-right:5px;
  @media(max-width:1199px){
    max-width:395px;
  }
  @media(max-width:991px){
    max-width:275px;
  }
  @media(max-width:359px){
    max-width:235px;
  }

`
const CardTextPrice = styled.div`
  font: Bold 16px/22px Roboto;
  margin-top:7px;
`

const CardTextQuestion = styled.div`
  font: 16px/22px Roboto;
  margin-top:15px;
  display: ${props => props.signUpPageDisplay};
`

const CardFirstRow = styled(Row)`
  display: ${props => props.signUpPageDisplay};
`

const CardButton = styled(Button)`
  margin-top:20px;
  font: 16px/22px Roboto;
  width: 127px;
  height: 47px;
  color: ${props => props.theme.buttons.primary_button_violet.normal.color};
  background-color: ${props => props.theme.buttons.primary_button_violet.normal.background_color};
  border-color: ${props => props.theme.buttons.primary_button_violet.normal.border_color};
  &:hover {
    color: ${props => props.theme.buttons.primary_button_violet.hovered.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_violet.hovered.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_violet.hovered.border_color} !important;
  }
  &:focus, &.focus, &:active, &.active {
    color: ${props => props.theme.buttons.primary_button_violet.pressed.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_violet.pressed.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_violet.pressed.border_color} !important;
  }
`
const TextPS = styled.div`
  font: 12px/16px Roboto;
  margin-top:10px;
  margin-bottom:25px;
  @media(min-width:1200px){
    margin-top:25px;
    margin-bottom:54px;
  }
`

export default function Home(props) {
  return (      
  <FourthSectionBackground backgroundColor={props.Background}>
    <Container>
      <Row className="justify-content-center">
        <Col md="12" lg="10">
          <FourthSectionTitle color={props.Color} marginTop={props.MarginTop}>
            {props.Title}
          </FourthSectionTitle>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="12" lg="10" xl="8">
          <FourthSectionSubtitle>
            Sign up for WomenWorks personalised 1-on-1 mentorship and access to mentors’ professional network to help through every stage of your professional life.
          </FourthSectionSubtitle>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <Row>
            <Col xs="12" md="6" xl="3">
                <FourthSectionCard className="first-card" cardTextDescriptionDisplay={props.CardTextDescriptionDisplay}>
                    <Card.Img alt="WomenWorks Program: Kick-Starters" variant="top" src="/page-home-section4-img1.png" />
                    <Card.Body>
                        <Card.Title>Kick-Starters</Card.Title>
                        <Card.Text>
                          <CardTextDescription  className="card-text--display">
                            For fresh-graduates or first-time job seekers
                          </CardTextDescription>
                          <CardTextChecklist>
                            <CardFirstRow signUpPageDisplay={props.SignUpPageDisplay}>
                                <CardTextCheckIcon xs="auto">
                                  <FACheck icon={faCheck} />
                                </CardTextCheckIcon>
                                <CardTextCheckContent xs="auto">
                                  For fresh-graduates or first-time job seekers
                                </CardTextCheckContent>
                              </CardFirstRow>
                              <Row>
                                <CardTextCheckIcon xs="auto">
                                  <FACheck icon={faCheck} />
                                </CardTextCheckIcon>
                                <CardTextCheckContent xs="auto">
                                  Mentoring on CV, interviews, or other chosen topics
                                </CardTextCheckContent>
                              </Row>
                              <Row>
                                <CardTextCheckIcon xs="auto">
                                  <FACheck icon={faCheck} />
                                </CardTextCheckIcon>
                                <CardTextCheckContent xs="auto">
                                  General career planning consultation
                                </CardTextCheckContent>
                              </Row>
                          </CardTextChecklist>
                          <CardTextQuestion signUpPageDisplay={props.SignUpPageDisplay} css={css`margin-bottom:25px;@media(min-width:991px){margin-bottom:38px;}@media(min-width:1199px){margin-bottom:0px;}`}>
                            <b>Questions you can ask:<br/></b> “How do I figure out what I want to do after I graduate? How do I best showcase my experience in a CV?”
                          </CardTextQuestion>
                          <CardTextPrice css={css`@media(min-width:1199px){margin-top:3px;}`}>
                            IDR 150k/45min session*
                          </CardTextPrice>
                        </Card.Text>
                        <div className="d-flex justify-content-center">
                          <OutboundLink target="_blank" rel="noreferrer" href="https://bit.ly/womenworksmenteesignupnew-kickstarters">
                            <CardButton>Sign up now</CardButton>
                          </OutboundLink>
                        </div>
                    </Card.Body>
                </FourthSectionCard>
                <TextPS>*If you wish to take more than one session, you only have to pay 50% upfront and complete the payment once you land the job</TextPS>
            </Col>
            <Col xs="12" md="6" xl="3">
                <FourthSectionCard cardTextDescriptionDisplay={props.CardTextDescriptionDisplay}>
                    <Card.Img alt="WomenWorks Program: Career Changers & Advancers" variant="top" src="/page-home-section4-img2.png" />
                    <Card.Body>
                        <Card.Title>Career Changers & Advancers</Card.Title>
                        <Card.Text >
                            <CardTextDescription  className="card-text--display" >
                              For mid-level professionals seeking to advance or change careers
                            </CardTextDescription>
                            <CardTextChecklist>
                              <CardFirstRow signUpPageDisplay={props.SignUpPageDisplay}>
                                  <CardTextCheckIcon xs="auto">
                                    <FACheck icon={faCheck} />
                                  </CardTextCheckIcon>
                                  <CardTextCheckContent xs="auto">
                                    For mid-level professionals seeking to advance or change careers
                                  </CardTextCheckContent>
                                </CardFirstRow>
                                <Row>
                                  <CardTextCheckIcon xs="auto">
                                    <FACheck icon={faCheck} />
                                  </CardTextCheckIcon>
                                  <CardTextCheckContent xs="auto">
                                    Mentoring with successful women leaders experienced in career-shift
                                  </CardTextCheckContent>
                                </Row>
                                <Row>
                                  <CardTextCheckIcon xs="auto">
                                    <FACheck icon={faCheck} />
                                  </CardTextCheckIcon>
                                  <CardTextCheckContent xs="auto">
                                    Connection to network with relevant opportunities
                                  </CardTextCheckContent>
                                </Row>   
                            </CardTextChecklist>
                            <CardTextQuestion signUpPageDisplay={props.SignUpPageDisplay} css={css`margin-bottom:24px;`}>
                              <b>Questions you can ask:</b><br/> “How do I shift industry or move career?”
                            </CardTextQuestion>
                            <CardTextPrice>
                              IDR 250k/45min session
                            </CardTextPrice>
                        </Card.Text>
                        <div className="d-flex justify-content-center">
                          <OutboundLink target="_blank" rel="noreferrer" href="https://bit.ly/womenworksmenteesignupnew-careerchangers">
                            <CardButton>Sign up now</CardButton>
                          </OutboundLink>
                        </div>
                    </Card.Body>
                </FourthSectionCard>
            </Col>
            <Col xs="12" md="6" xl="3">
                <FourthSectionCard cardTextDescriptionDisplay={props.CardTextDescriptionDisplay}>
                    <Card.Img alt="WomenWorks Program: Founders & Entrepreneurs" variant="top" src="/page-home-section4-img3.png" />
                    <Card.Body>
                        <Card.Title>Founders & Entrepreneurs</Card.Title>
                        <Card.Text >
                            <CardTextDescription  className="card-text--display" >
                              For women entrepreneurs or those planning to start new businesses
                            </CardTextDescription>
                            <CardTextChecklist>
                                <CardFirstRow signUpPageDisplay={props.SignUpPageDisplay}>
                                  <CardTextCheckIcon xs="auto">
                                    <FACheck icon={faCheck} />
                                  </CardTextCheckIcon>
                                  <CardTextCheckContent xs="auto">
                                    For women entrepreneurs or those planning to start new businesses
                                  </CardTextCheckContent>
                                </CardFirstRow>
                                <Row>
                                  <CardTextCheckIcon xs="auto">
                                    <FACheck icon={faCheck} />
                                  </CardTextCheckIcon>
                                  <CardTextCheckContent xs="auto">
                                    Mentoring with successful women founders
                                  </CardTextCheckContent>
                                </Row>
                                <Row>
                                  <CardTextCheckIcon xs="auto">
                                    <FACheck icon={faCheck} />
                                  </CardTextCheckIcon>
                                  <CardTextCheckContent xs="auto">
                                    Applicable insights on how to build your business from zero
                                  </CardTextCheckContent>
                                </Row>   
                            </CardTextChecklist>
                            <CardTextQuestion signUpPageDisplay={props.SignUpPageDisplay} css={css`margin-bottom:24px;`}>
                              <b>Questions you can ask:</b><br/> “How do I determine my audience and strategy?”
                            </CardTextQuestion>
                            <CardTextPrice>
                              IDR 250k/45min session
                            </CardTextPrice>
                        </Card.Text>
                        <div className="d-flex justify-content-center">
                          <OutboundLink target="_blank" rel="noreferrer" href="https://bit.ly/womenworksmenteesignupnew-founders">
                            <CardButton>Sign up now</CardButton>
                          </OutboundLink>
                        </div>
                    </Card.Body>
                </FourthSectionCard>
            </Col>
            <Col xs="12" md="6" xl="3">
                <FourthSectionCard className="fourth-card" cardTextDescriptionDisplay={props.CardTextDescriptionDisplay}>
                    <Card.Img alt="WomenWorks Program: Comebacks" variant="top" src="/page-home-section4-img4.png" />
                    <Card.Body>
                        <Card.Title>Comebacks</Card.Title>
                        <Card.Text >
                            <CardTextDescription  className="card-text--display" >
                              For women seeking to re-enter workforce
                            </CardTextDescription>
                            <CardTextChecklist>
                                <CardFirstRow signUpPageDisplay={props.SignUpPageDisplay}>
                                  <CardTextCheckIcon xs="auto">
                                    <FACheck icon={faCheck} />
                                  </CardTextCheckIcon>
                                  <CardTextCheckContent xs="auto">
                                    For women seeking to re-enter workforce
                                  </CardTextCheckContent>
                                </CardFirstRow>
                                <Row>
                                  <CardTextCheckIcon xs="auto">
                                    <FACheck icon={faCheck} />
                                  </CardTextCheckIcon>
                                  <CardTextCheckContent xs="auto">
                                    Mentoring with successful women leaders with similar experience
                                  </CardTextCheckContent>
                                </Row>
                                <Row>
                                  <CardTextCheckIcon xs="auto">
                                    <FACheck icon={faCheck} />
                                  </CardTextCheckIcon>
                                  <CardTextCheckContent xs="auto">
                                    Tips on transitioning back to working environment
                                  </CardTextCheckContent>
                                </Row>       
                            </CardTextChecklist>
                            <CardTextQuestion signUpPageDisplay={props.SignUpPageDisplay} css={css`margin-bottom:25px;`}>
                              <b>Questions you can ask:</b><br/> “How do I develop leadership skills after being out of the job market?”
                            </CardTextQuestion>
                            <CardTextPrice>
                              IDR 250k/45min session
                            </CardTextPrice>
                        </Card.Text>
                        <div className="d-flex justify-content-center">
                          <OutboundLink target="_blank" rel="noreferrer" href="https://bit.ly/womenworksmenteesignupnew-comebacks">
                            <CardButton>Sign up now</CardButton>
                          </OutboundLink>
                        </div>
                    </Card.Body>
                </FourthSectionCard>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  </FourthSectionBackground>
  )
}
import React from "react"
import styled from "@emotion/styled";
import {css} from "@emotion/core";
import Layout from "./layout"
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import { Link, ModalRoutingContext } from "gatsby-plugin-modal-routing"

const BackLink = styled(Link)`
  color: ${props => props.theme.colors.primary_violet};
  font: Bold 16px/50px Roboto;
  margin-bottom:5px;
  &:hover {
    color: ${props => props.theme.colors.gray_dark};
    text-decoration:none;
}
`

const ConditionalLayout = ({ children, ...rest }) => (
  <ModalRoutingContext.Consumer>
    {({ modal, closeTo }) => (
      modal ? (
        <React.Fragment>
          <Link to={closeTo}>
            Close
          </Link>
          {children}
        </React.Fragment>
      ) : (
        <Layout 
            LogoColor={props => props.theme.colors.secondary_green} 
            HeaderMenuColor={props => props.theme.colors.gray_dark} 
            firstSectionBGColor={props => props.theme.colors.white}

            //button
            HeaderButtonColor={props => props.theme.buttons.primary_small_button_yellow_gray.normal.color} 
            HeaderButtonBorder={props => props.theme.buttons.primary_small_button_yellow_gray.normal.border_color} 
            HeaderButtonBackground={props => props.theme.buttons.primary_small_button_yellow_gray.normal.background_color}
            
            HeaderButtonColorHover={props => props.theme.buttons.primary_small_button_yellow_gray.hovered.color}
            HeaderButtonBorderHover={props => props.theme.buttons.primary_small_button_yellow_gray.hovered.border_color} 
            HeaderButtonBackgroundHover={props => props.theme.buttons.primary_small_button_yellow_gray.hovered.background_color}

            HeaderButtonColorPressed={props => props.theme.buttons.primary_small_button_yellow_gray.pressed.color}
            HeaderButtonBorderPressed={props => props.theme.buttons.primary_small_button_yellow_gray.pressed.border_color} 
            HeaderButtonBackgroundPressed={props => props.theme.buttons.primary_small_button_yellow_gray.pressed.background_color}
        >
          <Container css={css`padding-bottom:50px;`}>  
            <BackLink to={"/the-ladybosses/"} >
                &lt; Back to The LadyBosses
            </BackLink>
            {children}
          </Container>
        </Layout>
      )
    )}
  </ModalRoutingContext.Consumer>
)

export default ConditionalLayout

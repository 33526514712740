import React from "react";
import { Link } from "gatsby";
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import styled from "@emotion/styled";
import {css} from "@emotion/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'
import Container from "react-bootstrap/Container";

const SecondSectionBackground = styled.div`
  background-color : ${props => props.theme.colors.primary_violet};
  color : ${props => props.theme.colors.accent_yellow};
  min-height:758px;
`

const SecondSectionDescription = styled(Col)`
  color : ${props => props.theme.colors.white};
  font: 20px/28px Roboto;
  text-align:center;
  margin-bottom:0;
}
`

const SecondSectionTitle = styled(Col)`
  margin-top:100px;
  font: 58px/62px Abril Fatface;
  text-align:center;
  margin-bottom:35px;
  @media(max-width:767px){
    font: 2.5rem/3rem Abril Fatface;
    margin-top:50px;
    margin-bottom:35px;
  }
`
const SecondSection = styled.div`
    display: flex;
    flex-wrap: wrap;
`

const Section2Col = styled(Col)`
   font: 28px/36px Roboto;
   margin-top:50px;
   text-align:center;
   margin-bottom:40px;
   @media(max-width:767px){
    margin-top: 35px;
    margin-bottom:0;
   }
`

const FAHeart = styled(FontAwesomeIcon)`
  font-size:1.5rem;
  color: ${props => props.theme.colors.white};
`

const ButtonWrapper = styled.div`
    margin-bottom:130px;
    width:100%;
    justify-content:center;
    display: flex;
    flex-wrap: wrap;
    @media(max-width:767px){
        margin-bottom:50px;
    }
`

const ButtonFill1 = styled(Button)`
  height: 60px;
  color: ${props => props.theme.buttons.primary_button_yellow_violet.normal.color};
  background-color: ${props => props.theme.buttons.primary_button_yellow_violet.normal.background_color};
  border-color: ${props => props.theme.buttons.primary_button_yellow_violet.normal.border_color};
  font: 22px/22px Roboto;
  transition: 0.25s ease-out;
  &:hover {
    color: ${props => props.theme.buttons.primary_button_yellow_violet.hovered.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_yellow_violet.hovered.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_yellow_violet.hovered.border_color} !important;
    box-shadow: 0px 3px 20px ${props => props.theme.buttons.primary_button_yellow_violet.hovered.shadow} !important;
    @media(min-width:1199px){
      margin-top:-3px;
    }
  }
  &:focus, &.focus, &:active, &.active {
    color: ${props => props.theme.buttons.primary_button_yellow_violet.pressed.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_yellow_violet.pressed.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_yellow_violet.pressed.border_color} !important;
    box-shadow: none !important;
    @media(min-width:1199px){
      margin-top:-3px;
    }
  }
  @media(min-width:1199px){
    width:272px;
  }
  @media(max-width:767px){
    width:100%;
    margin-top:35px;
    margin-bottom:0;
  }
`

const ItemTitle = styled.div`
  font: 28px/32px Abril Fatface;
  color: ${props => props.theme.colors.white};
  margin-bottom:15px;
`

const ItemContent = styled.div`
  font: 20px/28px Roboto;
  color: ${props => props.theme.colors.white};
`

export default function NewThemedMentorshipSection2() {
  return (      
  <SecondSectionBackground>
      <Container>
      <SecondSection css={css`margin-left:0px;margin-left:0px;`}>
            <Row css={css`justify-content:center;`}>
            <SecondSectionTitle xs="auto">
                WomenWorks - Clique
            </SecondSectionTitle>
            <SecondSectionDescription xs="auto">
                <b>Introducing the Clique</b>: A social platform app for aspiring women to find their support system and connect with other women in their journey of growth
            </SecondSectionDescription>
            </Row>
            <Row>
                <Section2Col xs="12" md="4">
                    <FAHeart icon={faHeart} /> 
                    <ItemTitle>Find your Clique</ItemTitle>
                    <ItemContent>You’ll meet a group of exclusively curated women ready to help and be helped according to their interests and goals.</ItemContent>
                </Section2Col>
                <Section2Col xs="12" md="4">
                    <FAHeart icon={faHeart} /> 
                    <ItemTitle>Build connections</ItemTitle>
                    <ItemContent>No more than 50 people in your inner clique, so you can build real, meaningful connections.</ItemContent>
                </Section2Col>
                <Section2Col xs="12" md="4">
                    <FAHeart icon={faHeart} /> 
                    <ItemTitle>Reach your goals</ItemTitle>
                    <ItemContent><b>#FindYourBuddy</b> to track your habits and growth journey, <b>#AskAnything</b> using voice-notes, and nudge features to help build meaningful connections as you grow</ItemContent>
                </Section2Col>
            </Row>
            <ButtonWrapper xs="12">
              <Link to={`/waitlist/`}>
                <ButtonFill1 variant="outline-light">
                    Join the waitlist
                </ButtonFill1>
              </Link>
            </ButtonWrapper>
      </SecondSection>
      </Container>
  </SecondSectionBackground>
  )
} 
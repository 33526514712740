import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Layout from "../components/layout";
import Section1 from "../sections/new-themed-mentorship-section1";
import Section2 from "../sections/index-section7-multiple-use";
import Section3 from "../sections/new-themed-mentorship-section3-multiple-use";
import Section4 from "../sections/index-section5-multiple-use";



export default function ExcitingNewOffer() {
  return (
    <Layout 
      LogoColor={props => props.theme.colors.secondary_green} 
      HeaderMenuColor={props => props.theme.colors.gray_dark} 
      firstSectionBGColor={props => props.theme.colors.white}

      //button
      HeaderButtonColor={props => props.theme.buttons.primary_small_button_yellow_gray.normal.color} 
      HeaderButtonBorder={props => props.theme.buttons.primary_small_button_yellow_gray.normal.border_color} 
      HeaderButtonBackground={props => props.theme.buttons.primary_small_button_yellow_gray.normal.background_color}
      
      HeaderButtonColorHover={props => props.theme.buttons.primary_small_button_yellow_gray.hovered.color}
      HeaderButtonBorderHover={props => props.theme.buttons.primary_small_button_yellow_gray.hovered.border_color} 
      HeaderButtonBackgroundHover={props => props.theme.buttons.primary_small_button_yellow_gray.hovered.background_color}

      HeaderButtonColorPressed={props => props.theme.buttons.primary_small_button_yellow_gray.pressed.color}
      HeaderButtonBorderPressed={props => props.theme.buttons.primary_small_button_yellow_gray.pressed.border_color} 
      HeaderButtonBackgroundPressed={props => props.theme.buttons.primary_small_button_yellow_gray.pressed.background_color}
    >
      <Section1></Section1>
      <Section2
        Names = {["Alanda Kariza","Clairine Runtung","Tantia Dian Permata Indah "]}
      ></Section2>
      <Section3
        Background = {props => props.theme.colors.accent_yellow70}
        BackgroundRight = {props => props.theme.colors.secondary_green10}
        BackgroundLeft = {props => props.theme.colors.secondary_green_shade20}
        TextColor = {props => props.theme.colors.white}

        TitleRight = "Lead, motivate, and inspire."
        ContentRight = "Mentorship and access to WomenWorks LadyBosses network could benefit women in different stages of professional career."

        TitleLeft= "Still have questions?"
        ContentLeft = "On the fence about investing your time for a mentorship? Sign up for a free 30-minute pre-mentorship consultation session to find out more!"

        ButtonTextRight = "Become a mentor"
        ButtonTextLeft = "Meet the LadyBosses"

        ButtonColorRight={props => props.theme.buttons.primary_button_yellow_green.normal.color} 
        ButtonBorderRight={props => props.theme.buttons.primary_button_yellow_green.normal.border_color} 
        ButtonBackgroundRight={props => props.theme.buttons.primary_button_yellow_green.normal.background_color}
        
        ButtonColorHoverRight={props => props.theme.buttons.primary_button_yellow_green.hovered.color}
        ButtonBorderHoverRight={props => props.theme.buttons.primary_button_yellow_green.hovered.border_color} 
        ButtonBackgroundHoverRight={props => props.theme.buttons.primary_button_yellow_green.hovered.background_color}

        ButtonColorPressedRight={props => props.theme.buttons.primary_button_yellow_green.pressed.color}
        ButtonBorderPressedRight={props => props.theme.buttons.primary_button_yellow_green.pressed.border_color} 
        ButtonBackgroundPressedRight={props => props.theme.buttons.primary_button_yellow_green.pressed.background_color}

        ButtonColorLeft={props => props.theme.buttons.primary_button_yellow_green.normal.color} 
        ButtonBorderLeft={props => props.theme.buttons.primary_button_yellow_green.normal.border_color} 
        ButtonBackgroundLeft={props => props.theme.colors.accent_yellow20}
        
        ButtonColorHoverLeft={props => props.theme.buttons.primary_button_yellow_green.hovered.color}
        ButtonBorderHoverLeft={props => props.theme.buttons.primary_button_yellow_green.hovered.border_color} 
        ButtonBackgroundHoverLeft={props => props.theme.buttons.primary_button_yellow_green.hovered.background_color}

        ButtonColorPressedLeft={props => props.theme.buttons.primary_button_yellow_green.pressed.color}
        ButtonBorderPressedLeft={props => props.theme.buttons.primary_button_yellow_green.pressed.border_color} 
        ButtonBackgroundPressedLeft={props => props.theme.buttons.primary_button_yellow_green.pressed.background_color}
      ></Section3>
      <Section4></Section4>
    </Layout>   
  )
}

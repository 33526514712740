import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import styled from "@emotion/styled";
import { Global, css} from "@emotion/core";
import Container from "react-bootstrap/Container";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagramSquare } from '@fortawesome/free-brands-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'

const FirstSectionDescription = styled.div`
  color : ${props => props.theme.colors.white};
  font: 20px/28px Roboto;
  margin-bottom:25.5px;
  text-align:left;
`

const FirstSectionTitle = styled.div`
  color : ${props => props.theme.colors.accent_yellow};
  margin-top:61px;
  font: 58px/62px Abril Fatface;
  text-align:left;
  margin-bottom:35px;
  @media(max-width:767px){
    font: 2.5rem/3rem Abril Fatface;
    margin-top:20px;
    margin-bottom:15px;
  }
`

const FASocmedButton = styled(FontAwesomeIcon)`
  font-size:3.3rem;
  margin-right:15px;
  color:${props => props.theme.colors.accent_yellow};
  transition: 0.25s ease-out;
  &:hover, &:focus, &.focus, &:active, &.active {
    color:${props => props.theme.colors.accent_yellow_hover};
    margin-top:-3px;
    margin-bottom:3px;
    cursor:pointer;
  }
`

const SocmedRow = styled.div`
  display:flex;
  flex-wrap:wrap;
  margin-bottom:129.5px;
`

const BackButtonWrapper = styled.div`
    display:flex;
    flex-wrap:wrap;
    font: 20px/28px Roboto;
    text-decoration: underline;
    margin-bottom:153px;
    a {
        color: ${props => props.theme.colors.accent_yellow} !important;
    }
    @media(max-width:767px){
      margin-bottom:50px;
    }
`

export default function ThankyouSection1(props) {
  return (      
    <Container>
      <Global
        //To Push Footer to the bottom of the page
        styles={css`
        .bg-location {
          @media(min-width:768px){
            :after {
              background-image: url('/page-home-section1-bg1.svg');
              content: '';
              display: block;
              position: absolute;
              width: 516px;
              height: 495px;
              top: 400px;
              right:0;
            }
          }
          @media(min-width:992px){
            :after {
              top:550px;
            }
          }
          @media(min-width:1200px){
            :after{
              top: 226px;
            }
          }
          @media(min-width:1600px){
            :after{
              display:none;
            }
          }
        }
        `}
      />
      <Row css={css`width:100%;`}>
          <Col md="12" xl="8">
              <FirstSectionTitle>
                Thank you for subscribing to our news!
              </FirstSectionTitle>
              <FirstSectionDescription>
                  {props.DescriptionText}
              </FirstSectionDescription>
              <SocmedRow >
                  <OutboundLink href="https://www.instagram.com/womenworksid/" target="_blank" rel="noreferrer">
                    <FASocmedButton icon={faInstagramSquare} />
                  </OutboundLink>
                  <OutboundLink href="https://www.linkedin.com/company/womenworksid/" target="_blank" rel="noreferrer">
                    <FASocmedButton icon={faLinkedin} />
                  </OutboundLink>
              </SocmedRow>
              <BackButtonWrapper>
                  <Link to={`/`}>Go Back to Home</Link>
              </BackButtonWrapper>
          </Col>
          <Col lg="12" xl="4" css={css`
          @media(min-width:1600px) {
            :after {
              background-image: url('/page-home-section1-bg1.svg');
              content: '';
              display: block;
              position: absolute;
              width: 516px;
              height: 495px;
              z-index:1;
              top: 55px;
              left: -15px;
            }
          }
        `}></Col>
      </Row>
    </Container>
  )
} 

import React from "react";
import { Link } from "gatsby";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from "@emotion/styled";
import {css} from "@emotion/core";
import Typed from 'react-typed';


const FirstSectionContainer = styled(Container)`
  @media(min-width:1200px){
    padding-top: 140px;
  }
  @media(max-width:1199px){
    padding-top: 70px;
    padding-bottom: 70px;
  }
  @media(min-width:768px){
    min-height: 725px;
  }
  @media(max-width:767px){
    padding-top: 0px;
    padding-bottom: 50px;
  }
`
const HomeTitle = styled.div`
  color: ${props => props.theme.colors.accent_yellow};
  @media(min-width:1200px){
    font: 98px/102px Abril Fatface;
    min-height:204px;
  }
  @media(max-width:1199px){
    font: 98px/102px Abril Fatface;
    min-height:408px;
  }
  @media(max-width:991px){
    font: 98px/102px Abril Fatface;
    min-height:408px;
  }
  @media(max-width:767px){
    font: 3.3rem/4rem Abril Fatface;
    min-height:213px;
  }
  @media(max-width:399px){
    font: 3.3rem/4rem Abril Fatface;
    min-height:284px;
  }
  @media(max-width:320px){
    font: 3rem Abril Fatface;
    min-height:276px;
  }
`
const HomeSubtitle = styled.div`
  margin-top: 35px;
  color: ${props => props.theme.colors.white};
  font: 20px/28px Roboto;
  @media(max-width:767px){
    margin-top: 20px;
  }
`
const ButtonFill1 = styled(Button)`
  width:100%;
  margin-top:25px;
  height: 60px;
  color: ${props => props.theme.buttons.primary_button_yellow_violet.normal.color};
  background-color: ${props => props.theme.buttons.primary_button_yellow_violet.normal.background_color};
  border-color: ${props => props.theme.buttons.primary_button_yellow_violet.normal.border_color};
  font: 22px/22px Roboto;
  transition: 0.25s ease-out;
  &:hover {
    color: ${props => props.theme.buttons.primary_button_yellow_violet.hovered.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_yellow_violet.hovered.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_yellow_violet.hovered.border_color} !important;
    box-shadow: 0px 3px 20px ${props => props.theme.buttons.primary_button_yellow_violet.hovered.shadow} !important;
    @media(min-width:1199px){
      margin-top:22px;
      margin-bottom:3px;
    }
  }
  &:focus, &.focus, &:active, &.active {
    color: ${props => props.theme.buttons.primary_button_yellow_violet.pressed.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_yellow_violet.pressed.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_yellow_violet.pressed.border_color} !important;
    box-shadow: none !important;
    @media(min-width:1199px){
      margin-top:22px;
      margin-bottom:3px;
    }
  }
  @media(max-width:767px){
    margin-top:20px;
  }
`
const ButtonOutline1 = styled(Button)`
  margin-top:25px;
  height: 60px;
  color: ${props => props.theme.buttons.secondary_big_button_yellow_violet.normal.color};
  background-color: ${props => props.theme.buttons.secondary_big_button_yellow_violet.normal.background_color};
  border-color: ${props => props.theme.buttons.secondary_big_button_yellow_violet.normal.border_color};
  font: 22px/22px Roboto;
  transition: 0.25s ease-out;
  &:hover {
    color: ${props => props.theme.buttons.secondary_big_button_yellow_violet.hovered.color} !important;
    background-color: ${props => props.theme.buttons.secondary_big_button_yellow_violet.hovered.background_color} !important;
    border-color: ${props => props.theme.buttons.secondary_big_button_yellow_violet.hovered.border_color} !important;
    box-shadow: 0px 3px 20px ${props => props.theme.buttons.secondary_big_button_yellow_violet.normal.shadow} !important;
    @media(min-width:1199px){
      margin-top:22px;
      margin-bottom:3px;
    }
  }
  &:focus, &.focus, &:active, &.active {
    color: ${props => props.theme.buttons.secondary_big_button_yellow_violet.pressed.color} !important;
    background-color: ${props => props.theme.buttons.secondary_big_button_yellow_violet.pressed.background_color} !important;
    border-color: ${props => props.theme.buttons.secondary_big_button_yellow_violet.pressed.border_color} !important;
    box-shadow: none !important;
    @media(min-width:1199px){
      margin-top:22px;
      margin-bottom:3px;
    }
  }
  @media(min-width:1200px){
    margin-left:-5px;
  }
  @media(max-width:1199px){
    width:100%;
  }
  @media(max-width:767px){
    margin-top:20px;
  }
`

export default function IndexSection1() {
    return (
    <FirstSectionContainer>
      <Row>
        <Col>
          <HomeTitle>
            <Typed
                strings={[
                "Grow with Indonesia’s top leading ladies.",
                "Reach your financially- independent future.",
                "Kick-start your career and business ideas."]}
                typeSpeed={40}
                backSpeed={20}
                loop >
            </Typed>
          </HomeTitle>
        </Col>
      </Row>
      <Row>
        <Col xl="8">
          <HomeSubtitle>
            Have you ever wondered how you can reach your peak potential, get advice and connections beyond your current network, be financially independent, or simply where to go after you graduate? Join WomenWorks and create personalised network and mentorship to grow both professionally and personally.
          </HomeSubtitle>
        </Col>
      </Row>
      <Row css={css`
        @media(min-width:768px){
          margin-bottom:147px;
          :after {
            background-image: url('/page-home-section1-bg1.svg');
            content: '';
            display: block;
            position: absolute;
            width: 516px;
            height: 495px;
            top: 875px;
            right:0;
            z-index:1;
          }
        }
        @media(min-width:992px) {
          :after {
            top:815px;
          }
        }
        @media(min-width:1200px) {
          :after {
            top:625px;
          }
        }
        @media(min-width:1600px) {
          :after {
            display:none;
          }
        }
      `}>
        <Col sm lg="6" xl="3">
          <Link to={`/sign-up-for-mentorship-coaching/`}>
            <ButtonFill1 variant="warning">Sign up for mentorship</ButtonFill1>
          </Link>
        </Col>
        <Col sm lg="6" xl="4">
         <Link to={`/be-a-mentor/`}>
            <ButtonOutline1 variant="outline-warning">Become a LadyBoss mentor</ButtonOutline1>
          </Link>
        </Col>  
        <Col xl="1">
        </Col>
        <Col lg="12" xl="4" css={css`
          @media(min-width:1600px) {
            :after {
              background-image: url('/page-home-section1-bg1.svg');
              content: '';
              display: block;
              position: absolute;
              width: 516px;
              height: 495px;
              z-index:1;
              top: 55px;
              left: -15px;
            }
          }
        `}>
        </Col>
      </Row>
    </FirstSectionContainer>
    )
}
import React from "react";
import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import styled from "@emotion/styled";
import {Global, css} from "@emotion/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

const SeventhSectionBackground = styled.div`
  background-color : ${props => props.theme.colors.gray_light_background};
  color : ${props => props.theme.colors.gray_dark};
  min-height: 1096px; 
  padding-bottom:130px;
  @media(max-width:767px){
    padding-bottom:65px;
  }
`
const SeventhSectionTitle = styled.div`
  font: 58px/62px Abril Fatface;
  padding-top:100px;
  text-align: ${props => props.textAlign};
  @media(max-width:767px){
    font: 2.5rem/3rem Abril Fatface;
    padding-top:50px;
  }
  @media(max-width:320px){
    font: 2rem/2.5rem Abril Fatface;
  }
`
const SeventhSectionSubtitle = styled.div`
  font: Bold 16px/22px Roboto;
  color: ${props => props.theme.colors.gray_dark};
`
const SeventhSectionSpanTitle = styled.span`
  font: Bold 16px/22px Roboto;
  color: ${props => props.theme.colors.gray_dark};
`
const SeventhSectionSpanContent = styled.span`
  padding-left:2px;
  font: 16px/22px Roboto;
  color: ${props => props.theme.colors.gray_dark};
`
const SeventhSectionColumn1 = styled(Col)`
  height:100%;
  .seventh-section-card {
    border-radius:0;
    border: 2px solid ${props => props.theme.colors.white_dark};
    @media(max-width:767px) {
      min-height:0;
    }
    @media(min-width:768px) {
      border-bottom: 1px solid #fff;
      min-height:726px;
    }
    @media(min-width:992px) {
      border-bottom: 1px solid #fff;
      min-height:760px;
    }
    @media(min-width:1200px) {
      min-height: 684px;
      border-bottom: 2px solid ${props => props.theme.colors.white_dark};
    }
  }
  .card-text {
    margin-bottom:0;
    .job-title {
        margin-top:5px;
        div {
          color: ${props => props.theme.colors.gray_dark3} !important;
          width:100% !important;
        }
    }
    .description {
        font: 16px/22px Roboto;
        margin-top:15px;
        ul:blank {
          display: none;
       }
    }
  }
  .name {
    color: ${props => props.theme.colors.primary_violet_dark2};
    font: Bold 28px/32px Roboto;
    margin-top:15px;
    margin-bottom:5px;
  }
  @media(min-width:992px){
    padding-right:0;
    .name {
        margin-top:36px;
    }
    .card-body {
        padding-top:0;
    }
    .description {
    }
  }
  @media(min-width:1200px){
    max-width:440px;
    padding:0;
    .card-body {
        padding:0;
        padding-left:50px;
    }
    img {
        height:300px;
        object-fit:none;
    }
    .name {
        max-width:362px;
    }
    .card-text {
        .job-title {
          max-width:386px;
        }
        .description {
            max-width:362px;
        }
    }
  }
`
const ButtonFill = styled(Button)`
  height: 47px;
  margin-top:10px;
  color: ${props => props.theme.buttons.primary_button_violet.normal.color};
  background-color: ${props => props.theme.buttons.primary_button_violet.normal.background_color};
  border-color: ${props => props.theme.buttons.primary_button_violet.normal.border_color};
  font: 16px/22px Roboto;
  transition: 0.25s ease-out;
  &:hover {
    color: ${props => props.theme.buttons.primary_button_violet.hovered.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_violet.hovered.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_violet.hovered.border_color} !important;
    box-shadow: 0px 3px 20px ${props => props.theme.buttons.primary_button_violet.hovered.shadow} !important;
    @media(min-width:1200px){
      margin-top:25px;
    }
  }
  &:focus, &.focus, &:active, &.active {
    color: ${props => props.theme.buttons.primary_button_violet.pressed.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_violet.pressed.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_violet.pressed.border_color} !important;
    box-shadow: none !important;
    @media(min-width:1200px){
      margin-top:25px;
    }
  }
  @media(max-width:767px){
    margin-top:20px;
  }
  @media(max-width:991px){
    width:100%;
  }
  @media(min-width:992px){
    margin-top:69px;
    font: 14px Roboto;
  }
  @media(min-width:1200px){
    margin-top:28px;
    font: 16px Roboto;
  }
`
const ButtonOutlineCol1 = styled(Button)`
  height: 47px;
  margin-top:10px;
  color: ${props => props.theme.buttons.secondary_small_button_violet.normal.color};
  background-color: ${props => props.theme.buttons.secondary_small_button_violet.normal.background_color};
  border-color: ${props => props.theme.buttons.secondary_small_button_violet.normal.border_color};
  font: 16px/22px Roboto;
  transition: 0.25s ease-out;
  &:hover {
    color: ${props => props.theme.buttons.secondary_small_button_violet.hovered.color} !important;
    background-color: ${props => props.theme.buttons.secondary_small_button_violet.hovered.background_color} !important;
    border-color: ${props => props.theme.buttons.secondary_small_button_violet.hovered.border_color} !important;
    box-shadow: 0px 3px 20px ${props => props.theme.buttons.secondary_small_button_violet.hovered.shadow} !important;
    @media(min-width:1200px){
      margin-top:25px;
    }
  }
  &:focus, &.focus, &:active, &.active {
    color: ${props => props.theme.buttons.secondary_small_button_violet.pressed.color} !important;
    background-color: ${props => props.theme.buttons.secondary_small_button_violet.pressed.background_color} !important;
    border-color: ${props => props.theme.buttons.secondary_small_button_violet.pressed.border_color} !important;
    box-shadow: none !important;
    @media(min-width:1200px){
      margin-top:25px;
    }
  }
  @media(max-width:767px){
    margin-top:20px;
  }
  @media(max-width:991px){
    width:100%;
  }
  @media(min-width:992px){
    margin-top:69px;
  }
  @media(min-width:1200px){
    width:173px;
    margin-top:28px;
  }
`
const SeventhSectionColumn2 = styled(Col)`
  height:100%;
  .seventh-section-card {
    border-radius:0;
    border: 2px solid ${props => props.theme.colors.white_dark};
    @media(max-width:767px) {
        border-bottom: 1px solid #fff; //remove border bottom
        min-height:0px;
    }
    @media(min-width:768px){
        border-right:1px solid #fff; //remove border right
        min-height:726px; 
    }
    @media(min-width:992px){
        border-left:1px solid #fff; //remove border left
        border-right:1px solid #fff; //remove border right
        min-height: 760px;
    }
    @media(min-width:1200px) {
      width:390px;
      min-height: 684px;
    }
  }
  .title {
      font: 28px/28px Roboto;
  }
  .row-video {
    margin-top:7px;
  }
  .video-title {
    margin-top:4px;
    margin-bottom:7px;
  }
  @media(min-width:768px){  
    padding-right:0;
    .card-body {
      padding-bottom:24px;
    }
  }
  @media(min-width:992px){
    padding:0;
  }
  @media(min-width:1200px){
    max-width:390px;
    .card-body {
        padding:0;
        padding-left:30px;
    }
    .title {
        margin-top:30px;
        margin-bottom:0;
    }
    .row-video {
        margin-left:0px;
        margin-top:12px;
    }
    .col-video {
        padding-left:0;
        padding-right:20px;
    }
    img {
        width:122px;
    }
  }
`
const Circle = styled.div`
    width: 43px;
    height: 43px;
    -webkit-border-radius: 21.5px;
    -moz-border-radius: 21.5px;
    border-radius: 21.5px;
    background: ${props => props.theme.colors.primary_violet};
    position:absolute;
    left: 39.5px;
    top: 14.5px;
    :hover{
        background: ${props => props.theme.colors.white};
        cursor:pointer;
    }
    @media(max-width:1199px){
        left: 62px;
    }
`
const FAPlay = styled(FontAwesomeIcon)`
  color: ${props => props.theme.colors.primary_violet_tint40};
  position:absolute;
  left:16px;
  top:12px;
  font-size:1.2rem;
  :hover{
    color: ${props => props.theme.colors.primary_violet};
}
`
const CardTextChecklist = styled.div`
  font: 16px/22px Roboto;
`
const CardTextCheckIcon = styled.span`
  padding-right:0;
  line-height:1.5;
`
const FACheck = styled(FontAwesomeIcon)`
  font-size:1rem;
  color: ${props => props.theme.colors.secondary_green10};
`
const CardTextCheckContainer = styled.div`
  margin-top:8px;
`
const CardTextCheckContent = styled.span`
  padding-left:7px;
  padding-right:5px;
  line-height:1.5;
  @media(max-width:1199px){
    max-width:395px;
  }
  @media(max-width:991px){
    max-width:275px;
  }
  @media(max-width:359px){
    max-width:235px;
  }

`
const SeventhSectionColumn3 = styled(Col)`
  height:100%;
  margin-bottom:0px;
 .seventh-section-card {
    border: 2px solid ${props => props.theme.colors.white_dark};
    border-radius:0;
    @media(max-width:767px) {
      min-height:0;
    }
    @media(min-width:768px) {
      min-height:726px;
      padding-left:15px;
      max-width:345px;
    }
    @media(min-width:992px){
        border-left:1px solid #fff; //remove border left
        min-height: 760px;
        background-color: ${props => props.theme.colors.accent_yellow70};
    }
    @media(min-width:1200px) {
      min-height: 684px;
    }
  }
  .title {
    font: Bold 16px/22px Roboto;
    color: ${props => props.theme.colors.gray_dark};
  }
  .book-title {
    font: Bold 16px/22px Roboto;
  }
  .description {
    font: 16px/22px Roboto;
  }
  .link {
    font: Bold 16px/22px Roboto;
    text-decoration: underline;
    color: ${props => props.theme.colors.primary_violet};
    :hover {
        cursor:pointer;
        color: ${props => props.theme.colors.primary_violet_hover};
    }
  }
  .link {
    margin-top:10px;
  }
  .book-author {
    font: 16px/22px Roboto;
  }
  @media(min-width:768px){
    padding:0;
    .card-body{
        padding-left:0;
    }
  }
  @media(min-width:992px){
    .link {
      margin-top:0;
    }
  }
  @media(min-width:1200px){
    max-width:310px;
    padding:0;
    .title {
      margin-top:30px;
      margin-bottom:10px;
    }
    .card-body{
      padding:0;
      padding-left:12px;
    }
    .link {
      margin-top:25px;
    }
    .book-group {
      margin-top:15px;
    }

  }
`
const SeventhSectionColumn3Row1Col = styled(Col)`
  .card-body {
    background-color: ${props => props.theme.colors.accent_yellow90};
  }
  @media(min-width:768px){
    .card-body {
        background-color: transparent;
    }
    background-color: ${props => props.theme.colors.accent_yellow90};
    max-width:341px;
    min-height:241px;
  }
  @media(min-width:992px){
    max-width:237px;
    min-height:278px;
  }
  @media(min-width:1200px){
    max-width:307px;
    min-height:222px;
  }
`
const SeventhSectionColumn3Row2Col = styled(Col)`
  .card-body {
    background-color: ${props => props.theme.colors.accent_yellow80};
    padding-right:10px;
  }
  @media(min-width:768px){
    padding-top:28px;
    padding-bottom:28px;
    .card-body {
        background-color: transparent;
    }
    background-color: ${props => props.theme.colors.accent_yellow80};
    max-width:341px;
    min-height:241px;
    height:241px;
  }
  @media(min-width:992px){
    padding-top:0;
    padding-bottom:0;
    max-width:237px;
    padding-right: 0;
    min-height:250px;
  }
  @media(min-width:1200px){
    max-width:307px;
    min-height:238px;
    height:238px;
  }
`
const SeventhSectionColumn3Row3Col = styled(Col)`
  .card-body{
    background-color: ${props => props.theme.colors.accent_yellow70};
  }
  @media(min-width:768px){
    .card-body {
        background-color: transparent;
    }
    background-color: ${props => props.theme.colors.accent_yellow70};
    max-width:341px;
    min-height:241px;
  }
  @media(min-width:992px){
    max-width:237px;
    min-height:228px;
  }
  @media(min-width:1200px){
    max-width:307px;
    min-height:220px;
  }
`

export default function IndexSection7(props) {
    return (
      <SeventhSectionBackground>
        <Container>
            <Row>
              <Col xs="12">
                <SeventhSectionTitle textAlign={props.TitleTextAlign}>
                  {props.Title}
                </SeventhSectionTitle>
              </Col>
            </Row>
            {props.Names.map((mentor, i) => 

            <Row css={css`margin-top:50px;@media(max-width:767px){margin-top:30px;}`}>
              <SeventhSectionColumn1 xs="12" md="12" lg="5">
                  <Card className="seventh-section-card">
                    <Card.Img alt={"WomenWorks Growth Circle Mentor -" + mentor.name} variant="top" src={"/growth-circle/"+ mentor.photo} />
                    <Card.Body>
                        <Card.Title className="name">{mentor.name}</Card.Title>
                        <Card.Text css={css`
                          @media(min-width:992px){
                            min-height:232px;
                          }
                          @media(min-width:1200px){
                            min-height:173px;
                          }
                          `}
                        >
                            {/* on production, somehow this become class for ButtonFill & ButtonOutlineCol1. Added row to make those button on the same level*/}
                            <div className="job-title row"> 
                                <SeventhSectionSubtitle css={css`
                                padding-left:15px;padding-right:15px;
                                width:100%;
                                @media(min-width:768px){
                                  width:50%;
                                }
                                button{width:100%;}`}>
                                  {mentor.title}
                                </SeventhSectionSubtitle>
                            </div>
                            <div className="description">
                                {mentor.description}
                            </div>
                        </Card.Text>
                        <Row>
                          <SeventhSectionSubtitle css={css`
                                padding-left:15px;padding-right:15px;
                                width:100%;
                                @media(min-width:768px){
                                  width:50%;
                                }
                                button{width:100%;}`}>
                            <OutboundLink target="_blank" rel="noreferrer" href="https://bit.ly/womenworksmenteesignupnew">      
                              <ButtonFill css={css`@media(min-width:1200px){width:158px;}`}>
                                Request mentorship
                              </ButtonFill>
                            </OutboundLink>
                          </SeventhSectionSubtitle>
                          <Col xs="12" md="6" css={css`@media(min-width:992px){padding-left:0;}`}>
                            <Link to={`/waitlist/`}>      
                              <ButtonOutlineCol1>
                                Connect with me
                              </ButtonOutlineCol1>
                            </Link>
                          </Col> 
                        </Row>
                    </Card.Body>
                  </Card>
              </SeventhSectionColumn1>

              <SeventhSectionColumn2 xs="12" md="6" lg="4">
                <Card className="seventh-section-card">
                    <Card.Body>
                        <Card.Title className="title">
                          Growth Circle
                        </Card.Title>
                        <div css={css`
                          @media(min-width:768px) {
                            min-height:524px;
                          }
                          @media(min-width:992px) {
                            min-height:548px;
                          }
                          @media(min-width:1200px) {
                            min-height:475.56px;
                          }
                        `}>
                          <SeventhSectionSubtitle css={css`
                            margin-top:20px;
                          `}>
                            {mentor.section2Subtitle}
                          </SeventhSectionSubtitle>
                          <CardTextChecklist>
                            <CardTextCheckContainer>
                              <CardTextCheckIcon>
                                <FACheck icon={faCheck} />
                              </CardTextCheckIcon>
                              <CardTextCheckContent>
                                {mentor.section2Check1}
                              </CardTextCheckContent>
                            </CardTextCheckContainer>
                            <CardTextCheckContainer>
                              <CardTextCheckIcon>
                                <FACheck icon={faCheck} />
                              </CardTextCheckIcon>
                              <CardTextCheckContent>
                                {mentor.section2Check2}
                              </CardTextCheckContent>
                            </CardTextCheckContainer>
                            <CardTextCheckContainer>
                              <CardTextCheckIcon>
                                <FACheck icon={faCheck} />
                              </CardTextCheckIcon>
                              <CardTextCheckContent>
                                {mentor.section2Check3}
                              </CardTextCheckContent>
                            </CardTextCheckContainer>
                            {mentor.section2Check4 && <CardTextCheckContainer>
                              <CardTextCheckIcon>
                                <FACheck icon={faCheck} />
                              </CardTextCheckIcon>
                              <CardTextCheckContent>
                                {mentor.section2Check4}
                              </CardTextCheckContent>
                            </CardTextCheckContainer>}
                          </CardTextChecklist>
                          <div css={css`
                            margin-top:15px;
                            `}
                          >
                            <SeventhSectionSpanTitle>
                              Group mentoring size:
                            </SeventhSectionSpanTitle>
                            <SeventhSectionSpanContent>
                              {mentor.section2GroupSize}
                            </SeventhSectionSpanContent>
                            <br></br>
                            <SeventhSectionSpanTitle>
                              Duration:
                            </SeventhSectionSpanTitle>
                            <SeventhSectionSpanContent>
                              {mentor.section2Duration}
                            </SeventhSectionSpanContent>
                            <br></br>
                            <SeventhSectionSpanTitle>
                              Price:
                            </SeventhSectionSpanTitle>
                            <SeventhSectionSpanContent>
                              {mentor.section2Price}
                            </SeventhSectionSpanContent>
                            <br></br>
                            <SeventhSectionSpanTitle>
                              {mentor.secion2FriendText}
                            </SeventhSectionSpanTitle>                           
                          </div> 
                          <Row className="row-video">
                            <Col xs="auto" className="col-video">
                              <Circle>
                                <FAPlay icon={faPlay} className="fa-play" />
                              </Circle>
                              <img alt={"WomenWorks Video Thumbnail - " + mentor.name} src={"/growth-circle/video-"+ mentor.photo} ></img>
                              <SeventhSectionSubtitle className="video-title">{mentor.section2VideoTitle}</SeventhSectionSubtitle>
                            </Col>
                          </Row>
                        </div>
                        <Row>
                            <Col xs="12" xl="auto">
                              <OutboundLink target="_blank" rel="noreferrer" href={mentor.section2ButtonLink}>
                                <ButtonFill css={css`
                                  @media(max-width:320px){
                                    font-size:14px;
                                  }
                                  @media(min-width:768px){
                                    margin-top:20px;}
                                  @media(min-width:1200px){
                                    width:279px;
                                    &:hover{margin-top:17px !important;margin-bottom:58px !important;}
                                    &:focus, &.focus, &:active, &.active {margin-top:20px !important; margin-bottom:55px !important;}
                                  }
                                  `}>
                                  Apply now for Growth Circle
                                </ButtonFill>
                              </OutboundLink>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
              </SeventhSectionColumn2>

              <Global
                //To style ul
                styles={css`
                  ul {
                    padding-inline-start: 17px;
                    margin-bottom:7px;
                  }
                `}
              />

              <SeventhSectionColumn3  xs="12" md="6" lg="3">
                <Card className="seventh-section-card">
                    <Row>
                        <SeventhSectionColumn3Row1Col>
                            <Card.Body>
                                <Card.Title className="title">Communities and teams I could introduce you to:</Card.Title>
                                <Card.Text>
                                    <div className="description">
                                      {mentor.section3Communities1 && <ul><li>{mentor.section3Communities1}</li></ul>}
                                      {mentor.section3Communities2 && <ul><li>{mentor.section3Communities2}</li></ul>}
                                      {mentor.section3Communities3 && <ul><li>{mentor.section3Communities3}</li></ul>}
                                      {mentor.section3Communities4 && <ul><li>{mentor.section3Communities4}</li></ul>}
                                      {!mentor.section3Communities3 && <OutboundLink target="_blank" rel="noreferrer" href="https://bit.ly/womenworksmenteesignupnew"><div className="link">Request mentorship</div></OutboundLink>}
                                    </div>
                                </Card.Text>
                            </Card.Body>
                        </SeventhSectionColumn3Row1Col>
                    </Row>
                    <Row>
                        <SeventhSectionColumn3Row2Col>
                            <Card.Body>
                                <Card.Title className="title">1-on-1 mentorship opportunity:</Card.Title>
                                <Card.Text>
                                    <div className="description">
                                      {mentor.section31o1Text}
                                    </div>
                                    <OutboundLink target="_blank" rel="noreferrer" href="https://bit.ly/womenworksmenteesignupnew">      
                                      <div className="link">
                                        Request mentorship
                                      </div>
                                    </OutboundLink>
                                </Card.Text>
                            </Card.Body>
                        </SeventhSectionColumn3Row2Col>
                    </Row>
                    <Row>
                        <SeventhSectionColumn3Row3Col>
                            <Card.Body>
                                <Card.Title className="title">Books I would recommend</Card.Title>
                                <Card.Text>
                                    <div className="book-group">
                                        <div className="book-title">
                                          {mentor.section3Book1}
                                        </div>
                                        <div className="book-author">
                                          - {mentor.section3Book1Author}
                                        </div>
                                    </div>
                                    {mentor.section3Book2 &&
                                    <div className="book-group">
                                        <div className="book-title">
                                          {mentor.section3Book2}
                                        </div>
                                        <div className="book-author">
                                          - {mentor.section3Book2Author}
                                        </div>
                                    </div>
                                    }
                                </Card.Text>
                            </Card.Body>
                        </SeventhSectionColumn3Row3Col>
                    </Row>
                </Card>
              </SeventhSectionColumn3>
            </Row>
        )}
        </Container>
      </SeventhSectionBackground>
    )
}
import React from "react";
import { useForm } from "react-hook-form";
import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { navigate } from "@reach/router";
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import styled from "@emotion/styled";
import { Global, css } from "@emotion/core";
import { ThemeProvider } from 'emotion-theming'
import themeWW from "../styles/theme-ww";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagramSquare } from '@fortawesome/free-brands-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'

// First Section
const FirstSectionBackground = styled.div`
  background-color: ${props => props.backgroundColor};
`

// Header
const MenuBrand = styled(Navbar.Brand)`
  color: ${props => props.color} !important;
  font: 32px/58px Abril Fatface;
`
const MenuItem = styled(Navbar.Text)`
  margin-right: 21px;
  font: 16px/22px Roboto;
  color: ${props => props.color} !important;
  &:hover {
      color:${props => props.theme.colors.accent_yellow} !important;
  }
`
const ButtonHeader = styled(Button)`
  height: 41px;
  font: 16px/22px Roboto;
  color: ${props => props.color};
  background-color: ${props => props.backgroundColor};
  border-color: ${props => props.borderColor};
  transition: 0.25s ease-out;
  &:hover, &:focus, &.focus, &:active, &.active  {
    color: ${props => props.colorHover} !important;
    background-color: ${props => props.backgroundColorHover} !important;
    border-color: ${props => props.borderColorHover} !important;
    box-shadow: 0px 3px 20px ${props => props.theme.colors.gray_shadow} !important;
    margin-top:-3px;
    margin-bottom:3px;
  }
  &:focus, &.focus, &:active, &.active {
    color: ${props => props.colorPressed} !important;
    background-color: ${props => props.backgroundColorPressed} !important;
    border-color: ${props => props.theme.borderColorPressed} !important;
    box-shadow: none !important;
    margin-top:-3px;
    margin-bottom:3px;
  }
  @media(max-width:767px){
    width:100%;
  }
`

// To Push Footer to the bottom of the page
const Wrap = styled.div`
  margin: 0 auto -274px;
  min-height: 100%;
  height: auto !important;
  height: 100%;
`

const Push = styled.div`
  height: 274px;
`

const Footer = styled.div`
  min-height: 274px;
  background-color: ${props => props.theme.colors.primary_violet_dark};
  color: ${props => props.theme.colors.white_dark};
`

// footer
const FooterBrand = styled.div`
  margin-top: 40px;
  font: 32px/58px Abril Fatface;
`
const TextFooterCol1 = styled.div`
  font: 16px/28px Roboto;
  a {
    color: ${props => props.theme.colors.white_dark};
    text-decoration: underline;
    &:hover {
      color: ${props => props.theme.colors.accent_yellow} !important;
    }
  }
`
const TextFooterCol1Margin = styled(TextFooterCol1)`
  margin-top:45px;
  @media(max-width:991px) {
    margin-top:0;
  }
`
const TextFooterCol2 = styled.div`
  font: 16px/22px Roboto;
  margin-top:16px;
  a {
    color: ${props => props.theme.colors.white_dark};
    text-decoration: underline;
    &:hover {
      color: ${props => props.theme.colors.accent_yellow} !important;
    }
  }
`
const TextFooterCol2Margin = styled(TextFooterCol2)`
  margin-top:50px;
  @media(max-width:767px) {
    margin-top:25px;
  }
`
const TextFooterCol3Margin = styled.div`
  margin-top:50px;
  font: 16px/20px Roboto;
  @media(max-width:767px) {
    margin-top:25px;
  }
`
const FASocmedButton = styled(FontAwesomeIcon)`
  font-size:3rem;
  color:${props => props.theme.colors.accent_yellow};
  transition: 0.25s ease-out;
  &:hover, &:focus, &.focus, &:active, &.active {
    color:${props => props.theme.colors.accent_yellow_hover};
    margin-top:-3px;
    cursor:pointer;
  }
`

const SocmedRow = styled(Row)`
  margin-top:10px;
  margin-bottom:40px;
`
const FormControlSubmit = styled(Form.Control)`
  width:250px;
  margin-top:10px;
  @media(max-width:991px) {
    width:100%;
  }
`
const ButtonSubmit = styled(Button)`
  margin-top:10px;
  color: ${props => props.theme.buttons.primary_button_yellow_violet.normal.color};
  background-color: ${props => props.theme.buttons.primary_button_yellow_violet.normal.background_color};
  border-color: ${props => props.theme.buttons.primary_button_yellow_violet.normal.border_color};
  transition: 0.25s ease-out;
  &:hover {
    margin-top:7px;
    color: ${props => props.theme.buttons.primary_button_yellow_violet.hovered.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_yellow_violet.hovered.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_yellow_violet.hovered.border_color} !important;
    box-shadow: 0px 3px 20px ${props => props.theme.buttons.primary_button_yellow_violet.hovered.shadow} !important;
  }
  &:focus, &.focus, &:active, &.active {
    color: ${props => props.theme.buttons.primary_button_yellow_violet.pressed.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_yellow_violet.pressed.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_yellow_violet.pressed.border_color} !important;
    box-shadow: none !important;
  }
`

export default function Layout(props) {

  const { handleSubmit, register} = useForm();
  const onSubmit = values => {

    async function subscribe() {
      const response = await fetch("https://ljpo3mlpja.execute-api.ap-southeast-1.amazonaws.com/Production/subscribe", {
        method: "POST", 
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "email": values.email,
          "name": values.name
        })
      });
      if (response.status) {
        navigate("/thank-you");
      } else {
        console.log("Error - response.status:",response.status);
      }
    };
    subscribe();
    
  }

  return (
    <ThemeProvider theme={themeWW}>
      <Global
        //To Push Footer to the bottom of the page
        styles={css`
        html,body, #___gatsby, #gatsby-focus-wrapper {
            height:100%;
            font-family: "Roboto"
        }
        a.active span {
          @media(min-width:1024px){
            border-bottom: 3px solid;
            padding-bottom:3px
          }
        }
        `}
      />
      <Wrap>
        <FirstSectionBackground className="first-section-background" backgroundColor={props.firstSectionBGColor}>
          <Navbar expand="lg">
            <Container>
              <Link to={`/`} title={`Empowering the world with #bad-ass, #connected, and #financially-independent Indonesian women`}>
                <MenuBrand color={props.LogoColor}>WomenWorks</MenuBrand>
              </Link>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                  <Link to={`/growth-circle/`} activeClassName="active" title={`Empower each other and reach your fullest potential with like-minded mentees and mentors as your support system.`}>
                      <MenuItem color={props.HeaderMenuColor}>New: Growth Circle</MenuItem>
                  </Link>
                  <Link to={`/the-ladybosses/`} activeClassName="active" title={`Get mentored and connected to Indonesia’s inspiring professionals through WomenWorks`}>
                      <MenuItem color={props.HeaderMenuColor}>The LadyBosses</MenuItem>
                  </Link>
                  <Link to={`/who-we-are/`} activeClassName="active" title={`WomenWorks is the place for women to connect, empower, and reach their fullest potential. We are bad-ass. Connected. Financially independent.`}>
                      <MenuItem color={props.HeaderMenuColor}>Who We Are</MenuItem>
                  </Link>
                  <Link to={`/be-a-mentor/`} activeClassName="active" title={`Join the community and empower others and help close the gender gap that is still prevalent in Indonesia’s workforce today`}>
                      <MenuItem color={props.HeaderMenuColor}>Be a Mentor</MenuItem>
                  </Link>
                  <Link to={`/sign-up-for-mentorship-coaching/`} activeClassName="active" title={`Sign up for WomenWorks personalised 1-on-1 mentorship / coaching and access to mentors’ professional network to help through every stage of your professional life.`}>
                      <ButtonHeader variant="outline-light" 
                        color={props.HeaderButtonColor} 
                        backgroundColor={props.HeaderButtonBackground} 
                        borderColor={props.HeaderButtonBorder} 
                        colorHover={props.HeaderButtonColorHover}
                        backgroundColorHover={props.HeaderButtonBackgroundHover}
                        borderColorHover={props.HeaderButtonBorderHover}
                        colorPressed={props.HeaderButtonColorPressed}
                        backgroundColorPressed={props.HeaderButtonBackgroundPressed}
                        borderColorPressed={props.HeaderButtonBorderPressed}
                      >
                          Sign up for mentorship
                      </ButtonHeader>
                  </Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
          {props.children}
        </FirstSectionBackground>
        <Push></Push>
        </Wrap>
        <Footer>
          <Container>
            <Row>
              <Col xs={12} md={4}>
                <FooterBrand>WomenWorks</FooterBrand>
                <TextFooterCol1>©2020 All Rights Reserved.</TextFooterCol1>
                <TextFooterCol1Margin>Contact us at {<OutboundLink href="mailto:yours@womenworks.io">yours@womenworks.io</OutboundLink>}</TextFooterCol1Margin>
                <TextFooterCol1><Link to={'/terms-of-use'}>Terms of Use & Privacy Policy</Link></TextFooterCol1>
              </Col>
              <Col css={css`@media(max-width:1199px){display:none}`} xs={3} >
                <TextFooterCol2Margin><Link to={'/who-we-are/'}>Who We Are</Link></TextFooterCol2Margin>
                <TextFooterCol2><Link to={'/growth-circle/'}>New: Growth Circle</Link></TextFooterCol2>
                <TextFooterCol2><Link to="/be-a-mentor/">Be a Mentor</Link></TextFooterCol2>
                <TextFooterCol2><Link to={'/sign-up-for-mentorship-coaching/'}>Sign Up for Mentorship</Link></TextFooterCol2>
              </Col>
              <Col xs={12} md={4} xl={3}>
                <TextFooterCol2Margin>Get informed of our latest updates:</TextFooterCol2Margin>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <FormControlSubmit name="name" type="text" placeholder="Enter your name" 
                    ref={register({
                    })} 
                  />
                  <FormControlSubmit name="email" type="email" placeholder="Enter your email" 
                    ref={register({
                      required: "Required"
                    })} 
                  />
                  <ButtonSubmit variant="warning" type="submit">Submit</ButtonSubmit>
                </Form>
              </Col>
              <Col xs={12} md={4} xl={2}>
                <TextFooterCol3Margin>Connect with us:</TextFooterCol3Margin>
                <SocmedRow>
                  <Col xs={2} md={3} xl={4}>
                    <OutboundLink href="https://www.instagram.com/womenworksid/" target="_blank" rel="noreferrer">
                      <FASocmedButton icon={faInstagramSquare} />
                    </OutboundLink>
                  </Col>
                  <Col xs={2} md={3} xl={4}>
                    <OutboundLink href="https://www.linkedin.com/company/womenworksid/" target="_blank" rel="noreferrer">
                      <FASocmedButton icon={faLinkedin} />
                    </OutboundLink>
                  </Col>
                </SocmedRow>
              </Col>
            </Row>
          </Container>
        </Footer>
    </ThemeProvider>
      
  )
}

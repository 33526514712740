import React from "react";
import { useForm } from "react-hook-form";
import { navigate } from "@reach/router";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from "@emotion/styled";
import {css} from "@emotion/core";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagramSquare } from '@fortawesome/free-brands-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { OutboundLink } from "gatsby-plugin-google-analytics";

const FifthSectionBackground = styled.div`
  min-height: 735px;
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.gray_dark};
  @media(max-width:991px){
    min-height: 632px;
  }
`
const Tagline = styled.div`
  color: ${props => props.theme.colors.gray_dark};
  font: 20px/28px Roboto;
  margin-bottom:25px;
  @media(max-width:767px){
    margin-top: 20px;
  }
`
const BgCol5 = styled(Col)`
  :before {
    background-image: url('/page-waitlist-section2-bg2.png');
    content: '';
    display: block;
    position: absolute;
    width: 544px;
    height: 400px;
    top: -59px;
    right: 300px;
    z-index: 1;
  }
  :after {
    background-image: url('/page-waitlist-section2-bg1.png');
    content: '';
    display: block;
    position: absolute;
    width: 439px;
    height: 418px;
    top: -300px;
    left: -400px;
    z-index: 2;
  }
  @media(max-width:1199px){
    :before {
      right: 260px;
    }
  }
  @media(max-width:991px){
    :after {
      display:none;
    }
    :before {
      right: 15px;
      width: 400px;
    }
  }
  @media(max-width:767px){
    :before {
      display:none;
    }
    :after {
      display:block;
      height: 300px;
      top: -50px;
      left: 20%;
      left: -80px;
      background-position:center;
    }
  }
  @media(max-width:374px){
    :after {
      width: 280px;
      left:10%;
    }
  }
  @media(max-width:359px){
    :after {
      width: 240px;
    }
  }
`

const FormControlSubmit = styled(Form.Control)`
  width:250px;
  margin-bottom:15px;
  @media(max-width:991px) {
    width:100%;
  }
`

const ButtonSubmit = styled(Button)`
  margin-top:10px;
  width:101px;
  height:47px;
  color: ${props => props.theme.buttons.primary_button_violet.normal.color} ;
  background-color: ${props => props.theme.buttons.primary_button_violet.normal.background_color};
  border-color: ${props => props.theme.buttons.primary_button_violet.normal.border_color} ;
  transition: 0.25s ease-out;
  &:hover {
    margin-top:7px;
    margin-bottom:3px;
    color: ${props => props.theme.buttons.primary_button_violet.hovered.color}!important;
    background-color: ${props => props.theme.buttons.primary_button_violet.hovered.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_violet.hovered.border_color}  !important;
    box-shadow: 0px 3px 20px ${props => props.theme.buttons.primary_button_yellow_violet.hovered.shadow} !important;
  }
  &:focus, &.focus, &:active, &.active {
    color: ${props => props.theme.buttons.primary_button_violet.pressed.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_violet.pressed.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_violet.pressed.border_color}  !important;
    box-shadow: none !important;
  }
  @media(max-width:767px){
    width:100%;
    margin-bottom:20px;
  }
`

const SocmedRow = styled.div`
  display:flex;
  flex-wrap:wrap;
  margin-bottom:72px;
  @media(max-width:767px) {
    margin-bottom:50px;
  }
`

const FASocmedButton = styled(FontAwesomeIcon)`
  font-size:3rem;
  margin-right:15px;
  color:${props => props.theme.colors.primary_violet};
  transition: 0.25s ease-out;
  &:hover, &:focus, &.focus, &:active, &.active {
    color:${props => props.theme.colors.primary_violet_hover};
    margin-top:-3px;
    cursor:pointer;
  }
`

const FollowUsWrapper = styled.div`
    display:flex;
    flex-wrap:wrap;
    font: 20px/28px Roboto;
    margin-bottom:25px;
    @media(max-width:767px) {
      margin-bottom:10px;
    }
`

export default function WaitlistSection2() {
  const { handleSubmit, register} = useForm();
  const onSubmit = values => {

    async function subscribe() {
      const response = await fetch("https://ljpo3mlpja.execute-api.ap-southeast-1.amazonaws.com/Production/subscribe", {
        method: "POST", 
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "email": values.email,
          "name": values.name
        })
      });
      if (response.status) {
        navigate("/thank-you");
      } else {
        console.log("Error - response.status:",response.status);
      }
    };
    subscribe();
    
  }

  return (
  <FifthSectionBackground>
    <Container>
      <Row css={css`padding-top:223px;@media(max-width:991px){padding-top:120px;}`}>
        <Col xs="12" md="4" lg="6" css={css`z-index:3;`}></Col>
        <BgCol5 md="1"></BgCol5>
        <Col md="6" lg="5" css={css`margin-top:-35px;@media(max-width:767px){margin-top:235px;}`}>
          <Tagline css={css`height:auto;@media(max-width:767px){margin-top:80px;}`}>
              While waiting, enter your email to subscribe to our Monthly Newsletter featuring curated, high-end advice from our curated cliques and community!
          </Tagline>
          <Row css={css`margin-bottom:50px;@media(max-width:767px){margin-bottom:0;}`}>
            <Col xs={12} md={12} xl={3}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                <FormControlSubmit name="name" type="text" placeholder="Enter your name" 
                    ref={register({
                    })} 
                  />
                  <FormControlSubmit name="email" type="email" placeholder="Enter your email" 
                    ref={register({
                      required: "Required"
                    })} 
                  />
                <ButtonSubmit variant="warning" type="submit">Submit</ButtonSubmit>
                </Form>
            </Col>
          </Row>
          <FollowUsWrapper>
            Follow us on social:
          </FollowUsWrapper>
          <SocmedRow>
                <OutboundLink href="https://www.instagram.com/womenworksid/" target="_blank" rel="noreferrer">
                  <FASocmedButton icon={faInstagramSquare} />
                </OutboundLink>
                <OutboundLink href="https://www.linkedin.com/company/womenworksid/" target="_blank" rel="noreferrer">
                  <FASocmedButton icon={faLinkedin} />
                </OutboundLink>
            </SocmedRow>
        </Col>
    </Row>
    </Container>
  </FifthSectionBackground>
  )
}
import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Layout2 from "../components/layout2";
import Section1 from "../sections/thank-you-section1";
import SEO from "../components/seo"

export default function ThankYou() {
  return (
      <Layout2 
        LogoColor={props => props.theme.colors.white} 
        HeaderMenuColor={props => props.theme.colors.white} 
        firstSectionBGColor={props => props.theme.colors.primary_violet}

        //button
        HeaderButtonColor={props => props.theme.buttons.secondary_small_button_white_violet.normal.color} 
        HeaderButtonBorder={props => props.theme.buttons.secondary_small_button_white_violet.normal.border_color} 
        HeaderButtonBackground={props => props.theme.buttons.secondary_small_button_white_violet.normal.background_color}
        
        HeaderButtonColorHover={props => props.theme.buttons.secondary_small_button_white_violet.hovered.color}
        HeaderButtonBorderHover={props => props.theme.buttons.secondary_small_button_white_violet.hovered.border_color} 
        HeaderButtonBackgroundHover={props => props.theme.buttons.secondary_small_button_white_violet.hovered.background_color}

        HeaderButtonColorPressed={props => props.theme.buttons.secondary_small_button_white_violet.pressed.color}
        HeaderButtonBorderPressed={props => props.theme.buttons.secondary_small_button_white_violet.pressed.border_color} 
        HeaderButtonBackgroundPressed={props => props.theme.buttons.secondary_small_button_white_violet.pressed.background_color}
      >
          <SEO title={"Thank you Page"} description={"Thank you for subscribing to our news!"} /> 
          <Section1 
            BackgroundColor={props => props.theme.colors.primary_violet}
            DescriptionText="Follow Us On:"
          >
          </Section1>
      </Layout2>
  )
}

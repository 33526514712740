import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import styled from "@emotion/styled";
import {css} from "@emotion/core";
import Container from "react-bootstrap/Container";
import { OutboundLink } from "gatsby-plugin-google-analytics";

const WhoWeAreSection1Background = styled.div`
  background-color: ${props => props.theme.colors.secondary_green};
  color: ${props => props.theme.colors.white};
  font: 20px/28px Roboto;
  min-height: 669px;
`

const WhoWeAreSection1Title = styled.div`
  font: 58px/62px Abril Fatface;
  margin-bottom:35px;
  min-height: 132px;
  color: ${props => props.theme.colors.accent_yellow};
  @media(max-width:1199px){
    font: 56px/60px Abril Fatface;
  }
  @media(max-width:767px){
    font: 2.5rem/3rem Abril Fatface;
    margin-bottom:17.5px;
  }
`
const SectionItem = styled.div`
  margin-top:94px;
  margin-bottom:100px;
  display: inline-block;
  @media(max-width:991px){
    margin-top:61px;
  }
  @media(max-width:767px){
    margin-top:0;
    margin-bottom:50px;
  }
`

const ButtonFill6 = styled(Button)`
  height: 60px;
  color: ${props => props.theme.buttons.primary_button_yellow_green.normal.color} ;
  background-color: ${props => props.theme.buttons.primary_button_yellow_green.normal.background_color};
  border-color: ${props => props.theme.buttons.primary_button_yellow_green.normal.border_color} ;
  font: 22px/22px Roboto;
  transition: 0.25s ease-out;
  &:hover {
    color: ${props => props.theme.buttons.primary_button_yellow_green.hovered.color}!important;
    background-color: ${props => props.theme.buttons.primary_button_yellow_green.hovered.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_yellow_green.hovered.border_color} !important;
    box-shadow: 0px 3px 20px ${props => props.theme.colors.gray_shadow} !important;
    @media(min-width:1199px){
      margin-top:-3px;
    }
  }
  &:focus, &.focus, &:active, &.active {
    color: ${props => props.theme.buttons.primary_button_yellow_green.pressed.color}!important;
    background-color: ${props => props.theme.buttons.primary_button_yellow_green.pressed.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_yellow_green.pressed.border_color}  !important;
    box-shadow: none !important;
    @media(min-width:1199px){
      margin-top:-3px;
    }
  }
  @media(max-width:767px){
    width:100%;
    margin-bottom:20px;
  }
`

export default function WaitlistSection1(props) {
  return (
  <WhoWeAreSection1Background backgroundColor={props.Background} color={props.TextColor} >
      <img alt="WomenWorks - two young woman looking at laptop" src="/page-wailist-section1-img1.png" 
        css={css`
          @media(max-width:991px) {
            display:none;
          }
          @media(min-width:992px) {
            position: absolute;
            right: 0;
            top:145px;
            height: 500px;
            max-width: 36vw;
            object-fit: cover;
          }
          @media(min-width:1600px) {
            display:none;
          }
      `}></img>
      <Container>
          <SectionItem>
            <Row>
              <Col xs="12" lg="8" >
                <WhoWeAreSection1Title>
                  Join the waitlist for a free trial of The Clique
                </WhoWeAreSection1Title>
              </Col>
              <Col xs="12" lg="4">
                <img alt="WomenWorks - female professionals discussing presentation" src="/page-waitlist-section1-img1.png" 
                  css={css`
                    @media(max-width:767px) {
                      width:100%;
                      margin-bottom:15px;
                    }
                    @media(min-width:768px) {
                      object-fit: cover;
                      height: 250px;
                      width:100%;
                      margin-bottom:30px;
                    }
                    @media(min-width:992px) {
                      display:none;
                    }
                    @media(min-width:1600px) {
                      display:block;
                      position:absolute;
                      width: 516px;
                      height: 500px;
                    }
                `}></img>
              </Col>
              <Col xs="12" lg="7" css={css`@media(min-width: 991px){max-height:500px;}`}>
                <p css={css`margin-bottom:35px;min-height:54px`}>
                    Thank you for your interest in WomenWorks’ The Clique - a private and membership-based social platform aimed to empower women in their journey towards professional and personal growth. 
                    <br></br><br></br>As we’re an invite-only platform, we require you to fill in this application as part of our admission process. From there, applications are assessed and decisions can range anywhere from a day to a few months.
                    <br></br><br></br>If admitted, every new member is asked to pay monthly subscription fees. We kindly thank you for your patience and will get back to you as soon as possible.
                </p>
                <OutboundLink target="_blank" rel="noreferrer" href="https://bit.ly/womenworkstheclique">
                  <ButtonFill6 variant="outline-light" css={css`margin-bottom:242px;@media(max-width:991px){width:100%;margin-bottom:0;}`}>
                      Apply to join The Clique
                  </ButtonFill6>
                </OutboundLink>
              </Col>
            </Row>
          </SectionItem>
      </Container>
  </WhoWeAreSection1Background>
  )
}


import React from "react";
import { renderToString } from 'react-dom/server';
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { graphql } from "gatsby"
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import {Global, css} from "@emotion/core";
import SEO from "../components/seo";
import ModalLayout from "../components/ModalLayout"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

export default class TheLadybosses extends React.Component {
  render() {

    const name = this.props.data.allGoogleSheetContentBankRow.edges[0].node.name;
    const title = this.props.data.allGoogleSheetContentBankRow.edges[0].node.title;
    const categories = this.props.data.allGoogleSheetContentBankRow.edges[0].node.categories;
    const aboutMe = this.props.data.allGoogleSheetContentBankRow.edges[0].node.aboutMe;
    const industries = this.props.data.allGoogleSheetContentBankRow.edges[0].node.industries;
    const expertise = this.props.data.allGoogleSheetContentBankRow.edges[0].node.expertise;
    const communities = this.props.data.allGoogleSheetContentBankRow.edges[0].node.communities;
    const webinarTopics = this.props.data.allGoogleSheetContentBankRow.edges[0].node.webinarTopics;
    

    return (
      <ModalLayout>
        <Global
            //To style ul
            styles={css`
              ul {
                padding-inline-start: 17px;
                margin-bottom:7px;
              }
              .ReactModal__Content {
                padding-top:0 !important;
                text-align:right;
                @media(min-width:992px) {
                  padding-left:1% !important;
                }
                @media(min-width:1199px) {
                  padding-left:10% !important;
                  text-align:left;
                }

                a[href="/the-ladybosses/"] {
                    width:100%;
                    color: #8D589B;
                    line-height:40px; 
                    padding:20px 30px 20px 30px;
                    background-color: #F6DE9D;
                    &:hover {
                      background-color:#DDC88D;
                      text-decoration:none;
                      color: #7F4F8C;
                    }
                }
              }
              .seventh-section-subtitle {
                font: Bold 16px/22px Roboto;
                color: #4E4F58;
              }
              .button-fill {
                height: 47px;
                margin-top:10px;
                color: #FFFFFF;
                background-color: #8D589B;
                border-color: #8D589B;
                font: 16px/22px Roboto;
                transition: 0.25s ease-out;
                &:hover {
                  color: #FFFFFF !important;
                  background-color: #7F4F8C !important;
                  border-color: #7F4F8C !important;
                  box-shadow: 0px 3px 20px #00000033 !important;
                  @media(min-width:1200px){
                    margin-top:17px;
                  }
                }
                &:focus, &.focus, &:active, &.active {
                  color: #FFFFFF !important;
                  background-color: #7F4F8C !important;
                  border-color: #7F4F8C !important;
                  box-shadow: none !important;
                  @media(min-width:1200px){
                    margin-top:17px;
                  }
                }
                @media(max-width:767px){
                  margin-top:20px;
                }
                @media(max-width:991px){
                  width:100%;
                }
                @media(min-width:992px){
                  margin-top:69px;
                  font: 14px Roboto;
                }
                @media(min-width:1200px){
                  margin-top:20px;
                  font: 16px Roboto;
                }
              }
              .button-outline {
                height: 47px;
                margin-top:10px;
                color: #8D589B;
                background-color: #FFFFFF;
                border-color: #8D589B;
                font: 16px/22px Roboto;
                transition: 0.25s ease-out;
                &:hover {
                  color: #FFFFFF !important;
                  background-color: #7F4F8C !important;
                  border-color: #7F4F8C !important;
                  box-shadow: 0px 3px 20px #00000033 !important;
                  @media(min-width:1200px){
                    margin-top:17px;
                  }
                }
                &:focus, &.focus, &:active, &.active {
                  color: #FFFFFF !important;
                  background-color: #7F4F8C !important;
                  border-color: #7F4F8C !important;
                  box-shadow: none !important;
                  @media(min-width:1200px){
                    margin-top:17px;
                  }
                }
                @media(max-width:767px){
                  margin-top:20px;
                }
                @media(max-width:991px){
                  width:100%;
                }
                @media(min-width:992px){
                  margin-top:69px;
                }
                @media(min-width:1200px){
                  width:173px;
                  margin-top:20px;
                }
              }
              .card-text-check-icon {
                padding-right:0;
                line-height:1.5;
              }
              .card-text-check-content {
                text-transform: capitalize;
                padding-left:7px;
                padding-right:5px;
                line-height:1.5;
                @media(max-width:1199px){
                  max-width:395px;
                }
                @media(max-width:991px){
                  max-width:275px;
                }
                @media(max-width:359px){
                  max-width:235px;
                }
              }
              .card-text-check-container {
                margin-top:8px;
              }
              .fa-check {
                font-size:1rem;
                color: #2B987A;
              }
              .seventh-section-div-title {
                font: Bold 16px/22px Roboto;
                color: #4E4F58;
              }
              .seventh-section-div-content {
                padding-left:2px;
                font: 16px/22px Roboto;
                color: #4E4F58;
                padding-right:15px;
                @media(min-width:992px) {
                  height:337px;
                }
              }
              .coming-soon {
                color: ${props => props.theme.colors.primary_violet};
                font: italic 16px/22px Abril Fatface;
                margin-top:60px;
                text-align:center;
                :after {
                  content: url(data:image/svg+xml,${encodeURIComponent(renderToString(<FontAwesomeIcon style={{color: "#8D589B"}} icon={faHeart} />))});
                  display: block;
                  position: absolute;
                  width: 99.9px;
                  height: 87.5px;
                  top: 85px;
                  right: 33%;
                  opacity:0.1;
                }
                @media(max-width:1199px){
                  margin-top:50px;
                }
                @media(max-width:767px){
                  margin-top:0;
                  :after {
                    width: 70px;
                    right:40%;
                    top:30px;
                  }
                }
              }
            `}
          />
        <SEO title={"The Ladyboss : "+ name} description={"Get connected and mentored by " + name +" - " + title} />
        <Row css={css`text-align:left;`}>
          <Col xs="12" md="12" lg="5" css={css`
            height:100%;
            .seventh-section-card {
              border-radius:0;
              border: 2px solid #F4EEF5;
              @media(max-width:767px) {
                min-height:0;
              }
              @media(min-width:768px) {
                border-bottom: 1px solid #fff;
              }
              @media(min-width:992px) {
                border-bottom: 1px solid #fff;
                min-height:760px;
              }
              @media(min-width:1200px) {
                min-height: 684px;
                border-bottom: 2px solid #F4EEF5;
              }
            }
            .card-text {
              margin-bottom:0;
              .job-title {
                  margin-top:5px;
                  div {
                    color: #36373E !important;
                    width:100% !important;
                  }
              }
              .description {
                  font: 16px/22px Roboto;
                  margin-top:5px;
                  ul:blank {
                    display: none;
                 }
              }
            }
            .name {
              color: #572A5C;
              font: Bold 28px/32px Roboto;
              margin-top:15px;
              margin-bottom:5px;
            }
            @media(min-width:992px){
              padding-right:0;
              .name {
                  margin-top:36px;
              }
              .card-body {
                  padding-top:0;
              }
            }
            @media(min-width:1200px){
              max-width:440px;
              padding:0;
              .card-body {
                  padding:0;
                  padding-left:50px;
              }
              img {
                  height:300px;
                  object-fit:cover;
              }
              .name {
                  max-width:362px;
              }
              .card-text {
                  .job-title {
                    max-width:386px;
                  }
                  .description {
                      max-width:362px;
                  }
              }
            }
          `}>
              <Card className="seventh-section-card">
                <Card.Img alt={"WomenWorks Growth Circle Mentor -" + name} variant="top" src={"/ladybosses/"+name.trim().replace(/ /gi, '-').toLowerCase()+".jpg"} />
                <Card.Body>
                  <Card.Title className="name">{name}</Card.Title>
                  <Card.Text css={css`
                    @media(min-width:992px){
                      min-height:178px;
                    }
                    @media(min-width:1200px){
                      min-height:181px;
                    }
                    `}
                  >
                      {/* on production, somehow this become class for ButtonFill & ButtonOutlineCol1. Added row to make those button on the same level*/}
                      <div className="job-title row"> 
                          <div className="seventh-section-subtitle" css={css`
                          padding-left:15px;padding-right:15px;
                          width:100%;
                          @media(min-width:768px){
                            width:50%;
                          }
                          button{width:100%;}`}>
                            {title}
                          </div>
                      </div>
                      <div className="seventh-section-subtitle" css={css`
                        margin-top:20px;
                      `}>
                        Industry
                      </div>
                      <div className="description">
                          {industries}
                      </div>
                  </Card.Text>
                  <Row>
                    <Col xs="12">
                      <OutboundLink target="_blank" rel="noreferrer" href={"https://docs.google.com/forms/d/e/1FAIpQLSdKqKhp_tNAvVrji92BlZkpgQCaOguXBsLVRAXPnJv36vHbWA/viewform?entry.802669091="+ name.replace(/ /gi, '%20')}>      
                        <Button className="button-fill" css={css`
                          @media(max-width:767px) {
                            height: 56px;
                          }
                        `}>
                          Ask me to speak at your event
                        </Button>
                      </OutboundLink>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
          </Col>

          <Col xs="12" md="12" lg="4" css={css`
            height:100%;
            .seventh-section-card {
              border-radius:0;
              border: 2px solid #F4EEF5;
              @media(max-width:767px) {
                  border-bottom: 1px solid #fff; //remove border bottom
                  min-height:0px;
              }
              @media(min-width:768px){
                  border-right:1px solid #fff; //remove border right 
              }
              @media(min-width:992px){
                  border-left:1px solid #fff; //remove border left
                  border-right:1px solid #fff; //remove border right
                  min-height: 760px;
              }
              @media(min-width:1200px) {
                width:390px;
                min-height: 684px;
              }
            }
            .title {
                font: 28px/28px Roboto;
            }
            .row-video {
              margin-top:7px;
            }
            .video-title {
              margin-top:4px;
              margin-bottom:7px;
            }
            @media(min-width:768px){  
              .card-body {
                padding-bottom:24px;
              }
            }
            @media(min-width:992px){
              padding:0;
            }
            @media(min-width:1200px){
              max-width:390px;
              .card-body {
                  padding:0;
                  padding-left:30px;
              }
              .title {
                  margin-top:30px;
                  margin-bottom:0;
              }
              img {
                  width:122px;
              }
            }
          `}>
            <Card className="seventh-section-card">
                <Card.Body>
                    <div css={css`
                      @media(min-width:992px) {
                        min-height:548px;
                      }
                      @media(min-width:1200px) {
                        min-height:475.56px;
                      }
                    `}>
                      <div className="seventh-section-subtitle" css={css`
                        @media(min-width:1200px) {margin-top:30px;}
                      `}>
                        My expertise consultation mentorship topics:
                      </div>
                      <div css={css`
                        font: 16px/22px Roboto;
                        @media(min-width:992px) {
                          min-height:120px;
                        }
                      `}>
                        {expertise && expertise.split(',')[0] && expertise.split(',')[0].trim() && <div className="card-text-check-container">
                          <span className="card-text-check-icon">
                            <FontAwesomeIcon icon={faCheck} />
                          </span>
                          <span className="card-text-check-content">
                            {expertise.split(',')[0]}
                          </span>
                        </div>}
                        {expertise && expertise.split(',')[1] && expertise.split(',')[1].trim() && <div className="card-text-check-container">
                          <span className="card-text-check-icon">
                            <FontAwesomeIcon icon={faCheck} />
                          </span>
                          <span className="card-text-check-content">
                            {expertise.split(',')[1]}
                          </span>
                        </div>}
                        {expertise && expertise.split(',')[2] && expertise.split(',')[2].trim() && <div className="card-text-check-container">
                          <span className="card-text-check-icon">
                            <FontAwesomeIcon icon={faCheck} />
                          </span>
                          <span className="card-text-check-content">
                            {expertise.split(',')[2]}
                          </span>
                        </div>}
                        {expertise && expertise.split(',')[3] && expertise.split(',')[3].trim() && <div className="card-text-check-container">
                          <span className="card-text-check-icon">
                            <FontAwesomeIcon icon={faCheck} />
                          </span>
                          <span className="card-text-check-content">
                            {expertise.split(',')[3]}
                          </span>
                        </div>}
                      </div>
                      <div css={css`
                        margin-top:15px;
                        `}
                      >
                        <div className="seventh-section-div-title">
                          About me:
                        </div>
                        <div className="seventh-section-div-content">
                          {aboutMe}
                        </div>                           
                      </div> 
                    </div>
                    <Row css={css`
                      @media(min-width:768px){margin-top:15px;}
                      @media(min-width:992px){margin-top:0;}
                      @media(min-width:1200px){height:67px;}`}>
                      <div className="seventh-section-subtitle" css={css`
                            padding-left:15px;padding-right:15px;
                            width:100%;
                            @media(min-width:768px){
                              width:50%;
                            }
                            button{width:100%;}`}>
                        <OutboundLink target="_blank" rel="noreferrer" href={"https://docs.google.com/forms/d/e/1FAIpQLSeGwQ9gNxGGKxK8xj7y9L331-qHmHnJfBIjAApiWti-0yFTcw/viewform?entry.1170413415="+ name.replace(/ /gi, '%20')}>      
                          <Button className="button-fill" css={css`@media(min-width:1200px){width:158px;}`}>
                            Ask for mentorship
                          </Button>
                        </OutboundLink>
                      </div>
                      <Col xs="12" md="6" css={css`@media(min-width:768px){padding-left:0;padding-right:1.25rem;}`}>
                        <OutboundLink href={"mailto:yours@womenworks.io" +
                          "?subject=Introduction%20Request" +
                          "&body=Hello%20WomenWorks%20Team,%0D%0A%0D%0A" +
                          "My%20name%20is%20...%20%20I'm%20a%20mentor%20at%20WomenWorks,%20my%20mentor%20referral%20code%20is%20...%0D%0A" +
                          "I%20would%20like%20to%20request%20an%20introduction%20to%20"+name+"%20please%0D%0A" +
                          "The purpose of the introduction is for ...%0D%0A%0D%0A" +
                          "Thank%20you!"}>      
                          <Button className="button-outline">
                            Ask for Intro*
                          </Button>
                        </OutboundLink>
                      </Col> 
                    </Row>
                    <Row>
                      <Col md="6"></Col>
                      <Col md="6">
                        <div css={css`
                            font: italic 12px/22px Roboto;
                            color: #4E4F58;
                            margin-top:5px;
                            @media(min-width:992px){font: italic 10px/22px Roboto;}
                            @media(min-width:1200px){font: italic 12px/22px Roboto;}`}>
                          *For existing mentors only
                        </div>
                      </Col>
                    </Row>
                </Card.Body>
            </Card>
          </Col>

          <Col  xs="12" md="12" lg="3" css={css`
            height:100%;
            margin-bottom:0px;
            @media(min-width:992px){
              padding-left:0;
            }
           .seventh-section-card {
              border: 2px solid #F4EEF5;
              border-radius:0;
              @media(max-width:767px) {
                min-height:0;
              }
              @media(min-width:768px) {
                padding-left:15px;
              }
              @media(min-width:992px){
                  border-left:1px solid #fff; //remove border left
                  min-height: 760px;
                  max-width:345px;
                  background-color: #FCF5E2;
              }
              @media(min-width:1200px) {
                min-height: 684px;
              }
            }
            .title {
              font: Bold 16px/22px Roboto;
              color: #4E4F58;
            }
            .book-title {
              font: Bold 16px/22px Roboto;
            }
            .description {
              font: 16px/22px Roboto;
            }
            .link {
              font: Bold 16px/22px Roboto;
              text-decoration: underline;
              color: #8D589B;
              :hover {
                  cursor:pointer;
                  color: #7F4F8C;
              }
            }
            .link {
              margin-top:10px;
            }
            .book-author {
              font: 16px/22px Roboto;
            }
            @media(min-width:992px){
              .card-body{
                padding-left:0;
              }
              .link {
                margin-top:0;
              }
            }
            @media(min-width:1200px){
              max-width:310px;
              padding:0;
              .title {
                margin-top:30px;
                margin-bottom:10px;
              }
              .card-body{
                padding:0;
                padding-left:12px;
              }
              .link {
                margin-top:25px;
              }
              .book-group {
                margin-top:15px;
              }
          
            }
          `}>
            <Card className="seventh-section-card">
                <Row>
                    <Col md="4" lg="12" css={css`
                      .card-body {
                        background-color: #FEFCF5;
                      }
                      @media(min-width:768px){
                        .card-body {
                            background-color: transparent;
                        }
                        background-color: #FEFCF5;
                        word-break: break-word;
                        min-height:241px;
                      }
                      @media(min-width:992px){
                        max-width:237px;
                        min-height:278px;
                      }
                      @media(min-width:1200px){
                        max-width:307px;
                        min-height:222px;
                      }
                    `}>
                        <Card.Body>
                            <Card.Title className="title">Webinar topics I can share on:</Card.Title>
                            <Card.Text>
                                <div className="description">
                                  {webinarTopics && webinarTopics.split(',')[0] && webinarTopics.split(',')[0].trim() && <ul><li>{webinarTopics.split(',')[0]}</li></ul>}
                                  {webinarTopics && webinarTopics.split(',')[1] && webinarTopics.split(',')[0].trim() && <ul><li>{webinarTopics.split(',')[1]}</li></ul>}
                                  {webinarTopics && webinarTopics.split(',')[2] && webinarTopics.split(',')[0].trim() && <ul><li>{webinarTopics.split(',')[2]}</li></ul>}
                                </div>
                                {!webinarTopics && <span>
                                  I'm open to speaking at your Webinar! Feel free to see my expertise / background and propose topics ;)
                                </span>}
                            </Card.Text>
                        </Card.Body>
                    </Col>
                    <Col md="4" lg="12" css={css`
                      .card-body {
                        background-color: #FDF8EB;
                        padding-right:10px;
                      }
                      @media(min-width:768px){
                        .card-body {
                            background-color: transparent;
                        }
                        background-color: #FDF8EB;
                        word-break: break-word;
                        min-height:241px;
                      }
                      @media(min-width:992px){
                        padding-top:0;
                        padding-bottom:0;
                        max-width:237px;
                        padding-right: 0;
                        min-height:250px;
                      }
                      @media(min-width:1200px){
                        max-width:307px;
                        min-height:238px;
                        height:238px;
                      }
                    `}>
                        <Card.Body>
                          <Card.Title className="title">Communities and teams I could introduce you to:</Card.Title>
                          <Card.Text>
                              <div className="description">
                                {communities && communities.split(',')[0] && communities.split(',')[0].trim() && <ul><li>{communities.split(',')[0]}</li></ul>}
                                {communities && communities.split(',')[1] && communities.split(',')[0].trim() && <ul><li>{communities.split(',')[1]}</li></ul>}
                                {communities && communities.split(',')[2] && communities.split(',')[0].trim() && <ul><li>{communities.split(',')[2]}</li></ul>}
                                {communities && communities.split(',')[3] && communities.split(',')[0].trim() && <ul><li>{communities.split(',')[3]}</li></ul>}
                              </div>
                              {!communities && <div className="coming-soon" css={css`margin-top:40px;@media(max-width:991px){margin-top:25px;@media(max-width:767px){margin-top:10px;:after {top:30px;}};@media(max-width:405px){:after {top:50px;}}`}>
                                  More info coming soon.
                              </div>}
                          </Card.Text>
                        </Card.Body>
                    </Col>
                    <Col md="4" lg="12" css={css`
                      .card-body{
                        background-color: #FCF5E2;
                      }
                      @media(min-width:768px){
                        .card-body {
                            background-color: transparent;
                        }
                        background-color: #FCF5E2;
                        word-break: break-word;
                        min-height:241px;
                      }
                      @media(min-width:992px){
                        max-width:237px;
                        min-height:228px;
                      }
                      @media(min-width:1200px){
                        max-width:307px;
                        min-height:220px;
                      }
                    `}>
                        <Card.Body>
                            <Card.Title className="title">1-on-1 mentorship opportunity:</Card.Title>
                            {categories && <Card.Text>
                                {name} is available for mentorship for {categories}
                            </Card.Text>}
                            {!categories && <Card.Text>
                              I am taking a rest for my 1:1s, but do check out other opportunities I offer!
                              </Card.Text>}
                        </Card.Body>
                    </Col>
                </Row>
            </Card>
          </Col>

          

          
        </Row>
      
          
      </ModalLayout>   
    )
  }
}

export const mentorQuery = graphql`
  query mentorQuery($mentorName: String!) {
    allGoogleSheetContentBankRow(
      filter: {name: {eq: $mentorName}}
    ) {
      edges {
        node {
          name
          title
          categories
          industries
          expertise
          aboutMe
          communities
          webinarTopics
        }
      }
    }
  }
`

import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import styled from "@emotion/styled";
import {css} from "@emotion/core";
import Container from "react-bootstrap/Container";
import { OutboundLink } from "gatsby-plugin-google-analytics";

const BeAMentorSection1Background = styled.div`
  background-color: ${props => props.theme.colors.secondary_green};
  color: ${props => props.theme.colors.white};
  font: 20px/28px Roboto;
  min-height: 669px;
  @media(max-width:767px) {
    min-height: 0;
    overflow:hidden;
  }
`

const BeAMentorSection1Title = styled.div`
  font: 58px/62px Abril Fatface;
  margin-bottom:35px;
  min-height: 132px;
  color: ${props => props.theme.colors.accent_yellow};
  @media(max-width:1199px){
    font: 56px/60px Abril Fatface;
  }
  @media(max-width:767px){
    font: 2.5rem/3rem Abril Fatface;
    margin-bottom:17.5px;
  }
`

const SectionItem = styled.div`
  margin-top:61px;
  margin-bottom:100px;
  display: inline-block;
  @media(max-width:767px){
    margin-top:0;
    margin-bottom:0px;
  }
`


const ButtonFill6 = styled(Button)`
  height: 60px;
  color: ${props => props.theme.buttons.primary_button_yellow_green.normal.color} ;
  background-color: ${props => props.theme.buttons.primary_button_yellow_green.normal.background_color};
  border-color: ${props => props.theme.buttons.primary_button_yellow_green.normal.border_color} ;
  font: 22px/22px Roboto;
  transition: 0.25s ease-out;
  &:hover {
    color: ${props => props.theme.buttons.primary_button_yellow_green.hovered.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_yellow_green.hovered.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_yellow_green.hovered.border_color} !important;
    box-shadow: 0px 3px 20px ${props => props.theme.colors.gray_shadow} !important;
    @media(min-width:1199px){
      margin-top:-3px;
      margin-bottom:3px;
    }
  }
  &:focus, &.focus, &:active, &.active {
    color: ${props => props.theme.buttons.primary_button_yellow_green.pressed.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_yellow_green.pressed.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_yellow_green.pressed.border_color}  !important;
    box-shadow: none !important;
    @media(min-width:1199px){
      margin-top:-3px;
      margin-bottom:3px;
    }
  }
  @media(max-width:922px){
    width:100%;
  }
  @media(max-width:767px){
    width:100%;
    margin-bottom:50px;
  }
`

const BgCol5 = styled(Col)`
  :before {
    background-image:url('/page-be-a-mentor-section2-img2.png');
    content: '';
    display: block;
    position: absolute;
    width: 278px;
    height: 190px;
    top: 250px;
    right: 170px;
    z-index:3;
  }
  :after {
    background-image:url('/page-be-a-mentor-section2-img3.png');
    content: '';
    display: block;
    position: absolute;
    width: 474px;
    height: 400px;
    top: 395px;
    right: 80px;
    z-index:0;
  }
  @media(max-width:1199px){
    :after {
      top: 435px;
      right:50px;
    }
  }
  @media(max-width:991px){
    :after {
      display:none;
    }
    :before {
      display:none;
    }
  }
  @media(max-width:767px){
    :before {
      display:none;
    }
    :after {
      display:block;
      width: 300px;
      height: 300px;
      top: -415px;
      left: 73px;
      background-position:center;
    }
  }
  @media(max-width:374px){
    :after {
      width: 280px;
    }
  }
  @media(max-width:359px){
    :after {
      width: 240px;
    }
  }
`

export default function BeAMentorSection1(props) {
  return (
  <BeAMentorSection1Background>
      <img alt="WomenWorks - two young woman working together" src="/page-be-a-mentor-section2-img1.png" 
        css={css`
          z-index:2;
          @media(max-width:991px) {
            display:none;
          }
          @media(min-width:992px) {
            position: absolute;
            right: 0;
            top:145px;
            height: 500px;
            max-width: 36vw;
            object-fit: cover;
          }
          @media(min-width:1600px) {
            display:none;
          }
      `}></img>
      <img alt="WomenWorks" src="/womenworks.svg"  css={css`
          @media(max-width:1198px) {
            display:none;
          }
          @media(min-width:1199px) {
            position: absolute;
            left:8vw;
            top:644px;
          }
          @media(min-width:1600px) {
            position: absolute;
            left:21vw;
            top:644px;
          }
      `}></img>
      <Container>
          <SectionItem>
            <BgCol5></BgCol5>
            <Row>
              <Col xs="12" lg="8" >
                <BeAMentorSection1Title>
                    Become a mentor, inspire and collaborate.
                </BeAMentorSection1Title>
              </Col>
              <Col xs="12" lg="4">
                <img alt="WomenWorks - two young woman looking at laptop" src="/page-be-a-mentor-section2-img1.png"
                  css={css`
                    @media(max-width:767px) {
                      width:100%;
                      margin-bottom:15px;
                    }
                    @media(min-width:768px) {
                      object-fit: cover;
                      height: 250px;
                      width:100%;
                      margin-bottom:30px;
                      object-position: 10% 10%;
                    }
                    @media(min-width:992px) {
                      display:none;
                    }
                    @media(min-width:1600px) {
                      display:block;
                      position:absolute;
                      width: 516px;
                      height: 500px;
                    }
                `}></img>
              </Col>
              <Col xs="12" lg="6" css={css`margin-bottom:25px;@media(max-width:767px){margin-bottom:20px;}`}>
                <p>
                Join the community and empower others and help close the gender gap that is still prevalent in Indonesia’s workforce today. If you are a professional or entrepreneur passionate to share your experience and expertise, we would love to connect you with fellow women from varying generations and stages in their career lives who needs your help.
                </p>
              </Col>
              <Col xs="12" lg="8">
                <OutboundLink target="_blank" rel="noreferrer" href="https://bit.ly/womenworksmentorsignupnew">
                  <ButtonFill6>Fill out the mentor registration form</ButtonFill6>
                </OutboundLink>
              </Col>
            </Row>
          </SectionItem>
      </Container>
  </BeAMentorSection1Background>
  )
}


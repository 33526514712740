const themeWW = {
  //Colors
  colors: {
    primary_violet : "#8D589B",
    primary_violet_hover : "#7F4F8C",
    primary_violet_dark : "#55355D",
    primary_violet_tint40 : "#BB9BC3",
    primary_violet_shade20: "#71467C",
    primary_violet_dark2: "#572A5C",

    accent_yellow : "#F6DE9D",
    accent_yellow_hover : "#DDC88D",
    accent_yellow_dark : "#94855E",
    accent_yellow20 : "#F8E5B1",
    accent_yellow70 : "#FCF5E2",
    accent_yellow80 : "#FDF8EB",
    accent_yellow90 : "#FEFCF5",

    secondary_green : "#30A987",
    secondary_green10 : "#2B987A",
    secondary_green_dark : "#1D6551",
    secondary_green_shade20: "#26876C",

    white : "#FFFFFF",
    white_dark : "#F4EEF5",

    gray_shadow : "#00000033",
    gray_shadow_card : "#0000000A",
    gray_shadow_card_hover : "#00000014",
    gray_dark : "#4E4F58",
    gray_dark2 : "#36373E",
    gray_dark3 : "#676767",
    gray_light_background : "#F8F8F8",
    gray_border : "#E7E9EE",
    gray_border2 : "#BFBFBF"
  }
}
//buttons
themeWW.buttons = {
  //primary buttons
  primary_button_violet : {
    normal : {
      color : themeWW.colors.white,
      background_color : themeWW.colors.primary_violet, 
      border_color : themeWW.colors.primary_violet 
    }, hovered : {
      color: themeWW.colors.white,
      background_color : themeWW.colors.primary_violet_hover, 
      border_color : themeWW.colors.primary_violet_hover, 
      shadow : themeWW.colors.gray_shadow
    }, pressed : {
      color: themeWW.colors.white,
      background_color : themeWW.colors.primary_violet_hover, 
      border_color : themeWW.colors.primary_violet_hover, 
    }
  },
  primary_button_yellow_green : {
    normal : {
      color : themeWW.colors.secondary_green_dark,
      background_color : themeWW.colors.accent_yellow,
      border_color : themeWW.colors.accent_yellow, 
    }, hovered : {
      color : themeWW.colors.secondary_green_dark,
      background_color : themeWW.colors.accent_yellow_hover,
      border_color : themeWW.colors.accent_yellow_hover,
      shadow : themeWW.colors.gray_shadow
    }, pressed : {
      color : themeWW.colors.secondary_green_dark,
      background_color : themeWW.colors.accent_yellow,
      border_color : themeWW.colors.accent_yellow,
    }
  },
  primary_button_yellow_violet : {
    normal : {
      color : themeWW.colors.primary_violet_dark,
      background_color : themeWW.colors.accent_yellow,
      border_color : themeWW.colors.accent_yellow, 
    }, hovered : {
      color : themeWW.colors.primary_violet_dark,
      background_color : themeWW.colors.accent_yellow_hover,
      border_color : themeWW.colors.accent_yellow_hover,
      shadow : themeWW.colors.gray_shadow
    }, pressed : {
      color : themeWW.colors.primary_violet_dark,
      background_color : themeWW.colors.accent_yellow,
      border_color : themeWW.colors.accent_yellow,
    }
  },
  primary_small_button_yellow_gray : {
    normal : {
      color : themeWW.colors.gray_dark,
      background_color : themeWW.colors.accent_yellow,
      border_color : themeWW.colors.accent_yellow, 
    }, hovered : {
      color : themeWW.colors.primary_violet_dark,
      background_color : themeWW.colors.accent_yellow_hover,
      border_color : themeWW.colors.accent_yellow_hover,
      shadow : themeWW.colors.gray_shadow
    }, pressed : {
      color : themeWW.colors.primary_violet_dark,
      background_color : themeWW.colors.accent_yellow_hover,
      border_color : themeWW.colors.accent_yellow_hover,
    }
  },
  primary_small_button_yellow_violet : {
    normal : {
      color : themeWW.colors.primary_violet_dark,
      background_color : themeWW.colors.accent_yellow,
      border_color : themeWW.colors.accent_yellow, 
    }, hovered : {
      color : themeWW.colors.primary_violet_dark,
      background_color : themeWW.colors.accent_yellow_hover,
      border_color : themeWW.colors.accent_yellow_hover,
      shadow : themeWW.colors.gray_shadow
    }, pressed : {
      color : themeWW.colors.primary_violet_dark,
      background_color : themeWW.colors.accent_yellow_hover,
      border_color : themeWW.colors.accent_yellow_hover,
    }
  },
  //secondary big button
  secondary_big_button_violet : {
    normal : {
      color : themeWW.colors.primary_violet,
      background_color : themeWW.colors.white, 
      border_color : themeWW.colors.primary_violet 
    }, hovered : {
      color: themeWW.colors.white,
      background_color : themeWW.colors.primary_violet_hover, 
      border_color : themeWW.colors.primary_violet_hover, 
      shadow : themeWW.colors.gray_shadow
    }, pressed : {
      color: themeWW.colors.white,
      background_color : themeWW.colors.primary_violet, 
      border_color : themeWW.colors.primary_violet, 
    }
  },
  secondary_big_button_white_green : {
    normal : {
      color : themeWW.colors.white,
      background_color : themeWW.colors.secondary_green,
      border_color : themeWW.colors.white, 
    }, hovered : {
      color : themeWW.colors.secondary_green_dark,
      background_color : themeWW.colors.accent_yellow_hover,
      border_color : themeWW.colors.accent_yellow_hover,
      shadow : themeWW.colors.gray_shadow
    }, pressed : {
      color : themeWW.colors.secondary_green_dark,
      background_color : themeWW.colors.accent_yellow_hover,
      border_color : themeWW.colors.accent_yellow_hover,
    }
  },
  secondary_big_button_yellow_violet : {
    normal : {
      color : themeWW.colors.accent_yellow,
      background_color : themeWW.colors.primary_violet,
      border_color : themeWW.colors.accent_yellow, 
    }, hovered : {
      color : themeWW.colors.primary_violet_dark,
      background_color : themeWW.colors.accent_yellow_hover,
      border_color : themeWW.colors.accent_yellow_hover,
      shadow : themeWW.colors.gray_shadow
    }, pressed : {
      color : themeWW.colors.primary_violet_dark,
      background_color : themeWW.colors.accent_yellow_hover,
      border_color : themeWW.colors.accent_yellow_hover,
    }
  },
  //secondary small button
  secondary_small_button_violet : {
    normal : {
      color : themeWW.colors.primary_violet,
      background_color : themeWW.colors.white, 
      border_color : themeWW.colors.primary_violet 
    }, hovered : {
      color: themeWW.colors.white,
      background_color : themeWW.colors.primary_violet_hover, 
      border_color : themeWW.colors.primary_violet_hover, 
      shadow : themeWW.colors.gray_shadow
    }, pressed : {
      color: themeWW.colors.white,
      background_color : themeWW.colors.primary_violet_hover, 
      border_color : themeWW.colors.primary_violet_hover, 
    }
  },
  secondary_small_button_white_green : {
    normal : {
      color : themeWW.colors.white,
      background_color : themeWW.colors.secondary_green,
      border_color : themeWW.colors.white, 
    }, hovered : {
      color : themeWW.colors.secondary_green,
      background_color : themeWW.colors.white,
      border_color : themeWW.colors.white,
      shadow : themeWW.colors.gray_shadow
    }, pressed : {
      color : themeWW.colors.secondary_green,
      background_color : themeWW.colors.white,
      border_color : themeWW.colors.white,
    }
  },
  secondary_small_button_white_violet : {
    normal : {
      color : themeWW.colors.white,
      background_color : themeWW.colors.primary_violet,
      border_color : themeWW.colors.white, 
    }, hovered : {
      color : themeWW.colors.primary_violet,
      background_color : themeWW.colors.white,
      border_color : themeWW.colors.white,
      shadow : themeWW.colors.gray_shadow
    }, pressed : {
      color : themeWW.colors.primary_violet,
      background_color : themeWW.colors.white,
      border_color : themeWW.colors.white,
    }
  }
}

export default function theme() {
    return (themeWW)
};
import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import styled from "@emotion/styled";
import {css} from "@emotion/core";
import Container from 'react-bootstrap/Container';
import { OutboundLink } from "gatsby-plugin-google-analytics";

const FirstSectionContainer = styled(Container)`
  min-height: 334px;
  border-bottom: 2px solid ${props => props.theme.colors.gray_border};
`

const FirstSectionTitle = styled.div`
  color : ${props => props.theme.colors.gray_dark};
  margin-top:61px;
  font: 58px/62px Abril Fatface;
  text-align:center;
  margin-bottom:35px;
  @media(max-width:767px){
    font: 2.5rem/3rem Abril Fatface;
    margin-top:20px;
    margin-bottom:15px;
  }
`

const ButtonFill1 = styled(Button)`
  height: 60px;
  margin-bottom:0px;
  color: ${props => props.theme.buttons.primary_button_violet.normal.color} ;
  background-color: ${props => props.theme.buttons.primary_button_violet.normal.background_color};
  border-color: ${props => props.theme.buttons.primary_button_violet.normal.border_color} ;
  font: 22px/22px Roboto;
  transition: 0.25s ease-out;
  &:hover {
    color: ${props => props.theme.buttons.primary_button_violet.hovered.color}!important;
    background-color: ${props => props.theme.buttons.primary_button_violet.hovered.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_violet.hovered.border_color}  !important;
    box-shadow: 0px 3px 20px ${props => props.theme.buttons.primary_button_yellow_violet.hovered.shadow} !important;
    @media(min-width:1199px){
      margin-top:-3px;
      margin-bottom:3px;
    }
  }
  &:focus, &.focus, &:active, &.active {
    color: ${props => props.theme.buttons.primary_button_violet.pressed.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_violet.pressed.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_violet.pressed.border_color}  !important;
    box-shadow: none !important;
    @media(min-width:1199px){
      margin-top:-3px;
      margin-bottom:3px;
    }
  }
  @media(max-width:1023px){
    width:100%;
    margin-bottom:20px;
  }
`

export default function Home() {
  return (      
    <FirstSectionContainer>
      <Row className="justify-content-center">
        <Col md="12">
          <FirstSectionTitle>
            Get mentored and connected to Indonesia’s inspiring professionals
          </FirstSectionTitle>
        </Col>
      </Row>
      <Row>
        <Col css={css`text-align:center;`}>
          <OutboundLink href="http://bit.ly/bookspeakerwomenworks" target="_blank" rel="noreferrer">
            <ButtonFill1>
                Book a speaker for your event
            </ButtonFill1>
          </OutboundLink>
        </Col>
      </Row>
    </FirstSectionContainer>
  )
}
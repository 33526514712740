import React from "react";
import { Link } from "gatsby";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from "@emotion/styled";
import {css} from "@emotion/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'

const SecondSectionBackground = styled.div`
  min-height: 1004px;
  background-color: ${props => props.theme.colors.white};
  @media(max-width:991px){
    min-height:850px;
  }
`
const Tagline = styled.h1`
  color: ${props => props.theme.colors.secondary_green};
  font: 28px/32px Abril Fatface;
  padding-top:26.5px;
  @media(min-width:768px){
    padding-top: 53px;
    margin-left:-7px; 
    width: 376px;
  }
  @media(max-width:320px){
    font: 25px/30px Abril Fatface;
  }
`
const BgCol21 = styled(Col)`
  margin-top:10px;
  :after {
    @media(min-width:992px) {
      display:none;
    }
    @media(min-width:1600px) {
      background-image:url('/page-home-section2-bg1.png');
      content: '';
      display: block;
      position: absolute;
      width: 516px;
      height: 400px;
      /* allow for bootstrap column padding */
      top: 0;
      left: -15px;
      bottom: -15px;
    }
  }
  @media(max-width:1199px){
    :after {
      width: 366px;
    }
  }
  @media(max-width:991px){
    :after {
      background-image:none;
      height: 0;
      width: 0;
    }
  }
`
const BgCol22 = styled(Col)`
  margin-top:10px;
  :after {
    background-image:url('page-home-section2-bg2.png');
    background-repeat: no-repeat;
    content: '';
    display: block;
    position: absolute;
    width: 300px;
    height: 300px;
    /* allow for bootstrap column padding */
    top: 299px;
    left: -15px;
    bottom: -15px;
  }
  @media(max-width:1199px){
    :after {
      width: 286px;
    }
  }
  @media(max-width:991px){
    :after {
      background-image:none;
      height: 0;
      width: 0;
    }
  }
`
const SecondSectionTitle = styled.div`
  color: ${props => props.theme.colors.accent_yellow_dark};
  font: 58px/62px Abril Fatface;
  margin-top:200px;
  @media(max-width:991px){
    margin-top:80px;
  }
  @media(max-width:767px){
    margin-top:20px;
    font: 2.5rem/3rem Abril Fatface;
  }
  @media(max-width:320px){
    font: 2rem/2.5rem Abril Fatface;
  }
`
const SecondSectionText = styled.div`
  color: ${props => props.theme.colors.gray_dark};
  font: 19px/28px Roboto;
  margin-top:35px;
  @media(max-width:767px){
    margin-top:20px;
  }
  .row {
    margin-bottom:20px;
  }
  span {
    margin-top:20px;
    margin-left:7px;
  }
`
const FAHeart = styled(FontAwesomeIcon)`
  font-size:1rem;
  color: ${props => props.theme.colors.accent_yellow_hover};
`
const SecondSectionButton = styled.div`
  margin-top:5px;
`
const ButtonFill2 = styled(Button)`
  height: 60px;
  color: ${props => props.theme.buttons.primary_button_violet.normal.color};
  background-color: ${props => props.theme.buttons.primary_button_violet.normal.background_color};
  border-color: ${props => props.theme.buttons.primary_button_violet.normal.border_color};
  font: 22px/22px Roboto;
  transition: 0.25s ease-out;
  &:hover {
    color: ${props => props.theme.buttons.primary_button_violet.hovered.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_violet.hovered.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_violet.hovered.border_color} !important;
    box-shadow: 0px 3px 20px ${props => props.theme.buttons.primary_button_violet.hovered.shadow} !important;
    @media(min-width:1199px){
      margin-top:-3px;
    }
  }
  &:focus, &.focus, &:active, &.active {
    color: ${props => props.theme.buttons.primary_button_violet.pressed.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_violet.pressed.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_violet.pressed.border_color} !important;
    box-shadow: none !important;
    @media(min-width:1199px){
      margin-top:-3px;
    }
  }
  @media(min-width:1200px){
    width:223px;
  }
  @media(max-width:1199px){
    width:100%;
  }
`
const ButtonOutline2 = styled(Button)`
  height: 60px;
  color: ${props => props.theme.buttons.secondary_big_button_violet.normal.color};
  background-color: ${props => props.theme.buttons.secondary_big_button_violet.normal.background_color};
  border-color: ${props => props.theme.buttons.secondary_big_button_violet.normal.border_color};
  font: 22px/22px Roboto;
  transition: 0.25s ease-out;
  &:hover {
    color: ${props => props.theme.buttons.secondary_big_button_violet.hovered.color} !important;
    background-color: ${props => props.theme.buttons.secondary_big_button_violet.hovered.background_color} !important;
    border-color: ${props => props.theme.buttons.secondary_big_button_violet.hovered.border_color} !important;
    box-shadow: 0px 3px 20px ${props => props.theme.buttons.secondary_big_button_violet.normal.shadow} !important;
    @media(min-width:1199px){
      margin-top:-3px;
    }
    @media(max-width:767px){
      margin-top:17px;
    }
  }
  &:focus, &.focus, &:active, &.active {
    color: ${props => props.theme.buttons.secondary_big_button_violet.pressed.color} !important;
    background-color: ${props => props.theme.buttons.secondary_big_button_violet.pressed.background_color} !important;
    border-color: ${props => props.theme.buttons.secondary_big_button_violet.pressed.border_color} !important;
    box-shadow: none !important;
    @media(min-width:1199px){
      margin-top:-3px;
    }
    @media(max-width:767px){
      margin-top:17px;
    }
  }
  @media(min-width:1200px){
    margin-left:25px;
  }
  @media(max-width:1199px){
    width:100%;
  }
  @media(max-width:767px){
    margin-top:20px;
    margin-bottom:30px;
  }
`


export default function IndexSection2() {
  return (
  <SecondSectionBackground>
    <img alt="WomenWorks - career woman working with chart" src="/page-home-section2-bg1.png" 
      css={css`
        @media(max-width:991px) {
          display:none;
        }
        @media(min-width:992px) {
          position: absolute;
          right: 0;
          margin-top:158px;
          min-height:330px;
          max-width: 38vw;
          object-fit: cover;
        }
        @media(min-width:1600px) {
          display:none;
        }
    `}></img>
    <Container>
      <Row className="justify-content-end">
        <Col lg="6">
          <Tagline>
            Bad-ass.
            <br></br>Connected.
            <br></br>Financially independent.
          </Tagline>
        </Col>
    </Row>
    <Row>
      <Col xs="12">
        <img alt="WomenWorks - career woman working with chart" css={css`width:100%; margin-top:15px; @media(min-width:768px){display:none;}`} src="/page-home-section2-bg1.png"></img>
      </Col>
    </Row>
    <Row>
      <Col lg="8" xl="7">
        <SecondSectionTitle>
          Join as a mentor and expand your impact
        </SecondSectionTitle>
        <SecondSectionText>
          <div css={css`margin-bottom:20px;`}>Join the community of empowered women who empower others and unlock the world's potential by empowering the world with <b>#bad-ass</b>, <b>#connected</b> and <b>#financially-independent</b> women.</div>
          <Row >
            <Col xs="auto" css={css`padding-right:0;`}>
              <FAHeart icon={faHeart} />
            </Col>
            <Col css={css`padding-left:7px;`}>Connect with fellow women from varying generations and stages in their career lives who needs your help</Col>
          </Row>
          <Row >
            <Col xs="auto" css={css`padding-right:0;`}>
              <FAHeart icon={faHeart} />
            </Col>
            <Col css={css`padding-left:7px;`}>Share your experience and expertise to inspire others and capitalize your knowledge</Col>
          </Row>
          <Row >
            <Col xs="auto" css={css`padding-right:0;`}>
              <FAHeart icon={faHeart} />
            </Col>
            <Col css={css`padding-left:7px;`}>Network with other mentors and top-notch women leaders in your impact journey</Col>
          </Row>
        </SecondSectionText>
        <SecondSectionButton>
          <Row>
            <Col sm lg="5" xl="4">
              <Link to={`/be-a-mentor/`}>
                <ButtonFill2>Become a mentor </ButtonFill2>
              </Link>
            </Col>
            <Col sm lg="7">
              <Link to="/growth-circle">
                <ButtonOutline2>Sign up for Growth Circle</ButtonOutline2>
              </Link>
            </Col>
          </Row>
        </SecondSectionButton>
      </Col>
      <Col xl="1" css={css`@media(max-width:1199px){display:none;}`}>{/* empty column */}</Col> 
      <BgCol21 lg="1">
      </BgCol21>
      <BgCol22 lg="3">
      </BgCol22>
    </Row>
    </Container>
  </SecondSectionBackground>
  )
}
import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import styled from "@emotion/styled";
import {css} from "@emotion/core";
import Link from "../components/link";

const WhoWeAreSection2Background = styled.div`
  min-height: 439px;
  maximum-height: 581px;
  background: linear-gradient(to left, ${props => props.backgroundColorRight} 50%, ${props => props.backgroundColorLeft} 50%);
  color: ${props => props.color};
  position:relative;
  width:100%;
  overflow:hidden;
`

const WhoWeAreSection2Title = styled.div`
  font: 58px/62px Abril Fatface;
  margin-bottom:15px;
  @media(max-width:991px){
    min-height:248px;
  }
  @media(max-width:767px){
    font: 2.5rem/3rem Abril Fatface;
    min-height:0;
  }
  @media(min-width:1200px) {
    min-height: 127px;
  }
`
const SectionItem = styled(Col)`
  margin-top:130px;
  margin-bottom:130px;
  text-align:center;
  display: inline-block;
  @media(max-width:767px){
    margin-top:50px;
    margin-bottom:50px;
  }
`
const SectionP = styled.p`
  margin-bottom:35px;min-height:84px;
  @media(max-width:991px){
    min-height:140px;
  }
  @media(max-width:767px){
    min-height:0;
  }
`

const ColumnSection = styled(Col)`
  background-color: ${props => props.backgroundColor};
  font: 20px/28px Roboto;
`

const ButtonFill6 = styled(Button)`
  height: 60px;
  color: ${props => props.color};
  background-color: ${props => props.backgroundColor};
  border-color: ${props => props.borderColor};
  font: 22px/22px Roboto;
  transition: 0.25s ease-out;
  &:hover {
    color: ${props => props.colorHover} !important;
    background-color: ${props => props.backgroundColorHover} !important;
    border-color: ${props => props.borderColorHover} !important;
    box-shadow: 0px 3px 20px ${props => props.theme.colors.gray_shadow} !important;
    @media(min-width:1199px){
      margin-top:-3px;
    }
  }
  &:focus, &.focus, &:active, &.active {
    color: ${props => props.colorPressed} !important;
    background-color: ${props => props.backgroundColorPressed} !important;
    border-color: ${props => props.theme.borderColorPressed} !important;
    box-shadow: none !important;
    @media(min-width:1199px){
      margin-top:-3px;
    }
  }
  @media(max-width:767px){
    width:100%;
    margin-bottom:20px;
  }
`

export default function NewThemedMentorshipSection3(props) {
  return (
  <WhoWeAreSection2Background color={props.TextColor} backgroundColorLeft={props.BackgroundLeft} backgroundColorRight={props.BackgroundRight}>
    <Container>
      <Row>
          <ColumnSection xs="12" md="6" xl="5" backgroundColor={props.BackgroundLeft}>
            <SectionItem>
              <WhoWeAreSection2Title>
                {props.TitleLeft}
              </WhoWeAreSection2Title>
              <SectionP>
                  {props.ContentLeft}
              </SectionP>
              <Link to={props.ButtonLinkLeft}>
                <ButtonFill6 variant="outline-light" 
                color={props.ButtonColorLeft} 
                backgroundColor={props.ButtonBackgroundLeft} 
                borderColor={props.ButtonBorderLeft} 
                colorHover={props.ButtonColorHoverLeft}
                backgroundColorHover={props.ButtonBackgroundHoverLeft}
                borderColorHover={props.ButtonBorderHoverLeft}
                colorPressed={props.ButtonColorPressedLeft}
                backgroundColorPressed={props.ButtonBackgroundPressedLeft}
                borderColorPressed={props.ButtonBorderPressedLeft}
                >
                    {props.ButtonTextLeft}
                </ButtonFill6>
              </Link>
            </SectionItem>
          </ColumnSection>
          <Col xl="2" css={css`@media(max-width:1199px){display:none;}`}></Col>
          <ColumnSection xs="12" md="6" xl="5" backgroundColor={props.BackgroundRight}>
            <SectionItem>
              <WhoWeAreSection2Title>
                {props.TitleRight}
              </WhoWeAreSection2Title>
              <SectionP>{props.ContentRight}</SectionP>
              <Link to={props.ButtonLinkRight}>
                <ButtonFill6 variant="outline-light" 
                  color={props.ButtonColorRight} 
                  backgroundColor={props.ButtonBackgroundRight} 
                  borderColor={props.ButtonBorderRight} 
                  colorHover={props.ButtonColorHoverRight}
                  backgroundColorHover={props.ButtonBackgroundHoverRight}
                  borderColorHover={props.ButtonBorderHoverRight}
                  colorPressed={props.ButtonColorPressedRight}
                  backgroundColorPressed={props.ButtonBackgroundPressedRight}
                  borderColorPressed={props.ButtonBorderPressedRight}
                >
                  {props.ButtonTextRight}
                </ButtonFill6>
              </Link>
            </SectionItem>
          </ColumnSection>
      </Row>
    </Container>
  </WhoWeAreSection2Background>
  )
}

import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from "@emotion/styled";
import {css} from "@emotion/core";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'

const BeAMentorSection2Background = styled.div`
  min-height: 1004px;
  background-color: ${props => props.theme.colors.white};
  @media(max-width:991px){
    min-height: 0;
  }
`

const BeAMentorSection2Title = styled.div`
  color: ${props => props.theme.colors.accent_yellow_dark};
  font: 58px/62px Abril Fatface;
  margin-top:286px;
  margin-bottom:25px;
  @media(max-width:991px){
    margin-top:50px;
  }
  @media(max-width:767px){
    margin-top:50px;
    font: 2.5rem/3rem Abril Fatface;
  }
`
const BeAMentorSection2Text = styled.div`
  color: ${props => props.theme.colors.gray_dark};
  font: 19px/28px Roboto;
  margin-top:35px;
  @media(max-width:767px){
    margin-top:20px;
  }
  .row {
    margin-bottom:20px;
  }
  span {
    margin-top:20px;
    margin-left:7px;
  }
`
const FAHeart = styled(FontAwesomeIcon)`
  font-size:1rem;
  color: ${props => props.theme.colors.accent_yellow_hover};
`
const BeAMentorSection2Button = styled.div`
  margin-top:25px;
`
const ButtonFill2 = styled(Button)`
  min-height: 60px
  color: ${props => props.theme.buttons.primary_button_violet.normal.color};
  background-color: ${props => props.theme.buttons.primary_button_violet.normal.background_color};
  border-color: ${props => props.theme.buttons.primary_button_violet.normal.border_color};
  font: 22px/22px Roboto;
  transition: 0.25s ease-out;
  &:hover {
    color: ${props => props.theme.buttons.primary_button_violet.hovered.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_violet.hovered.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_violet.hovered.border_color} !important;
    box-shadow: 0px 3px 20px ${props => props.theme.buttons.primary_button_violet.hovered.shadow} !important;
    @media(min-width:1199px){
      margin-top:-3px;
    }
  }
  &:focus, &.focus, &:active, &.active {
    color: ${props => props.theme.buttons.primary_button_violet.pressed.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_violet.pressed.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_violet.pressed.border_color} !important;
    box-shadow: none !important;
    @media(min-width:1199px){
      margin-top:-3px;
    }
  }
  @media(min-width:1200px){
    width:270px;
    min-height:60px;
  }
  @media(max-width:1199px){
    min-height:60px;
    width:270px;
  }
  @media(max-width:992px){
    width:100%;
    margin-bottom:50px;
  }
  @media(max-width:767px){
    margin-bottom:50px;
    width:100%;
  }
`

export default function BeAMentorSection2() {
  return (
  <BeAMentorSection2Background>
    <Container>
    <Row>
      <Col lg="4" xl="4"></Col>
      <Col lg="7" xl="7">
        <BeAMentorSection2Title>
            Sign up as a mentor and get access to:
        </BeAMentorSection2Title>
        <BeAMentorSection2Text>
          <Row >
            <Col xs="auto" css={css`padding-right:0;`}>
              <FAHeart icon={faHeart} />
            </Col>
            <Col css={css`padding-left:7px;`}>Inspiring women leaders in the #Ladyboss Mentor Community</Col>
          </Row>
          <Row >
            <Col xs="auto" css={css`padding-right:0;`}>
              <FAHeart icon={faHeart} />
            </Col>
            <Col css={css`padding-left:7px;`}>Committed women mentees who can be your next hires or even potential investments ;)</Col>
          </Row>
          <Row >
            <Col xs="auto" css={css`padding-right:0;`}>
              <FAHeart icon={faHeart} />
            </Col>
            <Col css={css`padding-left:7px;`}>Exclusively facilitated intros to a curated pool of Indonesian women leaders of your choosing within our Circle</Col>
          </Row>
        </BeAMentorSection2Text>
        <BeAMentorSection2Button>
          <Row>
            <Col sm lg="5" xl="4" xs="12">
              <OutboundLink target="_blank" rel="noreferrer" href="https://bit.ly/womenworksmentorsignupnew">
                <ButtonFill2>Become a mentor now</ButtonFill2>
              </OutboundLink>
            </Col>
          </Row>
        </BeAMentorSection2Button>
      </Col>
      <Col lg="1" xl="1"></Col>
    </Row>
    </Container>
  </BeAMentorSection2Background>
  )
}
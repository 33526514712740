import React from "react";
import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import styled from "@emotion/styled";
import {css} from "@emotion/core";
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagramSquare } from '@fortawesome/free-brands-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'

const FirstSectionContainer = styled(Container)`
  min-height: 1034px;
  padding-bottom:120px;
  @media(max-width:767px){
    padding-bottom:20px;
  }
`

const FirstSectionDescription = styled.div`
  color : ${props => props.theme.colors.white};
  font: 20px/28px Roboto;
  text-align:center;
  margin-bottom:25px;
`

const FirstSectionTitle = styled.div`
  color : ${props => props.theme.colors.accent_yellow};
  margin-top:61px;
  font: 58px/62px Abril Fatface;
  text-align:center;
  margin-bottom:25px;
  @media(max-width:767px){
    font: 2.5rem/3rem Abril Fatface;
    margin-top:20px;
    margin-bottom:15px;
  }
`

const ButtonFill1 = styled(Button)`
  height: 60px;
  margin-bottom:100px;
  color: ${props => props.theme.colors.secondary_green_dark};
  background-color: ${props => props.theme.colors.accent_yellow};
  border-color: ${props => props.theme.buttons.primary_button_yellow_green.normal.border_color} ;
  font: 22px/22px Roboto;
  transition: 0.25s ease-out;
  &:hover {
    color: ${props => props.theme.buttons.primary_button_yellow_green.hovered.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_yellow_green.hovered.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_yellow_green.hovered.border_color}  !important;
    box-shadow: 0px 3px 20px ${props => props.theme.colors.gray_shadow} !important;
    @media(min-width:1199px){
      margin-top:-3px;
      margin-bottom:103px;
    }
  }
  &:focus, &.focus, &:active, &.active {
    color: ${props => props.theme.buttons.primary_button_yellow_green.pressed.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_yellow_green.pressed.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_yellow_green.pressed.border_color}  !important;
    box-shadow: none !important;
    @media(min-width:1199px){
      margin-top:-3px;
      margin-bottom:103px;
    }
  }
  @media(min-width:1199px){
    width:272px;
  }
  @media(min-width:768px){
    margin-right:25px;
  }
  @media(max-width:767px){
    width:100%;
    margin-bottom:20px;
  }
`

const ButtonFill2 = styled(Button)`
  height: 60px;
  margin-bottom:100px;
  color: ${props => props.theme.buttons.secondary_small_button_white_green.normal.color};
  background-color: ${props => props.theme.buttons.secondary_small_button_white_green.normal.background_color};
  border-color: ${props => props.theme.buttons.secondary_small_button_white_green.normal.border_color} ;
  font: 22px/22px Roboto;
  transition: 0.25s ease-out;
  &:hover {
    color: ${props => props.theme.buttons.secondary_small_button_white_green.hovered.color} !important;
    background-color: ${props => props.theme.buttons.secondary_small_button_white_green.hovered.background_color} !important;
    border-color: ${props => props.theme.buttons.secondary_small_button_white_green.hovered.border_color}   !important;
    box-shadow: 0px 3px 20px ${props => props.theme.colors.gray_shadow} !important;
    @media(min-width:1199px){
      margin-top:-3px;
    }
  }
  &:focus, &.focus, &:active, &.active {
    color: ${props => props.theme.buttons.secondary_small_button_white_green.pressed.color} !important;
    background-color: ${props => props.theme.buttons.secondary_small_button_white_green.pressed.background_color} !important;
    border-color: ${props => props.theme.buttons.secondary_small_button_white_green.pressed.border_color}   !important;
    box-shadow: none !important;
    @media(min-width:1199px){
      margin-top:-3px;
    }
  }
  @media(min-width:1199px){
    width:324px;
  }
  @media(max-width:767px){
    width:100%;
    margin-bottom:20px;
    text-align:ce
  }
`

const CardsContainer = styled.div`
  color: ${props => props.theme.colors.gray_dark};
  justify-content: center;
  display:flex;
  flex-direction:column;
  margin-left:0px;
`

const ProfileCardColumn = styled(Col)`
  display:flex;
  justify-content:center;
`

const ProfileCard = styled(Card)`
  width:278px;
  height:373px;
  text-align:center;
  margin-bottom:30px;
  @media(max-width:768px){
    margin-right:0px;
  }
`
const CardTitle = styled(Card.Title)`
  font: Bold 16px/22px Roboto;
  margin-bottom:4px;
`

const CardText= styled(Card.Text)`
  height:66px;
  margin-top:0;
  margin-bottom:4px;
  font: 15px/22px Roboto;
`

const SocialMediaIcon = styled(FontAwesomeIcon)`
  font-size:2rem;
  color: ${props => props.theme.colors.primary_violet};
  margin-top:3px;
  &:hover {
    color: ${props => props.theme.colors.primary_violet_hover} !important;
  }
  &:focus, &.focus, &:active, &.active {
    color: ${props => props.theme.colors.primary_violet_hover} !important;
  }
`

const WWTeam = [
  {name:"Windy Natriavi", title:"Co-Founder & Chief LadyBoss @WomenWorks / CPO, AwanTunai", picture:"windy-natriavi.png", linkedinLink:"https://www.linkedin.com/in/windynatriavi/", instagramLink:"https://www.instagram.com/windynatriavi"},
  {name:"Fransiska P.W. Hadiwidjana", title:"Co-Founder & CTO @WomenWorks / Head of Engineering, Mamikos", picture:"fransiska-pw-hadiwidjana.png", linkedinLink:"https://www.linkedin.com/in/fransiskapw/", instagramLink:"https://www.instagram.com/fransiskapw"},
  {name:"Wina Wilanisa Wirsatyo", title:"Mentor Strategy and Relations Director @WomenWorks / Comm. and Branding Expert", picture:"wina-wilanisa-wirsatyo.png", linkedinLink:"https://www.linkedin.com/in/winawirsatyo/", instagramLink:"https://www.instagram.com/winawilanisa"},
  {name:"Stacy Yulianto", title:"Acquisition & Partnership Director @WomenWorks / Communications Specialist", picture:"stacy-yulianto.png", linkedinLink:"https://www.linkedin.com/in/stacylaurentiayulianto/", instagramLink:"https://www.instagram.com/stacylaurentia/"},
  {name:"Fransiska Andita", title:"Mentee Ops and Relations @WomenWorks", picture:"fransiska-andita.png", linkedinLink:"https://www.linkedin.com/in/fransiska-andita-a54303122/", instagramLink:"https://www.instagram.com/loitecia"},
  {name:"Shalika Hanum", title:"Chief Design Officer @WomenWorks/Lead Designer, Fincite GmbH Frankfurt", picture:"shalika-hanum.png", linkedinLink:"https://www.linkedin.com/in/shalika-hanum-08709559/", instagramLink:"https://shalika.format.com/"},
  {name:"Nadhira Audrelya", title:"Mentor Experience & Ops @WomenWorks / Senior Project Officer, YLI Foundation", picture:"nadhira-audrelya.png", linkedinLink:"https://www.linkedin.com/in/audrelyan/", instagramLink:"https://www.instagram.com/audrelyan"},
  {name:"Oci Ambrosia", title:"Marketing and Brand Director @WomenWorks / Head of Marketing, Kata.ai", picture:"oci-ambrosia.png", linkedinLink:"https://www.linkedin.com/in/oci-ambrosia-44b0432b/", instagramLink:"https://www.instagram.com/ociambrosia"},
  {name:"Natasha Efendy", title:"Software Engineer @WomenWorks / Software Engineer, Bukalapak", picture:"natasha-efendy.png", linkedinLink:"https://www.linkedin.com/in/natasha-efendy-949543198", instagramLink:""},
  {name:"Alanna Deborah", title:"Mentee Experience & Strategy @WomenWorks / BD Associate, ProSpark", picture:"alanna-deborah.png", linkedinLink:"https://www.linkedin.com/in/alannadeborah/", instagramLink:"https://www.instagram.com/alannadeborah"},
  {name:"Gizela Cindy", title:"Content Strategist @WomenWorks / Video Producer, Bukalapak", picture:"gizela-cindy.png", linkedinLink:"https://id.linkedin.com/in/gizelacindy/", instagramLink:"https://www.instagram.com/gizelacindy/"},
  {name:"Wimala Puspa", title:"Product Director @WomenWorks / Senior Product Manager, Bukalapak", picture:"wimala-puspa.png", linkedinLink:"https://id.linkedin.com/in/wimalapuspa/", instagramLink:"https://www.instagram.com/wimalapuspa/"},
];

export default function Home() {
  return (      
    <div css={css`background: url(/women.svg) no-repeat; background-position: center bottom;`}>
    <FirstSectionContainer>
      <Row className="justify-content-center">
        <Col md="12" lg="8">
          <FirstSectionTitle>
            We are bad-ass. Connected. Financially independent.
          </FirstSectionTitle>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="12" lg="8">
          <FirstSectionDescription>
            Founded in 2019, WomenWorks is the place for women to connect, empower, and reach their fullest potential. Our mission is to build meaningful connections for women to achieve their career-oriented and personal development goals.
          </FirstSectionDescription>
        </Col>
      </Row>
      <Row>
        <Col css={css`text-align:center;`}>
          <Link to="/sign-up-for-mentorship-coaching">
            <ButtonFill1 variant="outline-light">
                Sign up for mentorship
            </ButtonFill1>
          </Link>
          <Link to="/be-a-mentor/">
            <ButtonFill2 variant="outline-light">
                Become a LadyBoss mentor
            </ButtonFill2>
          </Link>
        </Col>
      </Row>

        <CardsContainer>
            <Row>
            {WWTeam.map(team => 
              <ProfileCardColumn  xs="12" md="6" lg="4" xl="3">
                <ProfileCard>
                    <Card.Img alt={"WomenWorks Team - " + team.name} variant="top" src={"/ww-team/" + team.picture} />
                    <Card.Body>
                        <CardTitle>{team.name}</CardTitle>
                        <CardText>
                            {team.title}
                        </CardText>
                        {team.linkedinLink && <OutboundLink target="_blank" rel="noreferrer" href={team.linkedinLink}>
                          <SocialMediaIcon icon={faLinkedin} css={css`margin-right:20px;`}/>
                        </OutboundLink>}
                        {team.instagramLink && <OutboundLink target="_blank" rel="noreferrer" href={team.instagramLink}>
                          <SocialMediaIcon icon={faInstagramSquare}/>
                        </OutboundLink>}
                    </Card.Body>
                </ProfileCard>
              </ProfileCardColumn>
              )}
           </Row>
        </CardsContainer>
    </FirstSectionContainer>
    </div>
  )
}
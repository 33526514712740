import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Layout from "../components/layout";
import Section1 from "../sections/be-a-mentor-section1";
import Section2 from "../sections/be-a-mentor-section2";
import Section3 from "../sections/be-a-mentor-section3";
import Section4 from "../sections/be-a-mentor-section4";
import Section5 from "../sections/index-section5-multiple-use";
import SEO from "../components/seo"

export default function BeAMentor() {
  return (
    <Layout 
      LogoColor={props => props.theme.colors.white} 
      HeaderMenuColor={props => props.theme.colors.white} 
      firstSectionBGColor={props => props.theme.colors.secondary_green}

      //button
      HeaderButtonColor={props => props.theme.buttons.secondary_small_button_white_green.normal.color} 
      HeaderButtonBorder={props => props.theme.buttons.secondary_small_button_white_green.normal.border_color} 
      HeaderButtonBackground={props => props.theme.buttons.secondary_small_button_white_green.normal.background_color}
      
      HeaderButtonColorHover={props => props.theme.buttons.secondary_small_button_white_green.hovered.color}
      HeaderButtonBorderHover={props => props.theme.buttons.secondary_small_button_white_green.hovered.border_color} 
      HeaderButtonBackgroundHover={props => props.theme.buttons.secondary_small_button_white_green.hovered.background_color}

      HeaderButtonColorPressed={props => props.theme.buttons.secondary_small_button_white_green.pressed.color}
      HeaderButtonBorderPressed={props => props.theme.buttons.secondary_small_button_white_green.pressed.border_color} 
      HeaderButtonBackgroundPressed={props => props.theme.buttons.secondary_small_button_white_green.pressed.background_color}
    >

      <SEO title={"Be a Mentor"} description={"Join the community and empower others and help close the gender gap that is still prevalent in Indonesia’s workforce today"} />
      <Section1></Section1>
      <Section2></Section2>
      <Section3></Section3>
      <Section4></Section4>
      <Section5 
        DisplaySection='none'
      >
      </Section5>
    </Layout>   
  )
}

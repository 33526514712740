import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import styled from "@emotion/styled";
import {css} from "@emotion/core";
import { OutboundLink } from "gatsby-plugin-google-analytics";

const BeAMentorSection3Background = styled.div`
  min-height: 564px;
  background-color: ${props => props.theme.colors.accent_yellow70};
  color: ${props => props.theme.colors.gray_dark};
  @media(max-width:992px){
    min-height:0;
  }
`

const BeAMentorSection3Text = styled(Col)`
  font: 58px/62px Abril Fatface;
  text-align:center;
  margin-bottom:35px;
  @media(max-width:767px){
    margin-bottom:20px;
    font: 2.5rem/3rem Abril Fatface;
  }
`

const BeAMentorSection3Button = styled(Col)`
  display:flex;
  justify-content:center;
`

const ButtonFill6 = styled(Button)`
  height: 60px;
  color: ${props => props.theme.buttons.primary_button_violet.normal.color};
  background-color: ${props => props.theme.buttons.primary_button_violet.normal.background_color};
  border-color: ${props => props.theme.buttons.primary_button_violet.normal.border_color};
  font: 22px/22px Roboto;
  transition: 0.25s ease-out;
  &:hover {
    color: ${props => props.theme.buttons.primary_button_violet.hovered.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_violet.hovered.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_violet.hovered.border_color} !important;
    box-shadow: 0px 3px 20px ${props => props.theme.colors.gray_shadow} !important;
    @media(min-width:1199px){
      margin-top:-3px;
    }
  }
  &:focus, &.focus, &:active, &.active {
    color: ${props => props.theme.buttons.primary_button_violet.pressed.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_violet.pressed.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_violet.pressed.border_color} !important;
    box-shadow: none !important;
    @media(min-width:1199px){
      margin-top:-3px;
    }
  }
  @media(max-width:767px){
    width:100%;
    margin-bottom:50px;
  }
  @media(min-width:768px){
    margin-bottom:50px;
  }
  @media(min-width:1199px){
    width:100%;
  }
`

export default function BeAMentorSection3() {
  return (
  <BeAMentorSection3Background>
    <Container>
      <Row>
        <Col css={css`margin-top:150px; @media(max-width:992px){margin-top:50px;}`}>
          <Row>
              <Col lg="1" xl="1"></Col>
              <BeAMentorSection3Text lg="10" xl="10" xs="12">
                Ready to make an impact? Fill out the following form and we’ll get back to you.
              </BeAMentorSection3Text>
              <Col lg="1" xl="1"></Col>
              <Col lg="1" xl="1"></Col>
              <BeAMentorSection3Button lg="10" xl="10" xs="12">
                <OutboundLink target="_blank" rel="noreferrer" href="https://bit.ly/womenworksmentorsignupnew">
                  <ButtonFill6 variant="outline-light">
                    Fill out the mentor registration form
                  </ButtonFill6>
                </OutboundLink>
              </BeAMentorSection3Button>
              <Col lg="1" xl="1"></Col>
            </Row>
        </Col>
      </Row>
    </Container>
  </BeAMentorSection3Background>
  )
}